import classNames from "classnames";
import { ReactNode } from "react";

export interface JWrowInfo<T, C extends keyof T = any> {
 original: T;
 value?: T[C];
 row: number;
}

export interface JWcolumn<T> {
 skip?: boolean;
 width?: number;
 maxWidth?: number;
 minWidth?: number;
 accessor?: keyof T;
 title: ReactNode;
 Cell?: (props: JWrowInfo<T>) => ReactNode;
}

interface TableProps<T> {}
export interface IJWTableProps<D = any> extends Partial<TableProps<D>> {
 columns: Array<JWcolumn<D>>;
 data: D[];
 className?: string;
}

export const JWtable = <T,>({ columns, data, className }: IJWTableProps<T>) => {
 const gridTemplateColumns = columns
  .map(
   (column) =>
    `minmax(${column.minWidth ? column.minWidth + "px" : "80px"}, ${
     column.maxWidth ? column.maxWidth + "px" : "1fr"
    })`
  )
  .join(" ");

 console.log("====gridTemplateColumns", gridTemplateColumns);
 return (
  <div className={classNames("overflow-x-auto", className)}>
   <div
    className={`grid  gap-4 p-4 bg-neutral-100 rounded-xl`}
    style={{
     gridTemplateColumns,
    }}
   >
    {/* Columns Header */}
    {columns.map((column, index) => {
     if (column.skip) return null;
     return (
      <div
       key={index}
       style={{
        width: column.width,
        maxWidth: column.maxWidth,
        minWidth: column.minWidth,
       }}
       className="font-semibold text-sm py-4 flex items-center justify-center  uppercase tracking-wider"
      >
       {column.title}
      </div>
     );
    })}

    {/* Data Rows */}
    {data.map((row, rowIndex) =>
     columns.map((column, colIndex) => {
      if (column.skip) return null;

      const cellInfo: JWrowInfo<T> = {
       original: row,
       row: rowIndex,
       value: column.accessor ? row[column.accessor] : ("" as any),
      };

      return (
       <div
        key={`${rowIndex}-${colIndex}`}
        style={{
         width: column.width,
         maxWidth: column.maxWidth,
         minWidth: column.minWidth,
        }}
        className="text-sm flex items-center justify-center"
       >
        {column.Cell ? (column.Cell(cellInfo) as any) : cellInfo.value}
       </div>
      );
     })
    )}
   </div>
  </div>
 );
};
