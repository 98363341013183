import { useState } from "react";
import {
 FfileFragment,
 useFileUploadsMutation,
} from "../app/apollo/type/graphql";

export type TUseSingleUpload = ReturnType<typeof useMultiUpload>;

type Config = {
 noImg?: boolean;
};
export const useMultiUpload = (
 defaultFiles?: FfileFragment[],
 config?: Config
) => {
 const [fileUploading, setLoading] = useState(false);
 const [files, setFiles] = useState<FfileFragment[] | undefined>(defaultFiles);
 const [uploadMu] = useFileUploadsMutation({});

 const fileUploads = async (files: File[]) => {
  setLoading(true);

  let allFiles: File[] = files;
  let tags: {
   key: string;
   value: string;
  }[];

  const fileUploads = allFiles.map((file) => ({
   upload: file,
   tags,
  }));

  return uploadMu({
   variables: {
    files: fileUploads,
    noImg: config?.noImg,
   },
  })
   .then(({ data }) => {
    const file = data?.FileUploads.data;
    if (file) {
     setFiles(file);
    }
    return file;
   })
   .finally(() => {
    setLoading(false);
   });
 };

 const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
  const file = e.currentTarget.files;
  if (!file) return;
  return await fileUploads(Array.from(file));
 };

 const onChangeFile = async (files: File[]) => {
  return await fileUploads(files);
 };

 return {
  fileUploads,
  fileUploading,
  onChange,
  onChangeFile,
  files,
  setFiles,
 };
};
