import React from "react";
import { Button } from "./button";

interface PaginationProps {
 currentPage: number;
 totalPages: number;
 onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
 currentPage,
 totalPages,
 onPageChange,
}) => {
 // Calculate the range of pages to show
 const startPage = Math.max(1, currentPage - 1);
 const endPage = Math.min(totalPages, currentPage + 1);

 const pages = [];
 for (let page = startPage; page <= endPage; page++) {
  pages.push(page);
 }

 return (
  <div className="flex items-center justify-center space-x-2">
   <Button
    className="max-w-fit px-4"
    disabled={currentPage === 1}
    onClick={() => onPageChange(currentPage - 1)}
   >
    이전
   </Button>
   {pages.map((page) => (
    <Button
     key={page}
     className="max-w-fit aspect-square"
     selected={page === currentPage}
     onClick={() => onPageChange(page)}
    >
     {page}
    </Button>
   ))}
   <Button
    className="max-w-fit px-4"
    disabled={currentPage === totalPages}
    onClick={() => onPageChange(currentPage + 1)}
   >
    다음
   </Button>
  </div>
 );
};

export default Pagination;
