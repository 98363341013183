export const swap = <T>(
 list: T[],
 startIndex: number,
 endIndex: number,
 copy: boolean = true
) => {
 const result = copy ? Array.from(list) : list;
 const [removed] = result.splice(startIndex, 1);
 result.splice(endIndex, 0, removed);

 return result;
};
