import { useState } from "react";
import { ISet } from "./userListQuery";

export interface IUseQueryFilter<F> {
    filter: F;
    setFilter: ISet<F>;
    updateFilter: (updateFilter: Partial<F>) => void;
    setUniqFilter: <T extends keyof F>(
        target: T,
        uniq: (keyof F)[],
        value: any
    ) => void;
}

export const useQueryFilter = <F>(
    defaultFilter: F,
    fixingFilter?: F
): IUseQueryFilter<F> => {
    const [filter, setFilter] = useState({ ...defaultFilter, ...fixingFilter });

    const updateFilter = (updateFilter: Partial<F>) => {
        const update = Object.assign(filter, updateFilter);
        setFilter({
            ...update,
            ...fixingFilter,
        });
    };

    //uniq목록을 사용하여 uniq한 필터를 set함.
    const setUniqFilter = <T extends keyof F>(
        target: T,
        uniq: (keyof F)[],
        value: F[T]
    ) => {
        const _value = value ? value : undefined;
        const _filter = {
            ...filter,
        };
        uniq.forEach((u) => {
            _filter[u] = undefined as any;
        });
        uniq.forEach((u) => {
            if (target === u) {
                _filter[u] = _value as any;
            }
        });

        setFilter({
            ..._filter,
            ...fixingFilter,
        });
    };

    return {
        filter,
        setFilter: setFilter as any,
        updateFilter,
        setUniqFilter,
    };
};

export const getUniqFilter =
    <F, T extends keyof F>(filter: F) =>
    (target: T, uniq: (keyof F)[], value: F[T]) => {
        const _value = value ? value : undefined;
        const _filter = {
            ...filter,
        };
        uniq.forEach((u) => {
            _filter[u] = undefined as any;
        });
        uniq.forEach((u) => {
            if (target === u) {
                _filter[u] = _value as any;
            }
        });

        return {
            ..._filter,
        };
    };
