import { EditBox } from "./EditBox";
import { BasicInput } from "./basic/input";
import { TextArea } from "./textarea";

interface IProp {
 title: JSX.Element | string;
 value: string;
 onChange: (value: string) => void;
 textarea?: boolean;
 InputProp?: React.ComponentProps<typeof BasicInput>;
}

export const EditBoxTitle: React.FC<IProp> = ({
 title,
 value,
 onChange,
 textarea,
 InputProp,
}) => {
 return (
  <EditBox title={<div>{title} </div>}>
   {textarea ? (
    <TextArea className="w-full" value={value} onChange={onChange} />
   ) : (
    <BasicInput
     className="w-full"
     value={value}
     onChange={onChange}
     {...InputProp}
    />
   )}
  </EditBox>
 );
};
