import {
 CellType,
 FcouponFragment,
 _ChatRoomSort,
 _CouponFilter,
 useCouponCreateMutation,
 useCouponDeleteMutation,
 useCouponUpdateMutation,
} from "../app/apollo/type/graphql";
import { SkipUpdate } from "../components/SkipUpdate";
import { useContext, useState } from "react";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import Pagination from "../components/Pagination";
import { SuperAdminContext } from "../hook/super.admin.context";
import { couponListHook } from "../hook/useItemList";
import { PageEditUI } from "../components/PageEditUI";
import { useIframCommunicate } from "../hook/useIframCommunication";
import useUpdateEffect from "../hook/useUpdateEffect";
import { Input } from "../components/input";
import { Select, withDefaultOp } from "../components/Select";
import { Button } from "../components/button";
import { CategoryOps, SiDoList } from "../data/const";
import { MinusCircleIcon, PlusIcon } from "@heroicons/react/24/outline";
import { AppContext } from "../hook/useApp.context";
import { s8 } from "../utils/s4";
import { BasicButton } from "../components/basic/button";
import { Spinner } from "../components/Spinner";
import { toast } from "react-toastify";
import { getRefetch } from "../utils/getRefetch";
import { COUPON_LIST } from "../apollo/gql/item";
import { Photo } from "../components/Photo";
import { isPastDay, yyyymmdd } from "../utils/date";
import { cloneDeep } from "@apollo/client/utilities";
import { CategoryBadge } from "./components/CategoryBadge";
import { whenEnter } from "../utils/whenEnter";

export const CouponList = () => {
 const { editingCoupon, setEditingCoupon } = useContext(SuperAdminContext);
 const {
  items: coupons,
  getLoading,
  pageInfo,
  setPage,
  setFilter,
  filter,
 } = couponListHook({
  initialViewCount: 100,
 });
 const [search, setSearch] = useState("");

 const isNewCoupon = !coupons.find((cp) => cp._id === editingCoupon?._id);

 console.log("@@isNewCoupon", isNewCoupon);

 const [couponDeleteMu, { loading: deleteLoading }] = useCouponDeleteMutation({
  ...getRefetch(COUPON_LIST),
  onCompleted(data, clientOptions) {
   if (data.CouponDelete?.ok) {
    setEdited(false);
    toast?.success("쿠폰삭제 완료");
   }
  },
 });

 const [couponCreateMu, { loading: createLoading }] = useCouponCreateMutation({
  ...getRefetch(COUPON_LIST),
  onCompleted(data, clientOptions) {
   if (data.CouponCreate?.ok) {
    setEdited(false);
    toast?.success("쿠폰등록 완료");
   }
  },
 });
 const [couponUpdateMu, { loading: updateLoading }] = useCouponUpdateMutation({
  ...getRefetch(COUPON_LIST),
  onCompleted(data, clientOptions) {
   if (data.CouponUpdate?.ok) {
    setEdited(false);
    toast?.success("업데이트 완료");
   }
  },
 });
 const [edited, setEdited] = useState(false);

 const iframeControlHook = useIframCommunicate({});
 const { postToChild, inited } = iframeControlHook;

 const set = () => {
  setEdited(true);
  setEditingCoupon({
   ...editingCoupon!,
  });
 };

 const handleSearch = () => {
  setFilter({
   ...filter,
   page_name_ko__contains: search || undefined,
  });
 };

 const setKr = (obj: any, key: string, val: string) => {
  if (!obj[key]) obj[key] = {};
  obj[key]["ko"] = val;
  set();
 };

 useUpdateEffect(() => {
  if (editingCoupon)
   postToChild({
    command: "updateCoupon",
    coupon: editingCoupon,
   });
  return () => {
   iframeControlHook.setInited(false);
  };
 }, [editingCoupon, iframeControlHook.inited]);

 const handleAdd = () => {
  const key = s8();
  setEditingCoupon({
   _id: s8(),
   page: {
    groups: [],
    key: key,
    path: "/" + key,
   },
  } as any);
 };

 const handleSave = () => {
  if (isNewCoupon) {
   couponCreateMu({
    variables: {
     input: editingCoupon!,
    },
   });
  } else {
   couponUpdateMu({
    variables: {
     couponId: editingCoupon?._id!,
     input: editingCoupon!,
    },
   });
  }
 };

 const handleDelete = (cid: string) => {
  couponDeleteMu({
   variables: {
    couponId: cid,
   },
  });
 };

 const isNewCreating = editingCoupon && !editingCoupon?.createdAt;

 const iconClasses = "cursor-pointer";
 return (
  <div className="flex  ">
   <div className="w-full   mb-0 m-[5%]  ">
    <SkipUpdate skip={getLoading}>
     {!editingCoupon ? (
      <>
       <div className="flex gap-2 ">
        <Input
         value={search}
         onChange={setSearch}
         onKeyDown={whenEnter(() => {
          handleSearch();
         })}
         className="max-w-fit"
         inputClassname=" rounded-full border-none px-8"
         placeholder="검색하기"
        ></Input>
        <div className="flex items-center  gap-2.5">
         <Select
          buttonClasses="border-none px-8"
          className="min-w-36 rounded-full    bg-white"
          placeholder="지역선택"
          onChange={(val) => {
           filter.addressDetail_fullConnedtedAddress__contains = val;
           setFilter({
            ...filter,
           });
          }}
          value={filter.addressDetail_fullConnedtedAddress__contains}
          options={withDefaultOp(
           SiDoList?.map((sido) => {
            return {
             label: sido?.name,
             value: sido?.name,
            };
           }) || [],
           "전체지역"
          )}
         />
         {/* <Select options={SiDoList?.map(sido => {
            return {
                name: sido?.,
                value: sido?.name,
            }
        })} /> */}
         {withDefaultOp(CategoryOps, "전체보기").map((op) => {
          return (
           <Button
            onClick={() => {
             setFilter({
              ...filter,
              categoryKey__eq: op.value,
             });
            }}
            selected={filter.categoryKey__eq === op.value}
            className="rounded-full px-8 whitespace-nowrap"
           >
            {op.label}
           </Button>
          );
         })}
        </div>
       </div>
       <div className="my-10 pb-10   flex gap-4 flex-nowrap overflow-auto ">
        <div
         className={classNames(
          "w-28 cursor-pointer hover:bg-neutral-50  h-28 border text-sm  rounded-lg flex items-center justify-center  bg-white md:bg-neutral-50",
          {
           "border-blue-500 text-blue-500": isNewCreating,
          }
         )}
         onClick={() => {
          handleAdd();
         }}
        >
         추가하기
        </div>
        {coupons?.map((item) => {
         const limitDates = item.page?.groups
          ?.filter((g) => g.type === CellType.Coupon)
          ?.map((c) => {
           return {
            date: c.limitDate,
            label: c.name?.ko,
           };
          });

         const limit = limitDates[0];

         return (
          <div
           onClick={() => {
            setEditingCoupon(cloneDeep(item));
           }}
           className={twMerge(
            classNames(
             "flex rounded-xl parent-hover  relative flex-col  justify-between gap-1 items-center  cursor-pointer relative  bg-white md:bg-neutral-50   p-1"
            )
           )}
          >
           <div
            onClick={(e) => {
             e.stopPropagation();
             e.preventDefault();
             handleDelete(item._id);
            }}
            className="absolute child-hover left-2 top-2 bg-white  hover:bg-neutral-100 p-1 rounded-full"
           >
            <MinusCircleIcon className=" text-rose-500  w-4 h-4 rounded-full  hover:text-rose-700" />
           </div>
           <CategoryBadge
            iconClassName="!w-4 !h-4"
            className="absolute top-2  !rounded-full !p-1 right-2 w-fit"
            categoryKey={item?.categoryKey!}
           />
           <div className="aspect-square overflow-hidden w-28  rounded-xl">
            <Photo
             className="bg-neutral-100 w-full h-full object-cover object-center"
             file={item.page?.image}
            />
           </div>
           <div className="p-1 pt-0.5 text-center text-xs items-start flex justify-center flex-col">
            <h6 className="text-center w-full font-semibold text-center">
             {item.page?.name?.ko || "[제목없음]"}
            </h6>
            <div
             className={classNames("text-center w-full", {
              "text-blue-500": !isPastDay(limit?.date),
              "text-rose-50-500": isPastDay(limit?.date),
             })}
            >
             {limit?.date
              ? isPastDay(limit?.date)
                ? "사용만료"
                : yyyymmdd(limit?.date)
              : limit?.label}
            </div>
           </div>
          </div>
         );
        })}
       </div>
      </>
     ) : (
      <>
       {/* {pageInfo.totalPageCount > 1 && (
      <div className="mt-20">
       <Pagination
        onPageChange={(page) => {
         setPage(page - 1);
        }}
        currentPage={pageInfo.pageIndex + 1}
        totalPages={pageInfo.totalPageCount}
       />
      </div>
     )} */}

       {editingCoupon?.page?.path && (
        <PageEditUI
         bodyClasses="!m-0"
         coupon={editingCoupon}
         editingPath="/coupon"
         iframeUrl={"/coupon"}
         iframControl={iframeControlHook}
         loading={createLoading || updateLoading}
         set={set}
         setKr={setKr}
         selectedPage={editingCoupon?.page}
        />
       )}
      </>
     )}
    </SkipUpdate>

    {editingCoupon && (
     <div className="shadow-lg drop-shadow-md flex left-1/2 border border-neutral-500/10 overflow-hidden rounded-lg -translate-x-1/2  fixed bottom-8  ">
      <BasicButton
       className="  whitespace-nowrap rounded-none  px-8"
       disabled={createLoading || updateLoading}
       onClick={handleSave}
       color="primary"
      >
       {createLoading || updateLoading ? "업데이트중" : "저장하기"}{" "}
       {(createLoading || updateLoading) && (
        <Spinner className="w-5 h-5  ml-1" />
       )}
      </BasicButton>
      <BasicButton
       onClick={() => {
        if (!edited) {
         setEditingCoupon(undefined);
         return;
        } else if (
         window.confirm(
          "변경된 내용이 존재합니다. 목록으로 이동하면 변경사항이 저장되지 않습니다."
         )
        ) {
         setEditingCoupon(undefined);
         return;
        }
       }}
       className="whitespace-nowrap   rounded-none px-8"
      >
       쿠폰목록
      </BasicButton>
     </div>
    )}
   </div>
  </div>
 );
};

export default CouponList;
