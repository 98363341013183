import {
 PencilIcon,
 EllipsisHorizontalIcon,
} from "@heroicons/react/24/outline";
import {
 CellType,
 FgroupFragment,
 FpageFragment,
} from "../app/apollo/type/graphql";
import { CellTypeKr } from "../data/const";
import { useContext } from "react";
import { AppContext } from "../hook/useApp.context";

interface IProp {
 group: FgroupFragment;
 index: number;
 dragProp: any;
 page: FpageFragment;
 set: () => void;
}

export const TitleOfEditBox: React.FC<IProp> = ({
 group: g,
 index,
 dragProp,
 page,
 set,
}) => {
 const { groupEditModalHook } = useContext(AppContext);
 return (
  <div className="flex parent-hover  items-center justify-between w-full">
   <div className="flex items-center gap-1">
    {g.name?.ko || "제목없음"}{" "}
    {g.type !== CellType.Normal ? `(${CellTypeKr[g.type]})` : ""}
    <PencilIcon
     onClick={() => {
      groupEditModalHook?.openImmediate({
       type: g.type,
       group: g,
       onSubmit(group) {
        page?.groups?.splice(index, 1, group);
        set();
       },
      });
     }}
     className="w-4 h-4 child-hover cursor-pointer"
    />
   </div>
   <div className=" flex items-center gap-1">
    <span
     onClick={() => {
      page.groups?.splice(index, 1);
      set();
     }}
     className="text-rose-500 child-hover inline-block mr-2 hover:text-rose-400 cursor-pointer"
    >
     삭제
    </span>
    <div {...dragProp}>
     <EllipsisHorizontalIcon
      strokeWidth={2}
      className="w-5 h-5    hover:text-neutral-500"
     />
    </div>
   </div>
  </div>
 );
};
