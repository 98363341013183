import { useParams } from "react-router-dom";
import { useModal } from "./useModal";
import React, { useContext, useEffect, useState } from "react";
import { IPromptModalInfo } from "../components/systemModal/PromptModal";
import { useCopy } from "./useCopy";
import { AppContext } from "./useApp.context";
import { ICellEditInfo } from "../components/CellEditModal.model";
import { IGroupEditInfo } from "../components/GroupEditModal.modal";
import { IPageEditInfo } from "../components/PageEditModal.modal copy";
import { useIframCommunicate } from "./useIframCommunication";
import useUpdateEffect from "./useUpdateEffect";

export type TUseAppContext = ReturnType<typeof useAppEditContextProvider>;

export const useAppEditContextProvider = () => {
 const { store: defaultStore } = useContext(AppContext);
 const [store, setStore] = useCopy(defaultStore);
 const [editingPath, setEditingPath] = useState("/");
 const iframeControlHook = useIframCommunicate({});
 const { postToChild, inited, selfIframeRef } = iframeControlHook;
 const promptModalHook = useModal<IPromptModalInfo>();
 const page = store?.pages?.find((p) => {
  return p.path === editingPath;
 });

 const pageOps = store?.pages?.map((p) => {
  return {
   label: p.name?.ko,
   value: p.path,
  };
 });

 const set = () => {
  setStore({
   ...store,
  } as any);
 };
 useUpdateEffect(() => {
  if (store)
   postToChild({
    command: "update",
    store,
   });
 }, [store, editingPath]);

 const setKr = (obj: any, key: string, val: string) => {
  if (!obj[key]) obj[key] = {};
  obj[key]["ko"] = val;
  set();
 };

 useUpdateEffect(() => {
  console.log("@@editingPath", editingPath);
  if (editingPath !== "/coupon")
   postToChild({
    command: "route",
    path: editingPath,
   });
 }, [editingPath, inited]);
 return {
  setKr,
  pageOps,
  set,
  store,
  page,
  iframeControlHook,
  selfIframeRef,
  setStore,
  editingPath,
  setEditingPath,
 };
};

export const AppEditContext = React.createContext<TUseAppContext>({} as any);
export const useAppEditContext = () => {
 return useContext(AppEditContext);
};
