import { useEffect, useState } from "react";

export const useIframeHeight = () => {
 const [height, setHeight] = useState(600);

 useEffect(() => {
  const func = (e: MessageEvent) => {
   if (e.data.hasOwnProperty("frameHeight")) {
    setHeight(e.data.frameHeight);
   }
  };
  window.addEventListener("message", func);
  return () => {
   window.removeEventListener("message", func);
  };
 }, []);
 return { height, setHeight };
};
