import { useContext, useEffect, useState } from "react";
import {
 CellType,
 FcellFragment,
 FcellFragmentDoc,
 FfileFragment,
 LineType,
} from "../app/apollo/type/graphql";
import { TUseModal } from "../hook/useModal";
import { useSingleUpload } from "../hook/useSingleUpload";
import Modal from "./Modal";
import { SingleImageUploader } from "./basic/SingleImageUploader";
import { BasicInput } from "./basic/input";
import { BasicSelect } from "./basic/Select";
import { s4, s6 } from "../utils/s4";
import { useSelect } from "../hook/useSelect";
import { AppEditContext, useAppEditContext } from "../hook/useAppEdit.context";
import { useInput } from "../hook/useInput";
import { BasicButton } from "./basic/button";
import { TextArea } from "./textarea";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { IconTypePairOps } from "./InfoLineEditor";
import { AddressInput } from "./addressInput/AddressInput";
import { EditBox } from "./EditBox";
import { PageEditorContext, usePageEditorContext } from "./PageEditor.context";
import { Icons } from "../data/icon.const";
import classNames from "classnames";

const CelllTypeOps = [
 {
  label: "사각형",
  value: "Square",
 },
 {
  label: "인포라인",
  value: "InfoLine",
 },
 {
  label: "텍스트존",
  value: "PageInfo",
 },
 {
  label: "텍스트존",
  value: "PageInfo",
 },
];

// Square: "Square",
// Info:  "Info",
// Verti: "Verti",
// PageBlock: "PageBlock",
// PageInfo: "PageInfo",

export interface ICellEditInfo {
 cell?: FcellFragment;
 type?: CellType;
}

interface IProp {
 modalhook: TUseModal<ICellEditInfo>;
}

export const CellEditModal: React.FC<IProp> = ({ modalhook }) => {
 const { pageOps } = useContext(AppEditContext);
 const editContext = useContext(PageEditorContext);
 const defaultCell = modalhook.info?.cell;
 const { setKr } = editContext;
 //  const [] = useState({

 //  });

 const cell = defaultCell;

 if (!cell) return null;
 return (
  <Modal bodyclasses="max-h-[80vh] overflow-auto" modalHook={modalhook}>
   <div
    key={cell?.key + "GroupCell"}
    className="flex flex-col gap-4 p-4 bg-white rounded-xl"
   >
    <BasicInput
     label="항목명"
     value={cell.name?.ko}
     onChange={(val) => {
      setKr(cell, "name", val);
     }}
    />

    {!!pageOps?.length &&
     ![
      CellType.Info,
      CellType.InfoPageList,
      CellType.Gallery,
      CellType.ImgList,
     ]?.includes(cell.type) && (
      <BasicSelect
       label="연결 페이지"
       options={[
        ...pageOps,
        {
         label: "직접쓰기",
         value: "self",
        },
       ]}
       value={cell.link}
       onChange={(val) => {
        cell.link = val;
        editContext?.set();
       }}
      />
     )}
    {cell.link === "self" && (
     <BasicInput
      label="링크 직접쓰기"
      placeholder="https://"
      value={cell.customLink || ""}
      onChange={(val) => {
       cell.customLink = val;
       editContext?.set();
      }}
     />
    )}

    {cell?.iconLines?.map((il, index) => {
     return (
      <div key={il.key} className="flex flex-col gap-1 items-center">
       <EditBox
        title={
         <div className="flex justify-between items-center">
          <span>{"정보" + (index + 1)}</span>

          <XMarkIcon
           className="cursor-pointer w-4 h-4"
           onClick={() => {
            cell.iconLines?.splice(index, 1);
            editContext?.set();
           }}
          />
         </div>
        }
       >
        <div className="flex gap-2  flex-col   gap-4 w-full">
         <div className="flex gap-0.5 flex-wrap overflow-auto h-36">
          {Object.entries(Icons)?.map(([key, Icon]) => {
           return (
            <div key={key + "IconSelect"}>
             <Icon
              onClick={() => {
               il.icon = key;
               editContext?.set();
              }}
              className={classNames(
               "w-4 h-4 cursor-pointer box-content hover:bg-neutral-100 p-1 rounded",
               {
                "bg-blue-50 text-blue-500": key === il.icon,
               }
              )}
             />
            </div>
           );
          })}
         </div>
         {/* <BasicSelect
          label="리스트타입"
          className="grow"
          onChange={(type) => {
           il.type = type;
           editContext?.set();
          }}
          value={il.type}
          options={IconTypePairOps}
         />{" "} */}
         <BasicInput
          label="리스트 인풋"
          className="grow"
          value={il.title?.ko}
          onChange={(val) => {
           setKr(il, "title", val);
          }}
         />{" "}
        </div>
       </EditBox>
      </div>
     );
    })}
    {[CellType.Info, CellType.InfoPageList]?.includes(cell.type) && (
     <BasicButton
      onClick={() => {
       cell.iconLines = [
        ...(cell.iconLines || []),
        {
         key: s4(),
         title: {
          ko: "",
         },
         type: LineType.PhoneNumber,
        },
       ];
       editContext?.set();
      }}
     >
      정보추가
     </BasicButton>
    )}

    <div className="w-full">
     <label className="mb-1 text-neutral-500 font-light text-sm">이미지</label>
     <SingleImageUploader
      className="rounded"
      file={cell.image as FfileFragment}
      setFile={(file) => {
       cell.image = file;
       editContext?.set();
      }}
     />
    </div>

    {cell.type === CellType.Map && (
     <AddressInput
      data={cell.addressDetail as any}
      address={cell.addressDetail?.jibunAddress as any}
      setAddress={(data) => {
       cell.addressDetail = data;
       editContext?.set();
      }}
     />
    )}
    {cell.type === CellType.PageInfo && (
     <TextArea
      label="항목텍스트"
      value={cell.desc?.ko}
      onChange={(val) => {
       setKr(cell, "desc", val);
      }}
     />
    )}
    <BasicButton color="primary" size="L" onClick={modalhook.close}>
     확인
    </BasicButton>
   </div>
  </Modal>
 );
};
