import dayjs from "dayjs";
import { ReactNode, useContext, useId, useState } from "react";
import {
 ArrowDownCircleIcon,
 ChevronDownIcon,
 ChevronLeftIcon,
 ChevronRightIcon,
 ChevronUpDownIcon,
 ChevronUpIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import useUpdateEffect from "../hook/useUpdateEffect";
import {
 startOfMonthNumber,
 endOfMonthNumber,
 generateDateArrayBetween,
 isDateInsideSameInclude,
 isPast,
} from "../utils/date";
import { twMerge } from "tailwind-merge";

const monthOps = new Array(12).fill(1)?.map((_, index) => {
 return index + 1;
});

export const HotelSearcher = () => {};

type DateCellInfo = {
 outofMonth: boolean;
 past: boolean;
 week: string;
 isStart: boolean;
 isEnd: boolean;
 isInside: boolean;
 disabled: boolean;
};

type DateClassFn = (date: Date, info: DateCellInfo) => string;
type DateRenderFn = (date: Date, info: DateCellInfo) => ReactNode;
type MonthClasses = {
 dateClasses?: DateClassFn;
 dateCellClasses?: string;
 monthWrapClasses?: string;
 bodyClasses?: string;
 monthClasses?: string;
 monthTitleClass?: string;
 weekWrapClasses?: string;
 weekCellClasses?: string;
 navIconClasses?: string;
 leftNavIconClasses?: string;
 rightNavIconClasses?: string;
 dateCellGridClasses?: string;
};

export type MonthProp = {
 monthUnder: Date;
 month: Date;
 setMonthUnder: (date: Date) => void;
 setMonth: (month: Date) => void;
 onSelect: (date: Date) => void;
 classes?: MonthClasses;
 NavRender?: () => React.ReactNode;
 TitleRender?: (month: Date) => React.ReactNode;
 renderDateInisder?: DateRenderFn;
 weekRender?: () => ReactNode;
 renderDateCell?: (date: Date, info: DateCellInfo, cellProps: any) => ReactNode;
 isDisabled?: (date: Date) => boolean;
 from: Date | undefined;
 to: Date | undefined;
 inMode: "calendar" | "years" | "months";
 setInMode: (inMode: "calendar" | "years" | "months") => void;
};

const Month: React.FC<MonthProp> = ({
 classes,
 month,
 monthUnder,
 setMonthUnder,
 setMonth,
 onSelect,
 isDisabled,
 renderDateCell,
 weekRender,
 renderDateInisder,
 TitleRender,
 inMode,
 setInMode,
 from,
 to,
}) => {
 const yearsOps = new Array(12).fill(1)?.map((_, index) => {
  const year = new Date(monthUnder).getFullYear();
  return year + (-10 + index);
 });

 const start = dayjs(month).startOf("month");
 const uniqId = useId();
 const monthStartAt = startOfMonthNumber(month);
 const monthEndAt = endOfMonthNumber(month);
 const monthDateRange = generateDateArrayBetween(
  dayjs.tz(monthStartAt).startOf("week"),
  dayjs.tz(monthEndAt).endOf("week").add(1, "day")
 );
 const {
  dateClasses,
  dateCellClasses,
  monthTitleClass,
  monthWrapClasses,
  bodyClasses,
  dateCellGridClasses,
  monthClasses,
  weekCellClasses,
  weekWrapClasses,
 } = classes || {};

 const Week = weekRender?.();
 const Title = TitleRender?.(month);
 const isCalMode = inMode === "calendar";
 const isYearMode = inMode === "years";
 const isMonthMode = inMode === "months";

 return (
  <div className={twMerge(classNames("w-full", monthClasses))}>
   {Title || (
    <div className="w-full flex justify-center">
     <div
      onClick={() => {
       if (isYearMode) return;
       if (isCalMode) setInMode("months");
       else if (isMonthMode) setInMode("years");
       else if (isYearMode) setInMode("calendar");
      }}
      className={twMerge(
       classNames(
        "font-semibold w-fit  flex items-center justify-center !mt-3.5  p-2 rounded  gap-1 my-4 mb-8 text-lg text-center",
        monthTitleClass,
        {
         "hover:bg-neutral-100 cursor-pointer": !isYearMode,
        }
       )
      )}
     >
      {isYearMode && yearsOps[0] + " ~ " + yearsOps[yearsOps?.length - 1]}
      {isCalMode && dayjs(start).format("YYYY MM")}
      {isMonthMode && dayjs(monthUnder).format("YYYY")}
      {!isYearMode && (
       <ChevronUpDownIcon
        strokeWidth={2}
        className=" text-neutral-400 w-5 h-5 "
       />
      )}
      {/* {isCalMode ? (
       <ChevronDownIcon strokeWidth={2} className="w-5 h-5 " />
      ) : (
       <ChevronUpIcon strokeWidth={2} className="w-5 h-5 " />
      )} */}
     </div>
    </div>
   )}

   <div className={twMerge(classNames("w-full", bodyClasses))}>
    {isCalMode && (
     <>
      {Week || (
       <div
        className={twMerge(classNames("grid grid-cols-7", weekWrapClasses))}
       >
        {new Array(7).fill(monthDateRange?.at(0)).map((date, index) => {
         const ddd = dayjs(date).add(index, "day").locale("ko").format("dd");
         return (
          <div
           key={"uniqId" + ddd + "Calendar"}
           className={twMerge(
            classNames(
             "flex items-center justify-center aspect-square",
             weekCellClasses
            )
           )}
          >
           {ddd}
          </div>
         );
        })}
       </div>
      )}
      <div className={classNames("grid grid-cols-7", dateCellGridClasses)}>
       {monthDateRange.map((date) => {
        const outofMonth = !isDateInsideSameInclude(
         date,
         monthStartAt,
         monthEndAt
        );

        const past = isPast(date);
        const week = dayjs.tz(date).format("d");
        const disabled = !!isDisabled?.(date);
        const isInside = isDateInsideSameInclude(date, from, to);
        const isStart = !!(from && dayjs(date).isSame(from, "date"));
        const isEnd = !!(to && dayjs(date).isSame(to, "date"));

        const dateInfo: DateCellInfo = {
         isStart,
         isEnd,
         isInside,
         disabled,
         outofMonth,
         past,
         week,
        };

        const cellProps = {
         key: date.getTime(),
         className: twMerge(
          classNames(
           "aspect-square text-neutral-800",
           dateClasses?.(date, dateInfo),
           dateCellClasses
          )
         ),
         onClick: () => {
          if (disabled) return;
          onSelect(date);
         },
        };

        const rendered = renderDateInisder?.(date, dateInfo);
        const dateRendered = renderDateCell?.(date, dateInfo, cellProps);

        if (dateRendered) {
         return dateRendered;
        }

        return (
         <div {...cellProps}>
          {renderDateInisder ? (
           rendered
          ) : (
           <span>{dayjs(date).format("DD")}</span>
          )}
         </div>
        );
       })}
      </div>
     </>
    )}
    {isYearMode && (
     <div className="grid grid-cols-4 gap-4 gap-y-8">
      {yearsOps?.map((year) => {
       return (
        <div
         onClick={() => {
          setMonth(dayjs(month).set("year", year)?.toDate());
          setInMode("months");
         }}
         className={classNames(
          "w-full cursor-pointer p-2 rounded hover:bg-neutral-100 flex items-center justify-center",
          {
           "text-primary-500 bg-blue-50": year === month.getFullYear(),
          }
         )}
        >
         {year}
        </div>
       );
      })}
     </div>
    )}
    {isMonthMode && (
     <div className="grid grid-cols-4 gap-4 gap-y-8">
      {monthOps?.map((op) => {
       return (
        <div
         onClick={() => {
          setMonth(
           dayjs(month)
            .set("year", dayjs(monthUnder).get("year"))
            .set("month", op - 1)
            ?.toDate()
          );
          setInMode("calendar");
         }}
         className={classNames(
          "w-full cursor-pointer p-2 rounded hover:bg-neutral-100 flex items-center justify-center",
          {
           "text-primary-500 bg-blue-50":
            op - 1 === month.getMonth() &&
            dayjs(month).year() === dayjs(monthUnder).year(),
          }
         )}
        >
         {op}월
        </div>
       );
      })}
     </div>
    )}
   </div>
  </div>
 );
};

export interface CalendarProp
 extends Omit<
  MonthProp,
  | "month"
  | "onSelect"
  | "setMonth"
  | "inMode"
  | "setInMode"
  | "setMonthUnder"
  | "monthUnder"
 > {
 onChange: (from: Date | undefined, to: Date | undefined) => void;
 month?: Date;
 setMonth?: (month: Date) => void;
 viewMonthCnt?: number;
 canSelectBeforeDay?: boolean;
 className?: string;
 range?: boolean;
 insideClasses?: string;
 startClasses?: string;
 endClasses?: string;
}
export type TInMode = "calendar" | "years" | "months";

export const Calendar: React.FC<CalendarProp> = ({
 renderDateInisder,
 renderDateCell,
 viewMonthCnt = 2,
 range = true,
 from,
 isDisabled,
 weekRender,
 onChange,
 to,
 classes,
 month: propMonth,
 canSelectBeforeDay,
 setMonth: propSetMonth,
 className,
 insideClasses,
 startClasses,
 endClasses,
}) => {
 const [_month, _setMonth] = useState(new Date());
 const month = propMonth || _month;
 const setMonth = propSetMonth || _setMonth;
 const [inMode, setInMode] = useState<TInMode>("calendar");

 const [monthUnder, setMonthUnder] = useState(month);
 useUpdateEffect(() => {
  setMonthUnder(month);
 }, [month]);

 const months = new Array(viewMonthCnt).fill(month).map((m, index) => {
  return dayjs(m).add(index, "month").toDate();
 });

 const isFromSelect = (inFrom: any, inTo: any, day: any) => {
  const isBeforeFirstDay = inFrom && dayjs.tz(day).isBefore(inFrom, "day");
  const isRangeSelected = inFrom && inTo;
  return !inFrom || isBeforeFirstDay || isRangeSelected;
 };
 const reset = () => {
  onChange(undefined, undefined);
 };

 const handleDayClick = (day: Date) => {
  const isFristSelect = isFromSelect(from, to, day);
  const clickedSameDate = dayjs.tz(from).isSame(day, "d");
  if (clickedSameDate) {
   reset();
   return;
  }

  if (!range) {
   onChange(day, day);
   return;
  }

  if (clickedSameDate) {
   onChange(from, to);
   return;
  }

  // 선택한 날자 뒤를 누른경우에
  if (from && day <= from) {
   onChange(day, undefined);
   return;
  }

  // 이미 선택된 날을 눌렀을경우에
  if (from && to && day >= from && day <= to) {
   reset();
   return;
  }

  if (isFristSelect) {
   onChange(day, undefined);
  } else {
   onChange(from, day);
  }
 };

 const monthProps = (month: Date): MonthProp => {
  const dateClassFn: DateClassFn = (
   date: Date,
   { outofMonth, past, isInside, isStart, isEnd, disabled }
  ) => {
   return classNames("flex  cursor-pointer  items-center justify-center", {
    "text-neutral-400 cursor-auto pointer-events-none":
     !disabled && !canSelectBeforeDay && past,
    [`bg-primary-700 text-primary-over ${insideClasses}`]: isInside,
    [`bg-primary-500 text-primary-over ${startClasses}`]: isStart || isEnd,
   });
  };

  return {
   inMode,
   setInMode,
   from,
   to,
   month,
   monthUnder,
   setMonthUnder,
   isDisabled,
   weekRender,
   setMonth,
   classes: {
    dateClasses: dateClassFn,
    ...classes,
   },
   onSelect: handleDayClick,
   renderDateInisder,
   renderDateCell,
  };
 };

 const monthMove = (direction: number) => {
  const date = dayjs(month).add(direction, "month").toDate();
  setMonth(date);
 };

 const yearMove = (direction: number) => {
  const date = dayjs(monthUnder).add(direction, "year").toDate();
  setMonthUnder(date);
 };

 const {
  leftNavIconClasses,
  rightNavIconClasses,
  navIconClasses,
  monthWrapClasses,
 } = classes || {};
 return (
  <div className={twMerge(classNames("relative", className))}>
   <ChevronLeftIcon
    className={twMerge(
     classNames(
      "absolute  hover:text-neutral-500 left-4 top-5 cursor-pointer w-6 text-neutral-400",
      navIconClasses,
      leftNavIconClasses
     )
    )}
    onClick={() => {
     if (inMode == "years") {
      yearMove(-12);
     } else if (inMode === "months") {
      yearMove(-1);
     } else if (inMode === "calendar") {
      monthMove(-1);
     }
    }}
   />
   <ChevronRightIcon
    className={twMerge(
     classNames(
      "absolute hover:text-neutral-500 right-4 top-5 cursor-pointer w-6 text-neutral-400",
      navIconClasses,
      rightNavIconClasses
     )
    )}
    onClick={() => {
     if (inMode == "years") {
      yearMove(12);
     } else if (inMode === "months") {
      yearMove(1);
     } else if (inMode === "calendar") {
      monthMove(1);
     }
    }}
   />
   <div
    className={twMerge(
     classNames(
      "flex gap-8 mb-2",
      {
       //    "flex-col": isMobileSize,
      },
      monthWrapClasses
     )
    )}
   >
    {months.map((month) => {
     return <Month {...monthProps(month)} />;
    })}
   </div>
  </div>
 );
};
