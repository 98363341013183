import { Outlet, useLocation } from "react-router-dom";
import { Suspense, useContext, useEffect } from "react";
import {
 ChatBubbleBottomCenterTextIcon,
 HomeIcon,
 MapIcon,
} from "@heroicons/react/20/solid";
import { AppContext } from "../hook/useApp.context";
import { L } from "../data/lang.static";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { RoomNumberPopModal } from "../components/RoomNumberPop.modal";
import { useIframeHeightSender } from "../hook/useIframeHeightSender";
import { iamTopWindow } from "../utils/localstorage/StorageAddition";
import { ImagesViewModal } from "../components/ImagesView.modal";
import { Helmet } from "react-helmet";

export default function CustomerLayout() {
 const { lang, roomNumberPOpModal, store, page, imgViewModalHook } =
  useContext(AppContext);
 const navigate = useNavigate();

 const { pathname } = useLocation();
 const isChat = pathname?.includes("/chat");
 const isMap = pathname?.includes("/coupon");
 const isIntro = pathname === "/";
 const isHome = !isChat && !isMap;

 type Nav = {
  path: string;
  Icon: typeof HomeIcon;
  title: string;
  selected?: boolean;
  skip?: boolean;
 };

 const NavSet: Nav[] = [
  {
   Icon: HomeIcon,
   path: "/main",
   title: L["Home"],
   selected: isHome,
  },
  {
   skip: !!store?.User?.noChat,
   Icon: ChatBubbleBottomCenterTextIcon,
   path: "/chat",
   title: L["Chat"],
   selected: isChat,
  },
  {
   Icon: MapIcon,
   path: "/coupon",
   title: L["Map"],
   selected: isMap,
  },
 ]?.filter((v) => !v.skip);

 useEffect(() => {
  const body = document.getElementsByTagName("body").item(0);
  if (!iamTopWindow && body && !isChat) {
   body.style.overflow = "hidden";
  }
 }, []);
 useIframeHeightSender();
 if (!store) return null;

 return (
  <div id="customerLayout" className="max-w-screen-sm mx-auto">
   <Helmet>
    <title>{lang(page?.name || store.name || {}) || "Hotel Talk"}</title>
   </Helmet>
   <Suspense>
    <Outlet key={store._id}></Outlet>
   </Suspense>
   {!isIntro && (
    <div className="w-full fixed max-w-screen-sm mx-auto left-1/2 -translate-x-1/2 bottom-0 h-[17.78vw] xs:h-[76.8px] py-[2.78vw] xs:py-[12.8px] bg-white border-t border-neutral-200 justify-center items-center gap-[7.78vw] xs:gap-[35.84px] inline-flex">
     {NavSet?.map((nav) => {
      const Icon = nav.Icon;

      return (
       <div
        onClick={() => {
         navigate(nav.path);
        }}
        key={nav.path + "nav"}
        className={classNames(
         "cursor-pointer text-neutral-400 w-[15.56vw] xs:w-[71.68px] flex-col justify-center items-center gap-[2.78vw] xs:gap-[12.8px] inline-flex",
         {
          "text-primary-500": nav.selected,
         }
        )}
       >
        <Icon className="w-[6.67vw] xs:w-[25.6px] h-[6.67vw] xs:h-[25.6px] relative" />
        <div
         className={classNames(
          "whitespace-nowrap text-center text-[3.33vw] xs:text-[14.4px] font-semibold font-['Pretendard'] leading-[3.33vw] xs:leading-[14.4px]"
         )}
        >
         {nav.title}
        </div>
       </div>
      );
     })}
    </div>
   )}
   {imgViewModalHook?.isOpen && (
    <ImagesViewModal modalhook={imgViewModalHook} />
   )}
   {roomNumberPOpModal?.isOpen && (
    <RoomNumberPopModal modalhook={roomNumberPOpModal} />
   )}
  </div>
 );
}
