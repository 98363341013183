import { useContext, useState } from "react";
import { FpageFragment } from "../app/apollo/type/graphql";

import { EditImageBox } from "./EditBox.image";
import { EditBoxTitle } from "./EditBox.title";

// Square: "Square",
// Info:  "Info",
// Verti: "Verti",
// PageBlock: "PageBlock",
// PageInfo: "PageInfo",

export interface IPageEditInfo {}

interface IProp {
 page: FpageFragment;
 setKr: any;
 set: any;
}

export const CommonPageEditBox: React.FC<IProp> = ({ page, setKr, set }) => {
 return (
  <>
   <EditImageBox
    title="페이지 메인이미지"
    value={page?.image || undefined}
    onChange={(file) => {
     page["image"] = file!;
     set();
    }}
   />
   <EditBoxTitle
    title={"페이지 타이틀"}
    value={page?.name?.ko}
    onChange={(val) => {
     setKr(page, "name", val);
    }}
   />
  </>
 );
};

export default CommonPageEditBox;
