import classNames from "classnames";
import React, { Fragment } from "react";
import { Switch } from "@headlessui/react";

interface IProp extends React.HTMLAttributes<HTMLDivElement> {
 checked?: boolean;
 onChange?: () => void;
 label?: string;
}

export const BasicSwitch: React.FC<IProp> = ({
 label,
 checked,
 onChange,
 className,
 ...props
}) => {
 const wrapClassnames = classNames(
  className,
  "cursor-pointer text-neutral-500 hover:text-neutral-800 outline-none flex gap-x-2 items-center h-8 justify-between"
 );
 const ballClassnames = classNames(
  "transition rounded-full  w-4 h-4 absolute bg-white",
  {
   "right-[2px]": checked,
   "left-[2px]": !checked,
  }
 );

 return (
  <Switch checked={checked} onChange={onChange} as={Fragment}>
   {({ checked }) => (
    <div className={wrapClassnames} {...props}>
     <span className="text-sm">{label}</span>
     <button
      className={`${
       checked ? "bg-blue-600" : "bg-neutral-300"
      } relative inline-flex h-5 w-9 items-center rounded-full`}
     >
      <span
       className={`${
        checked ? "translate-x-[18px]" : "translate-x-[2px]"
       } inline-block h-4 w-4 transform rounded-full bg-white transition`}
      />
     </button>
    </div>
   )}
  </Switch>
 );
};
