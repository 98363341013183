import { gql } from "@apollo/client";
import {
 F_COLLECTION_DATA_INTERFACE,
 F_INPUTERROR,
 F_PAGEINFO,
} from "./common";
import { F_FILE } from "./file";

export const F_USER = gql`
 fragment Fuser on User {
  ...FcollectionDataInterface
  role
  noChat
  name
  maxPage
 }
 ${F_COLLECTION_DATA_INTERFACE}
`;

export const ME = gql`
 query me {
  Me {
   ...Fuser
  }
 }
 ${F_USER}
 ${F_INPUTERROR}
`;

export const USER_REGIST = gql`
 mutation userRegist($name: String!, $password: String!, $domain: String!) {
  UserRegist(name: $name, domain: $domain, password: $password) {
   ok
   error {
    ...FinputError
   }
  }
 }
 ${F_INPUTERROR}
`;
export const USER_CREATE = gql`
 query userCreateOrFind($input: UserInput!) {
  UserCreateOrFind(input: $input) {
   ok
   error {
    ...FinputError
   }
   data {
    ...Fuser
   }
  }
 }
 ${F_USER}
 ${F_INPUTERROR}
`;

export const USER_FIND_BY_NFC_ID = gql`
 query userFindByNfcId($nfcId: String!) {
  UserFindByNfcId(nfcId: $nfcId) {
   ...Fuser
  }
 }
 ${F_USER}
 ${F_INPUTERROR}
`;

export const USER_UPDATE = gql`
 mutation userUpdate(
  $userId: String!
  $name: String!
  $password: String
  $domain: String!
 ) {
  UserUpdate(
   userId: $userId
   name: $name
   password: $password
   domain: $domain
  ) {
   ok
   error {
    ...FinputError
   }
  }
 }
 ${F_INPUTERROR}
`;

export const USER_VALUE_UPDATE = gql`
 mutation userValueUpdate($userId: String!, $input: UserInput!) {
  UserValueUpdate(userId: $userId, input: $input) {
   ok
   error {
    ...FinputError
   }
  }
 }
 ${F_INPUTERROR}
`;

export const USER_DELETE = gql`
 mutation userDelete($userId: String!) {
  UserDelete(userId: $userId) {
   ok
   error {
    ...FinputError
   }
  }
 }
 ${F_INPUTERROR}
`;

export const USER_LIST = gql`
 query userList(
  $sort: [_UserSort!]
  $filter: _UserFilter
  $pagingInput: OffsetPagingInput!
 ) {
  UserList(sort: $sort, pagingInput: $pagingInput, filter: $filter) {
   pageInfo {
    ...FoffsetPagingInfo
   }
   items {
    ...Fuser
    Store {
     name
     pages {
      key
     }
     mainImage {
      uri
     }
     domain
     pagesCnt
    }
   }
  }
 }
 ${F_USER}
 ${F_PAGEINFO}
`;
