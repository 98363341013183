import classNames from "classnames";
import { Category } from "../../app/apollo/type/graphql";
import { Photo } from "../../components/Photo";
import { L } from "../../data/lang.static";
import { Airplan, CategoryImgMap } from "../../data/const";
import { twMerge } from "tailwind-merge";

interface IProp {
 className?: string;
 iconClassName?: string;
 categoryKey: Category;
}

export const CategoryBadge: React.FC<IProp> = ({
 categoryKey,
 iconClassName,
 className,
}) => {
 return (
  <div
   className={twMerge(
    classNames(
     "KBbadge flex items-center gap-x-[0.5vw] xs:gap-x-[1.2px] py-[0.8vw] xs:py-[3.6px] px-[1.5vw] xs:px-[7.68px] rounded-[0.8vw] xs:rounded-[4.8px]",
     className,
     {
      "bg-teal-400": categoryKey === Category.Attractions,
      "bg-red-400": categoryKey === Category.Cafe,
      "bg-amber-400": categoryKey === Category.Convention,
     }
    )
   )}
  >
   <Photo
    width={20}
    height={20}
    className={twMerge(
     classNames(
      "xs:w-[12px] xs:h-[12px] brightness-[500] w-[2.7vw] h-[2.7vw]",
      iconClassName
     )
    )}
    src={CategoryImgMap[categoryKey] || Airplan}
    alt={"아이콘"}
   />
  </div>
 );
};
