import { getFromUrl } from "../utils/getFromUrl";
import {
 LocalStorage,
 iamTopWindow,
} from "../utils/localstorage/StorageAddition";
import { LabelValuePair } from "./type";

const langData = {
 select: {
  ko: "선택",
  ja: "選択",
  en: "Select",
  zh: "选择",
  zhTW: "選擇",
  zhHK: "選擇",
  th: "เลือก",
  vi: "Chọn",
  tl: "Pumili",
  ms: "Pilih",
  id: "Pilih",
  hi: "चुनें",
  fr: "Sélectionner",
  de: "Wählen",
  enGB: "Select",
  enCA: "Select",
  mn: "Сонгох",
  it: "Seleziona",
  es: "Seleccionar",
 },
 required: {
  ko: "필수",
  ja: "必須",
  en: "Required",
  zh: "必填",
  zhTW: "必填",
  zhHK: "必填",
  th: "จำเป็น",
  vi: "Bắt buộc",
  tl: "Kailangan",
  ms: "Diperlukan",
  id: "Diperlukan",
  hi: "आवश्यक",
  fr: "Requis",
  de: "Erforderlich",
  enGB: "Required",
  enCA: "Required",
  mn: "Шаардлагатай",
  it: "Obbligatorio",
  es: "Obligatorio",
 },

 pleaseEnterRequire: {
  ko: "필수 사항을 입력 해주세요.",
  ja: "必須項目を入力してください。",
  en: "Please enter the required information.",
  zh: "请输入必填项。",
  zhTW: "請輸入必填項。",
  zhHK: "請輸入必填項。",
  th: "โปรดกรอกข้อมูลที่จำเป็น",
  vi: "Vui lòng nhập thông tin bắt buộc.",
  tl: "Pakipasok ang kinakailangang impormasyon.",
  ms: "Sila masukkan maklumat yang diperlukan.",
  id: "Silakan masukkan informasi yang diperlukan.",
  hi: "कृपया आवश्यक जानकारी दर्ज करें।",
  fr: "Veuillez entrer les informations requises.",
  de: "Bitte geben Sie die erforderlichen Informationen ein.",
  enGB: "Please enter the required information.",
  enCA: "Please enter the required information.",
  mn: "Шаардлагатай мэдээллийг оруулна уу.",
  it: "Inserisci le informazioni richieste.",
  es: "Por favor, introduzca la información requerida.",
 },
 PM: {
  ko: "오후",
  ja: "午後",
  en: "PM",
  zh: "下午",
  zhTW: "下午",
  zhHK: "下午",
  th: "บ่าย",
  vi: "Chiều",
  tl: "Hapon",
  ms: "Petang",
  id: "Sore",
  hi: "अपराह्न",
  fr: "PM",
  de: "PM",
  enGB: "PM",
  enCA: "PM",
  mn: "Үдээс хойш",
  it: "PM",
  es: "PM", // Spanish
 },
 UseExpire: {
  ko: "사용 만료일",
  ja: "使用期限",
  en: "Use Expiry Date",
  zh: "使用期限",
  zhTW: "使用期限",
  zhHK: "使用期限",
  th: "วันหมดอายุการใช้",
  vi: "Ngày hết hạn sử dụng",
  tl: "Petsa ng Pag-expire ng Paggamit",
  ms: "Tarikh Luput Penggunaan",
  id: "Tanggal Kedaluwarsa Penggunaan",
  hi: "उपयोग समाप्ति तिथि",
  fr: "Date d'expiration d'utilisation",
  de: "Verfallsdatum",
  enGB: "Use Expiry Date",
  enCA: "Use Expiry Date",
  mn: "Хэрэглэх дуусах огноо",
  it: "Data di Scadenza",
  es: "Fecha de caducidad", // Spanish
 },
 CouponUseInfo: {
  ko: "쿠폰 사용 방법",
  ja: "クーポン使用方法",
  en: "Coupon Use Information",
  zh: "优惠券使用信息",
  zhTW: "優惠券使用資訊",
  zhHK: "優惠券使用資訊",
  th: "ข้อมูลการใช้คูปอง",
  vi: "Thông tin sử dụng phiếu giảm giá",
  tl: "Impormasyon sa Paggamit ng Kupon",
  ms: "Maklumat Penggunaan Kupon",
  id: "Informasi Penggunaan Kupon",
  hi: "कूपन उपयोग जानकारी",
  fr: "Informations sur l'utilisation du coupon",
  de: "Gutschein-Nutzungsinformationen",
  enGB: "Coupon Use Information",
  enCA: "Coupon Use Information",
  mn: "Купоныг ашиглах мэдээлэл",
  it: "Informazioni sull'uso del coupon",
  es: "Información de uso del cupón", // Spanish
 },
 Attractions: {
  ko: "카페",
  ja: "カフェ",
  en: "Cafe",
  zh: "咖啡馆",
  zhTW: "咖啡館",
  zhHK: "咖啡館",
  th: "คาเฟ่",
  vi: "Quán cà phê",
  tl: "Kapihan",
  ms: "Kafe",
  id: "Kafe",
  hi: "कैफ़े",
  fr: "Café",
  de: "Café",
  enGB: "Cafe",
  enCA: "Cafe",
  mn: "Кофе шоп",
  it: "Caffè",
  es: "Cafetería", // Spanish
 },
 Confirm: {
  ko: "확인",
  ja: "確認",
  en: "Confirm",
  zh: "确认",
  zhTW: "確認",
  zhHK: "確認",
  th: "ยืนยัน",
  vi: "Xác nhận",
  tl: "Kumpirmahin",
  ms: "Sahkan",
  id: "Konfirmasi",
  hi: "पुष्टि करें",
  fr: "Confirmer",
  de: "Bestätigen",
  enGB: "Confirm",
  enCA: "Confirm",
  mn: "Баталгаажуулах",
  it: "Conferma",
  es: "Confirmar", // Spanish
 },
 Cafe: {
  ko: "맛집",
  ja: "レストラン",
  en: "Restaurants",
  zh: "美食店",
  zhTW: "美食店",
  zhHK: "美食店",
  th: "ร้านอาหาร",
  vi: "Nhà hàng",
  tl: "Mga restawran",
  ms: "Restoran",
  id: "Restoran",
  hi: "रेस्टोरेंट",
  fr: "Restaurants",
  de: "Restaurants",
  enGB: "Restaurants",
  enCA: "Restaurants",
  mn: "Ресторанууд",
  it: "Ristoranti",
  es: "Restaurantes", // Spanish
 },
 CouponComplete: {
  ko: "쿠폰사용이 완료되었습니다.",
  ja: "クーポンの使用が完了しました。",
  en: "Coupon use has been completed.",
  zh: "优惠券使用已完成。",
  zhTW: "優惠券使用已完成。",
  zhHK: "優惠券使用已完成。",
  th: "การใช้คูปองเสร็จสมบูรณ์",
  vi: "Sử dụng phiếu giảm giá đã hoàn thành.",
  tl: "Tapos na ang paggamit ng kupon.",
  ms: "Penggunaan kupon telah selesai.",
  id: "Penggunaan kupon telah selesai.",
  hi: "कूपन का उपयोग पूरा हो गया है।",
  fr: "L'utilisation du coupon est terminée.",
  de: "Die Nutzung des Gutscheins ist abgeschlossen.",
  enGB: "Coupon use has been completed.",
  enCA: "Coupon use has been completed.",
  mn: "Купоныг ашиглалт дууссан.",
  it: "L'uso del coupon è stato completato.",
  es: "El uso del cupón se ha completado.", // Spanish
 },
 Information: {
  ko: "액티비티",
  ja: "アクティビティ",
  en: "Activities",
  zh: "活动",
  zhTW: "活動",
  zhHK: "活動",
  th: "กิจกรรม",
  vi: "Hoạt động",
  tl: "Mga Gawain",
  ms: "Aktiviti",
  id: "Kegiatan",
  hi: "गतिविधियां",
  fr: "Activités",
  de: "Aktivitäten",
  enGB: "Activities",
  enCA: "Activities",
  mn: "Үйл ажиллагаа",
  it: "Attività",
  es: "Actividades", // Spanish
 },
 Convention: {
  ko: "엑티비티",
  ja: "アクティビティ",
  en: "Activity",
  zh: "活动",
  zhTW: "活動",
  zhHK: "活動",
  th: "กิจกรรม",
  vi: "Hoạt động",
  tl: "Aktibidad",
  ms: "Aktiviti",
  id: "Aktivitas",
  hi: "गतिविधि",
  fr: "Activité",
  de: "Aktivität",
  enGB: "Activity",
  enCA: "Activity",
  mn: "Үйл ажиллагаа",
  it: "Attività",
  es: "Actividad", // Spanish
 },
 UseCoupon: {
  ko: "쿠폰 사용하기",
  ja: "クーポンを使用する",
  en: "Use Coupon",
  zh: "使用优惠券",
  zhTW: "使用優惠券",
  zhHK: "使用優惠券",
  th: "ใช้คูปอง",
  vi: "Sử dụng phiếu giảm giá",
  tl: "Gamitin ang Kupon",
  ms: "Guna Kupon",
  id: "Gunakan Kupon",
  hi: "कूपन का उपयोग करें",
  fr: "Utiliser le coupon",
  de: "Gutschein verwenden",
  enGB: "Use Coupon",
  enCA: "Use Coupon",
  mn: "Купоныг ашиглах",
  it: "Usa il coupon",
  es: "Usar cupón", // Spanish
 },
 Map: {
  ko: "지도",
  ja: "地図",
  en: "Map",
  zh: "地图",
  zhTW: "地圖",
  zhHK: "地圖",
  th: "แผนที่",
  vi: "Bản đồ",
  tl: "Mapa",
  ms: "Peta",
  id: "Peta",
  hi: "मानचित्र",
  fr: "Carte",
  de: "Karte",
  enGB: "Map",
  enCA: "Map",
  mn: "Газрын зураг",
  it: "Mappa",
  es: "Mapa", // Spanish
 },
 "Start Now": {
  ko: "지금 시작하기",
  en: "Start Now",
  zh: "立即开始",
  zhTW: "立即開始",
  zhHK: "立即開始",
  th: "เริ่มเลย",
  vi: "Bắt đầu ngay",
  tl: "Simulan Ngayon",
  ms: "Mula Sekarang",
  id: "Mulai Sekarang",
  ja: "今すぐ開始",
  hi: "अभी शुरू करें",
  fr: "Commencez maintenant",
  de: "Jetzt starten",
  enGB: "Start Now",
  enCA: "Start Now",
  mn: "Одоо эхлэх",
  it: "Inizia ora",
  es: "Empieza ahora", // Spanish
 },
 Home: {
  ko: "메인",
  en: "Home",
  zh: "首页",
  zhTW: "首頁",
  zhHK: "首頁",
  th: "หน้าหลัก",
  vi: "Trang chủ",
  tl: "Bahay",
  ms: "Laman Utama",
  id: "Beranda",
  ja: "ホーム",
  hi: "होम",
  fr: "Accueil",
  de: "Startseite",
  enGB: "Home",
  enCA: "Home",
  mn: "Нүүр",
  it: "Home",
  es: "Inicio", // Spanish
 },
 Chat: {
  ko: "채팅",
  en: "Chat",
  zh: "聊天",
  zhTW: "聊天",
  zhHK: "聊天",
  th: "แชท",
  vi: "Trò chuyện",
  tl: "Chat",
  ms: "Sembang",
  id: "Obrolan",
  ja: "チャット",
  hi: "चैट",
  fr: "Chat",
  de: "Chat",
  enGB: "Chat",
  enCA: "Chat",
  mn: "Чат",
  it: "Chat",
  es: "Chat", // Spanish
 },
 Room: {
  ko: "방",
  en: "Room",
  zh: "房间",
  zhTW: "房間",
  zhHK: "房間",
  th: "ห้อง",
  vi: "Phòng",
  tl: "Kwarto",
  ms: "Bilik",
  id: "Kamar",
  ja: "部屋",
  hi: "कमरा",
  fr: "Chambre",
  de: "Zimmer",
  enGB: "Room",
  enCA: "Room",
  mn: "Өрөө",
  it: "Stanza",
  es: "Habitación", // Spanish
 },
 WELLCOME: {
  ko: "환영합니다!",
  en: "WELLCOME",
  zh: "欢迎",
  zhTW: "歡迎",
  zhHK: "歡迎",
  th: "ยินดีต้อนรับ",
  vi: "Chào mừng",
  tl: "Maligayang pagdating",
  ms: "Selamat datang",
  id: "Selamat datang",
  ja: "ようこそ",
  hi: "स्वागत हे",
  fr: "Bienvenue",
  de: "Willkommen",
  enGB: "WELLCOME",
  enCA: "WELLCOME",
  mn: "Тавтай морилно уу",
  it: "Benvenuto",
  es: "Bienvenido", // Spanish
 },
 RoomPopIntro: {
  ko: "고객님의 정보 보호를 위해 객실 번호 입력 후 사용하세요",
  en: "For your information protection, please enter the room number",
  ja: "お客様の情報保護のため、部屋番号を入力してください",
  zh: "为保护您的信息，请输入房间号",
  zhTW: "為了保護您的信息，請輸入房間號",
  zhHK: "為了保護您的信息，請輸入房間號",
  th: "เพื่อปกป้องข้อมูลของคุณ กรุณากรอกหมายเลขห้อง",
  vi: "Để bảo vệ thông tin của bạn, vui lòng nhập số phòng",
  tl: "Para sa proteksyon ng iyong impormasyon, mangyaring ipasok ang numero ng silid",
  ms: "Untuk melindungi maklumat anda, sila masukkan nombor bilik",
  id: "Untuk melindungi informasi Anda, silakan masukkan nomor kamar",
  hi: "आपकी जानकारी की सुरक्षा के लिए कृपया कमरे का नंबर दर्ज करें",
  fr: "Pour protéger vos informations, veuillez entrer le numéro de la chambre",
  de: "Zum Schutz Ihrer Informationen geben Sie bitte die Zimmernummer ein",
  enGB: "For your information protection, please enter the room number",
  enCA: "For your information protection, please enter the room number",
  mn: "Таны мэдээллийг хамгаалах үүднээс өрөөний дугаарыг оруулна уу",
  it: "Per proteggere le tue informazioni, inserisci il numero della stanza",
  es: "Para proteger su información, ingrese el número de la habitación", // Spanish
 },
 RoomPopIntroExample: {
  ko: "예) 535호 → 0535 입력",
  en: "e.g., Room 535 → Enter 0535",
  ja: "例）535号室 → 0535を入力",
  zh: "例如：房间535 → 输入0535",
  zhTW: "例如：房間535 → 輸入0535",
  zhHK: "例如：房間535 → 輸入0535",
  th: "ตัวอย่าง: ห้อง 535 → กรอก 0535",
  vi: "Ví dụ: Phòng 535 → Nhập 0535",
  tl: "Halimbawa: Silid 535 → Ipasok ang 0535",
  ms: "Contoh: Bilik 535 → Masukkan 0535",
  id: "Contoh: Kamar 535 → Masukkan 0535",
  hi: "उदा।, कमरा 535 → दर्ज करें 0535",
  fr: "par exemple, Chambre 535 → Entrez 0535",
  de: "z.B. Zimmer 535 → 0535 eingeben",
  enGB: "e.g., Room 535 → Enter 0535",
  enCA: "e.g., Room 535 → Enter 0535",
  mn: "Жишээ нь: Өрөө 535 → 0535 оруулах",
  it: "esempio: Stanza 535 → Inserisci 0535",
  es: "p.ej., Habitación 535 → Ingrese 0535", // Spanish
 },
 ChatProblom: {
  ko: "채팅방 연결에 문제가 있습니다.",
  en: "Problem connecting to the chat room.",
  ja: "チャットルームの接続に問題があります。",
  zh: "连接聊天室时出现问题。",
  zhTW: "連接聊天室時出現問題。",
  zhHK: "連線聊天室時出現問題。",
  th: "มีปัญหาในการเชื่อมต่อห้องแชท。",
  vi: "Có vấn đề khi kết nối đến phòng chat.",
  tl: "May problema sa pagkonekta sa chat room.",
  ms: "Masalah menyambung ke bilik sembang.",
  id: "Ada masalah menghubungkan ke ruang obrolan.",
  hi: "चैट रूम से कनेक्ट करने में समस्या है।",
  fr: "Problème de connexion à la salle de chat.",
  de: "Problem beim Verbinden zum Chatraum.",
  enGB: "Problem connecting to the chat room.",
  enCA: "Problem connecting to the chat room.",
  mn: "Чат өрөөтэй холбогдоход асуудал гарлаа.",
  it: "Problema di connessione alla chat room.",
  es: "Problema al conectar con la sala de chat.", // Spanish
 },
 Submit: {
  ko: "완료",
  en: "ENTER",
  ja: "エンター",
  zh: "提交",
  zhTW: "提交",
  zhHK: "提交",
  th: "เข้าสู่ระบบ",
  vi: "NHẬP",
  tl: "IPASOK",
  ms: "MASUK",
  id: "MASUK",
  hi: "जमा करें",
  fr: "ENTRER",
  de: "EINGEBEN",
  enGB: "ENTER",
  enCA: "ENTER",
  mn: "ОРУУЛАХ",
  it: "INVIO",
  es: "INTRODUCIR", // Spanish
 },
 Language: {
  ko: "언어",
  en: "Language",
  ja: "言語",
  zh: "语言",
  zhTW: "語言",
  zhHK: "語言",
  th: "ภาษา",
  vi: "Ngôn ngữ",
  tl: "Wika",
  ms: "Bahasa",
  id: "Bahasa",
  hi: "भाषा",
  fr: "Langue",
  de: "Sprache",
  enGB: "Language",
  enCA: "Language",
  mn: "Хэл",
  it: "Lingua",
  es: "Idioma", // Spanish
 },
 ChatStartEx: {
  ko: `고객님의 정보 보호를 위해 객실 번호 입력 후 사용하세요\n예) 535호 → 0535 입력`,
  ja: `고객님의 정보 보호를 위해 객실 번호 입력 후 사용하세요\n예) 535호 → 0535 입력`,
  en: `For your information protection, please enter the room number\nExample: Room 535 → Enter 0535`,
  zh: `为了保护您的信息，请输入房间号\n例如：房间 535 → 输入 0535`,
  zhTW: `為了保護您的信息，請輸入房間號\n例如：房間 535 → 輸入 0535`,
  zhHK: `為了保護您的信息，請輸入房間號\n例如：房間 535 → 輸入 0535`,
  th: `เพื่อปกป้องข้อมูลของคุณ กรุณากรอกหมายเลขห้อง\nตัวอย่าง: ห้อง 535 → กรอก 0535`,
  vi: `Để bảo vệ thông tin của bạn, vui lòng nhập số phòng\nVí dụ: Phòng 535 → Nhập 0535`,
  tl: `Para sa proteksyon ng iyong impormasyon, mangyaring ipasok ang numero ng silid\nHalimbawa: Silid 535 → Ipasok ang 0535`,
  ms: `Untuk melindungi maklumat anda, sila masukkan nombor bilik\nContoh: Bilik 535 → Masukkan 0535`,
  id: `Untuk melindungi informasi Anda, silakan masukkan nomor kamar\nContoh: Kamar 535 → Masukkan 0535`,
  hi: `आपकी जानकारी की सुरक्षा के लिए कृपया कमरे का नंबर दर्ज करें\nउदाहरण: कमरा 535 → दर्ज करें 0535`,
  fr: `Pour protéger vos informations, veuillez entrer le numéro de la chambre\nExemple: Chambre 535 → Entrez 0535`,
  de: `Zum Schutz Ihrer Informationen geben Sie bitte die Zimmernummer ein\nBeispiel: Zimmer 535 → 0535 eingeben`,
  enGB: `For your information protection, please enter the room number\nExample: Room 535 → Enter 0535`,
  enCA: `For your information protection, please enter the room number\nExample: Room 535 → Enter 0535`,
  mn: `Таны мэдээллийг хамгаалах үүднээс өрөөний дугаарыг оруулна уу\nЖишээ нь: Өрөө 535 → 0535 оруулах`,
  it: `Per proteggere le tue informazioni, inserisci il numero della stanza\nEsempio: Stanza 535 → Inserisci 0535`,
  es: `Para proteger su información, ingrese el número de la habitación\np.ej., Habitación 535 → Ingrese 0535`, // Spanish
 },
 Language_Desc: {
  ko: "표기 언어를 변경합니다.",
  en: "Please select the language you want to use",
  ja: "表示言語を変更します。",
  zh: "更改显示语言",
  th: "เปลี่ยนภาษาที่แสดง",
  vi: "Thay đổi ngôn ngữ hiển thị",
  tl: "Baguhin ang wika ng display",
  ms: "Tukar bahasa paparan",
  id: "Ganti bahasa tampilan",
  hi: "दिखाई जाने वाली भाषा बदलें",
  fr: "Modifier la langue affichée",
  de: "Ändere die angezeigte Sprache",
  mn: "Харуулах хэлийг сонгоно уу",
  it: "Si prega di selezionare la lingua che si desidera utilizzare",
  es: "Por favor seleccione el idioma que desea usar", // Spanish
 },
};

export const L: Record<keyof typeof langData, string> = {} as any;
export const LagnList = [
 "ko", // Korean
 "en", // English
 "zh", // Chinese (Simplified)
 //  "zhTW", // Taiwanese Mandarin (Traditional) 홍콩어랑 동일함
 "zhHK", // Hong Kong Cantonese (Traditional)
 "th", // Thai
 "vi", // Vietnamese
 "ms", // Malay
 "id", // Indonesian
 "ja", // Japanese
 "hi", // Hindi
 "fr", // French
 "de", // German
 "it", // Italian
 "es", // Spanish
];
export const LangPair = {
 ko: "한국어",
 en: "English",
 zh: "中文",
 //  zhTW: "繁體中文 (台灣)",
 zhHK: "繁體中文 (香港)",
 th: "ภาษาไทย",
 vi: "Tiếng Việt",
 tl: "Filipino",
 ms: "Bahasa Melayu",
 id: "Bahasa Indonesia",
 ja: "日本語",
 hi: "हिन्दी",
 fr: "Français",
 de: "Deutsch",
 mn: "Монгол",
 it: "Italiano",
 es: "Español",
};

export const LangOps: LabelValuePair[] = LagnList?.map((lang) => {
 return {
  label: LangPair[lang as keyof typeof LangPair],
  value: lang,
 };
});
export const awsLangCodeMap: Map<string, string> = new Map([
 ["ko", "ko-KR"],
 ["en", "en-US"],
 ["zh", "cmn-CN"], // Mandarin Chinese (Simplified, China)
 //  ["zhTW", "cmn-TW"], // Taiwanese Mandarin (Traditional, Taiwan)
 ["zhHK", "yue-CN"], // Cantonese (Traditional, Hong Kong)
 ["th", "th-TH"], // Thai (Thailand)
 ["vi", "vi-VN"], // Vietnamese (Vietnam)
 ["tl", "fil-PH"], // Filipino (Philippines)
 ["ms", "ms-MY"], // Malay (Malaysia)
 ["id", "id-ID"], // Indonesian (Indonesia)
 ["ja", "ja-JP"], // Japanese (Japan)
 ["hi", "hi-IN"], // Hindi (India)
 ["fr", "fr-FR"], // French (France)
 ["de", "de-DE"], // German (Germany)
 ["enGB", "en-GB"], // British English (United Kingdom)
 ["enCA", "en-CA"], // Canadian English (Canada)
 ["mn", "mn-MN"], // Mongolian (Mongolia)
 ["it", "it-IT"], // Italian (Italy)
 ["es", "es-ES"], // Spanish (Spain)
]);
const reversedMap = new Map<string, string>(
 Array.from(awsLangCodeMap, (a) => a.reverse() as any)
);

export const CACHE = {
 langCode: LocalStorage.getItem("lang") || "",
};
export const getLangCode = (_lang: string) => {
 //  const urlForceLang = getFromUrl("lang");
 if (!iamTopWindow) return "ko";
 //  if (urlForceLang) return urlForceLang;
 if (CACHE.langCode) return CACHE.langCode;

 const lang = _lang?.toLowerCase(); // Convert to lowercase for consistency
 let langCode = "en";

 // Check if the full language code is in the list
 if (LagnList.includes(lang)) {
  langCode = lang;
 }
 // Check if the language without region code (part before hyphen) is in the list
 else if (lang.includes("-") && LagnList.includes(lang.split("-")[0])) {
  langCode = lang.split("-")[0];
 }
 // Fallback to reversed map if available
 else if (reversedMap.get(lang)) {
  langCode = reversedMap.get(lang) as any;
 }

 return langCode;
};

export const LangSet = (lang: string) => {
 const langCode = getLangCode(lang);

 const keys = Object.keys(langData);
 keys.forEach((key) => {
  //@ts-ignore
  L[key] = langData[key][langCode] || langData[key]["en"];
 });
 console.log("@@@L", { L, langCode });
};
export default langData;

export const unspportedAwsLangCodes = [
 "ms",
 "ms-MY",
 "th",
 "th-TH",
 "vi",
 "vi-VN",
 "tl",
 "fil-PH",
 "id",
 "mn",
];
