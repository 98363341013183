import { Listbox, Transition } from "@headlessui/react";
import React, { ReactNode, useId } from "react";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { LabelValuePair } from "../data/type";

type Option = LabelValuePair & React.ComponentProps<typeof Listbox.Option>;

export const withDefaultOp = (
 options: Option[],
 label: string = "선택없음"
) => {
 const newOptions = [
  {
   value: undefined,
   label,
  },
  ...options,
 ];
 return newOptions;
};

interface IProp
 extends Omit<
  React.HTMLProps<HTMLDivElement>,
  "label" | "onChange" | "placeholder" | "value" | "size"
 > {
 placeholder?: ReactNode;
 value?: any;
 onChange: (val: any) => void;
 options?: Option[];
 selectFormat?: (value: any) => ReactNode;
 label?: React.ReactNode;
 optionClasses?: string;
 buttonClasses?: string;
 size?: "L";
}

export const Select: React.FC<IProp> = ({
 value,
 label,
 placeholder = "선택",
 onChange: handleChange,
 options,
 selectFormat = (val: any) => val,
 optionClasses,
 size,
 className,
 buttonClasses: propButtonClasses,
 ...props
}) => {
 const id = useId();

 return (
  <div className={classNames(" w-full", className)} {...props}>
   <Listbox value={value} onChange={handleChange}>
    {({ open }) => {
     const buttonClasses = twMerge(
      classNames(
       "relative flex rounded text-sm items-center w-full h-11 justify-between border border-neutral-200 text-neutral-800 outline-none px-3",
       {
        "border-indigo-700": open,
        "text-neutral-200 ": !value && placeholder,
        "text-sm h-11 text-sm font-normal": size === "L",
       },
       propButtonClasses
      )
     );

     const Chevron = open ? ChevronUpIcon : ChevronDownIcon;

     const selectedLabel = options?.find((op) => op.value === value)?.label;
     return (
      <>
       {label && <p className="mb-1   text-neutral-500 font-light ">{label}</p>}
       <Listbox.Button className={buttonClasses}>
        {selectFormat(selectedLabel || placeholder)}{" "}
        <Chevron className="absolute right-4 ml-1 w-6 text-neutral-200" />
        {open && (
         <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
         >
          <Listbox.Options
           className={
            "p-2 mt-1 shadow rounded  z-40 top-full  absolute left-0 right-0 bg-white"
           }
          >
           {options?.map((option) => (
            <Listbox.Option
             className={twMerge(
              classNames(
               "my-px px-3 text-neutral-800 flex hover:bg-neutral-50 items-center cursor-pointer h-9 rounded  font-normal",
               {
                "bg-indigo-50 hover:bg-indigo-50 text-neutral-800":
                 option.value === value,
               },
               optionClasses
              )
             )}
             key={option.value + id}
             {...option}
            >
             {option.label}
            </Listbox.Option>
           ))}
          </Listbox.Options>
         </Transition>
        )}
       </Listbox.Button>
      </>
     );
    }}
   </Listbox>
  </div>
 );
};
