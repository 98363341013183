import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { useId } from "react";
import { Transition } from "@headlessui/react";

interface IProp {
 show?: boolean;
 validates: {
  skip?: boolean;
  ok: boolean;
  okMessage?: string;
  noMessage: string;
 }[];
}

export const ValidaterView: React.FC<IProp> = ({ validates, show }) => {
 const uid = useId();

 if (!show) return null;

 const nodes = validates
  .filter((v) => !v.skip)
  .filter((v) => {
   return v.ok ? v.okMessage : v.noMessage;
  });

 return (
  <Transition
   as={"div"}
   show={!!show && !!nodes.length}
   enter="transition ease-out duration-500 "
   enterFrom="opacity-0 "
   enterTo="opacity-100 "
   leave="transition ease-in duration-450"
   leaveFrom="opacity-100 "
   leaveTo="opacity-0 "
  >
   <ul className="flex flex-col gap-2 mt-2">
    {nodes.map((vd, index) => {
     const Icon = vd.ok ? CheckIcon : XMarkIcon;

     return (
      <li
       className={classNames("flex gap-1 items-center text-xs", {
        "text-green-500": vd.ok,
        "text-red-500": !vd.ok,
       })}
       key={index + "" + uid}
      >
       <Icon strokeWidth={2.5} className={classNames("w-4 h-4 relative")} />
       {vd.ok ? vd.okMessage : vd.noMessage}
      </li>
     );
    })}
   </ul>
  </Transition>
 );
};
