export function validateLink(str: string) {
 if (str === "#") return true;
 try {
  // Try full URL
  new URL(str);
  return true;
 } catch (err) {
  try {
   // Try relative path
   new URL(str, "http://example.com");
   return true;
  } catch (_) {
   return false;
  }
 }
}

export const isUrl = (string: string): boolean | "" => {
 if (string === "") return "";
 const regExp =
  /^http(s)?:\/\/(www\.)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
 return regExp.test(string);
};

export function isNumberOnly(str: string): boolean {
 return /^\d+$/.test(str);
}

export function isValidHexColor(color: string): boolean {
 // Regex pattern to match valid hexadecimal color codes
 const pattern = /^#([A-Fa-f0-9]{3,4}|[A-Fa-f0-9]{6}|[A-Fa-f0-9]{8})$/;
 return pattern.test(color);
}

export function isKoreanIncluded(str: string): boolean {
 // Regular expression to match Korean characters
 const koreanRegex = /[\u3131-\uD79D]/g;

 // Test the string against the regular expression
 return koreanRegex.test(str);
}

export function isEnglish(text: string): boolean {
 // Regular expression pattern that matches English characters, common punctuations, and numbers
 const englishPattern = /^[A-Za-z0-9\s.,!?'"()\-]+$/;
 return englishPattern.test(text);
}

export const removeSpecialChar = (str: string, replace = "_") => {
 if (!str) return str;
 const test = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi;
 return str.replace(test, replace);
};

export function isValidSubdomain(subdomain: string) {
 // Subdomain must be between 1 and 63 characters long
 if (subdomain.length < 1 || subdomain.length > 63) {
  return false;
 }
 // Subdomain must start and end with an alphanumeric character
 if (!subdomain.match(/^[a-z0-9]/i) || !subdomain.match(/[a-z0-9]$/i)) {
  return false;
 }
 // Subdomain must only contain alphanumeric characters and hyphens
 for (let i = 0; i < subdomain.length; i++) {
  let char = subdomain.charAt(i);
  if (!char.match(/[a-z0-9-]/i)) {
   return false;
  }
 }
 // Subdomain must not contain two consecutive hyphens
 if (subdomain.includes("--")) {
  return false;
 }
 return true;
}

export const isEmail = (string: string): boolean | "" => {
 if (string === "") return "";
 const regExp = /^[-$.^_=+0-9A-Za-z~]+@[-$%/0-9=?A-Z^_a-z~]+.[.0-9A-Za-z~]+\w$/;
 return regExp.test(string);
};

export const isImgFile = (file: File) => {
 return !!file.type.match(/image.*/);
};

export const isPhone = (string: string): boolean | "" => {
 if (string === "") return "";
 if (typeof string !== "string") return string;
 const result = string.replace(/[\s-]+/g, "");
 const validation =
  result.length >= 10 && result.length < 14 && /^[0-9+]+\w$/g.test(result);
 return validation;
};

export function isObjectLike(value: any) {
 return value != null && typeof value === "object";
}

export function deepFreeze(object: any) {
 if (!isObjectLike(object)) {
  return;
 }

 Object.freeze(object);

 Object.keys(object).forEach(function (key) {
  const value = object[key];
  if (!isObjectLike(value) || Object.isFrozen(value)) {
   return;
  }

  deepFreeze(value);
 });
}

export const isPassword = (string: string): boolean | "" => {
 if (string === "") return "";
 // 특수문자 1개이상 숫자가 포함된 7~15 자리의 영문 숫자 조합
 const regExp =
  /^(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])[a-zA-Z0-9~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]{7,15}$/gi;
 return regExp.test(string);
};
