import classNames from "classnames";
import { useContext } from "react";
import { twMerge } from "tailwind-merge";
import { AppContext } from "../../hook/useApp.context";
import { FcouponFragment } from "../../app/apollo/type/graphql";
import { IconCellUI } from "./IconCellUi";
import { MapPinIcon, PhoneIcon } from "@heroicons/react/24/solid";
import { DoorIcon } from "../icon/DoorIcon";
import { autoComma, autoHypen } from "../../utils/format";

interface IProp {
 coupon: FcouponFragment;
 sizeL?: boolean;
 hideTime?: boolean;
}

export const CouponInfoLineZone: React.FC<IProp> = ({
 sizeL = true,
 hideTime,
 coupon,
}) => {
 const { lang } = useContext(AppContext);

 const roadAddress = coupon.addressDetail?.roadAddress;
 const detail = coupon.addressDetail?.addressDetail;

 const fullKoreanAddress = (roadAddress || "") + " " + (detail || "");

 if (
  !coupon.time &&
  !coupon.contact &&
  !coupon.addressDetail?.globalAddress?.ko
 )
  return null;
 return (
  <ul className="flex flex-col ">
   {(coupon.addressDetail?.globalAddress?.ko || fullKoreanAddress?.trim()) && (
    <IconCellUI
     Icon={MapPinIcon}
     sizeL={sizeL}
     title={lang(coupon.addressDetail?.globalAddress) || fullKoreanAddress}
    />
   )}
   {coupon.contact && (
    <IconCellUI
     sizeL={sizeL}
     Icon={PhoneIcon}
     title={autoHypen(coupon.contact || "")}
    />
   )}
   {!hideTime && coupon.time && (
    <IconCellUI
     sizeL={sizeL}
     Icon={DoorIcon as any}
     title={lang(coupon.time)}
    />
   )}
  </ul>
 );
};
