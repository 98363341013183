import { useState } from "react";
import {
 FfileFragment,
 useFileUploadsMutation,
 FileDocInput,
} from "../app/apollo/type/graphql";

export type TUseSingleUpload = ReturnType<typeof useSingleUpload>;
export const useSingleUpload = (defaultFile?: FfileFragment) => {
 const [fileUploading, setLoading] = useState(false);
 const [file, setFile] = useState<FfileFragment | undefined>(defaultFile);
 const [uploadMu] = useFileUploadsMutation();

 const fileUpload = async (file: File) => {
  setLoading(true);

  let allFiles: File[] = [file];
  let tags: {
   key: string;
   value: string;
  }[];

  const files = allFiles.map((file) => ({
   upload: file,
   tags,
  }));

  const clear = () => {
   setFile(undefined);
  };

  return uploadMu({
   variables: {
    files,
   },
  })
   .then(({ data }) => {
    const file = data?.FileUploads.data?.[0];
    if (file) {
     setFile(file);
    }
    return file;
   })
   .finally(() => {
    setLoading(false);
   });
 };

 const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
  const file = e.currentTarget.files?.[0];
  if (!file) return;
  return await fileUpload(file);
 };

 const onChangeFile = async (file: File) => {
  return await fileUpload(file);
 };

 const createInput: FileDocInput | undefined = file
  ? {
     uri: file.uri,
     description: file.description,
     extension: file.extension,
     fileType: file.fileType,
     name: file.name,
     tags: file.tags,
    }
  : undefined;

 return {
  fileUpload,
  fileUploading,
  setFile,
  onChange,
  onChangeFile,
  ...file,
  file,
  createInput,
 };
};
