import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import {
 CellType,
 EventType,
 FcellFragment,
} from "../../app/apollo/type/graphql";
import { useContext } from "react";
import { AppContext } from "../../hook/useApp.context";

interface IProp
 extends React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
 > {
 cell: FcellFragment;
}

export const CellWrap: React.FC<IProp> = ({ cell, className, ...props }) => {
 const navigate = useNavigate();

 const { sendRecord } = useContext(AppContext);

 return (
  <div
   onClick={() => {
    if (cell.name?.ko)
     sendRecord({
      cellName: cell.name?.ko,
      event: EventType.Click,
     });
    if (cell.link === "self") {
     if (!cell.customLink) {
      return;
     }
     window.open(cell.customLink, "_blank");
     return;
    }
    if (cell.link) navigate(cell.link);
   }}
   className={classNames("fade-up", className)}
   {...props}
  />
 );
};
