import dataURLtoFile from "./dataURLtoFile";
import { fileExtendDivider } from "./fileExtendDivider";
import { IResizeImageOptions } from "./fileResize";
import { resizeCanvasImg } from "./resizeCanvas";


export const resizeImage = (settings: IResizeImageOptions): Promise<File> => {
  const file = settings.file;
  const maxSize = settings.maxSize;
  const reader = new FileReader();
  const image = new Image();
  const canvas = document.createElement('canvas');

  const resize = () => {
    //4
    let width = image.width;
    let height = image.height;

    if (width > height) {
      if (width > maxSize) {
        height *= maxSize / width;
        width = maxSize;
      }
    } else {
      if (height > maxSize) {
        width *= maxSize / height;
        height = maxSize;
      }
    }

    const canvasContext = canvas.getContext('2d');
    canvas.width = width;
    canvas.height = height;
    canvasContext?.drawImage(image, 0, 0, width, height);
    resizeCanvasImg(canvas, width, height, true);

    let dataUrl = canvas.toDataURL(file.type);
    const fileName = settings.changedFileName || file.name;
    const { extend, namePart } = fileExtendDivider(fileName);
    const suffixStr = `---${settings.suffix || maxSize}`;

    const newfileName = namePart + suffixStr + '.' + extend;

    return dataURLtoFile(dataUrl, newfileName);
  };

  return new Promise((ok, no) => {
    if (!file.type.match(/image.*/)) {
      no(false);
      return;
    }

    reader.onload = (readerEvent: any) => {
      image.onload = () => ok(resize()); //3
      image.src = readerEvent.target.result; // 2
    };
    reader.readAsDataURL(file); // 1
  });
};
