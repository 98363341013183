import { useState } from "react";
import { FgroupFragment, FpageFragment } from "../../app/apollo/type/graphql";
import { EditBox } from "../EditBox";
import { TitleOfEditBox } from "../EditBoxTitle";
import { BasicSwitch } from "../basic/Switch";
import { BasicInput } from "../basic/input";
import { SingleImageUploader } from "../basic/SingleImageUploader";
import { VideoUploader } from "../basic/VideoUploader";
import { useAppEditContext } from "../../hook/useAppEdit.context";

export function extractYouTubeVideoCode(input: string): string | null {
 if (!input) return input;
 // First, check if the input is already a valid YouTube video ID
 if (/^[a-zA-Z0-9_-]{11}$/.test(input)) {
  return input;
 }

 // Regular expression for extracting video code from YouTube URLs
 const regExp =
  /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
 const match = input.match(regExp);

 // Return the video code if found, otherwise null
 return match ? match[1] : null;
}

interface IProp {
 g: FgroupFragment;
 set: () => void;
}

export const VideoEditor: React.FC<IProp> = ({ set, g }) => {
 const [youtubeMode, setYoutubeMode] = useState(g.youtubeCode ? true : false);
 return (
  <div className="flex w-full flex-col gap-2">
   <BasicSwitch
    label={"유튜브동영상"}
    checked={youtubeMode}
    onChange={() => {
     const nextIsYoutubeMode = !youtubeMode;
     if (!nextIsYoutubeMode) {
      g.youtubeCode = undefined;
      set();
     }
     setYoutubeMode(nextIsYoutubeMode);
    }}
   />
   {youtubeMode ? (
    <BasicInput
     onBlur={() => {
      if (g.youtubeCode) {
       g.youtubeCode = extractYouTubeVideoCode(g.youtubeCode);
       set();
      }
     }}
     value={g.youtubeCode || ""}
     onChange={(val) => {
      g.youtubeCode = val;
      set();
     }}
     label="유튜브 비디오 링크"
    />
   ) : (
    <VideoUploader
     setFile={(file) => {
      g.video = file;
      set();
     }}
     file={g.video || undefined}
    />
   )}
  </div>
 );
};
