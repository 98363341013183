import { useContext } from "react";
import { useInput } from "../hook/useInput";
import { TUseModal } from "../hook/useModal";
import Modal from "./Modal";
import { NDigitInput } from "./basic/NDigitInput";
import { AppContext } from "../hook/useApp.context";
import { BasicButton } from "./basic/Button.ratio";
import { L } from "../data/lang.static";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

export interface IImageViewModalInfo {
 urls: string[];
}

interface IProp {
 modalhook: TUseModal<IImageViewModalInfo>;
}

export const ImagesViewModal: React.FC<IProp> = ({ modalhook }) => {
 return (
  <Modal
   bodyclasses="bg-transparent w-full"
   outsideClickClose={false}
   modalHook={modalhook}
  >
   <Swiper
    speed={1000}
    pagination={{
     clickable: true,
     renderBullet: (index: number, className: string) => `
             <span class="!w-3 !h-3 mb-2 ${className} !bg-white !border-2 !rounded-full !border-white" data-index="${index}"></span>
           `,
    }}
    slidesPerView={1}
    modules={[Pagination]}
   >
    {modalhook?.info?.urls?.map((url) => {
     return (
      <SwiperSlide
       onClick={() => {
        modalhook.close();
       }}
       key={url + "ImageViewer"}
       className="h-full relative w-full"
      >
       <div className="flex h-[100svh]  max-w-[90vw] mx-auto items-center justify-center">
        <img
         src={url}
         onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
         }}
         className="max-w-full mx-auto max-h-[80svh]"
        />
       </div>
      </SwiperSlide>
     );
    })}
   </Swiper>
  </Modal>
 );
};
