import { useContext } from "react";
import { FieldType, FstoreFragment } from "../app/apollo/type/graphql";
import { EditBoxTitle } from "./EditBox.title";
import { EditImageBox } from "./EditBox.image";
import { PageEditorContext } from "./PageEditor.context";
import { BasicButton } from "./basic/button";
import { EditBox } from "./EditBox";
import { BasicInput } from "./basic/input";
import { BasicSelect } from "./basic/Select";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { whenEnter } from "../utils/whenEnter";
import { BasicTagInput } from "./basic/TagInput";
import { s8 } from "../utils/s4";
import { BasicSwitch } from "./basic/Switch";

interface IProp {}

export const IntroPageEditor: React.FC<IProp> = ({}) => {
 const { set, editingPath, setKr, subject, store } =
  useContext(PageEditorContext);

 if (editingPath !== "/") return null;
 if (!store) return null;

 return (
  <>
   <EditImageBox
    onChange={(file) => {
     store!["mainImage"] = file;
     set();
    }}
    title={"이미지"}
    value={store?.mainImage}
   />
   <EditBoxTitle
    title={"대표문구"}
    onChange={(val) => {
     setKr(subject, "introMain", val);
    }}
    value={store?.introMain?.ko}
   />
   <EditBoxTitle
    textarea
    title={"상세문구"}
    onChange={(val) => {
     setKr(subject, "introduce", val);
    }}
    value={store?.introduce?.ko}
   />

   <EditBox title={<div>입력필드 설정 </div>}>
    <div className="flex w-full flex-col gap-4 ">
     {store.fields?.map((field, index) => {
      return (
       <div className="w-full">
        <div className="flex items-center mb-2 gap-2">
         <BasicSwitch
          onChange={() => {
           field.require = !field.require;
           set();
          }}
          checked={!!field.require}
          className="shrink-0"
          label="필수"
         />
         <BasicSelect
          className="min-w-24 w-12"
          value={field.type}
          onChange={(val) => {
           field.type = val;
           set();
          }}
          options={[
           {
            label: "입력형",
            value: FieldType.Input,
           },
           {
            label: "선택형",
            value: FieldType.Select,
           },
          ]}
         />
         <BasicInput
          placeholder="라벨"
          onChange={(val) => {
           field.label["ko"] = val;
           set();
          }}
          value={field?.label?.ko || ""}
         />
         <XMarkIcon
          onClick={() => {
           store.fields?.splice(index, 1);
           set();
          }}
          className="w-6 h-6 shrink-0 cursor-pointer hover:text-neutral-700"
         />
        </div>
        {field.type === FieldType.Select && (
         <BasicTagInput
          onChange={(ops) => {
           const labels = ops?.map((op) => {
            return {
             ko: op.label,
            };
           });
           field.options = labels;
           set();
          }}
          options={
           field?.options?.map((op) => {
            return {
             key: op?.ko,
             label: op?.ko,
            };
           }) || []
          }
         />
        )}
       </div>
      );
     })}
     <BasicButton
      className="w-full min-w-full flex-grow"
      onClick={() => {
       store.fields = [
        ...(store.fields || []),
        {
         key: s8(),
         label: {
          ko: "",
         },
         type: FieldType.Input,
        },
       ];
       set();
      }}
     >
      필드 추가
     </BasicButton>
    </div>
   </EditBox>
  </>
 );
};
