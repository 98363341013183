import classNames from "classnames";
import { Popover, Transition } from "@headlessui/react";
import { Calendar, CalendarProp } from "./JW.abs.calenader";
import React, { Fragment } from "react";
import { twMerge } from "tailwind-merge";

interface IProp extends React.ComponentProps<typeof Popover> {
 calprop: CalendarProp;
 ButtonEle?: React.ReactNode;
 range?: boolean;
 className?: string;
 calendarClassName?: string;
}

export const DayPickerInput = ({
 ButtonEle,
 calprop,
 range,
 calendarClassName,
 className,
 ...props
}: IProp) => {
 return (
  <Popover {...props} className={className}>
   <div className="relative w-full">
    <Popover.Button
     as={ButtonEle ? Fragment : "button"}
     className={"outline-none"}
    >
     {ButtonEle}
    </Popover.Button>
    <Transition
     as={Fragment}
     enter="transition ease-out duration-200"
     enterFrom="opacity-0 translate-y-1"
     enterTo="opacity-100 translate-y-0"
     leave="transition ease-in duration-150"
     leaveFrom="opacity-100 translate-y-0"
     leaveTo="opacity-0 translate-y-1"
    >
     <Popover.Panel>
      {({ close }) => {
       return (
        <Calendar
         viewMonthCnt={1}
         className={twMerge(
          classNames(
           "shadow-2xl w-full  mb-16  absolute left-0  rounded-xl p-2 bg-white z-10",
           calendarClassName
          )
         )}
         {...calprop}
         onChange={(from, to) => {
          if (from && to) {
           close();
          }
          calprop.onChange(from, to);
         }}
        />
       );
      }}
     </Popover.Panel>
    </Transition>
   </div>
  </Popover>
 );
};
