import React from "react";
import DaumPostcode, { DaumPostcodeProps } from "react-daum-postcode";
import Modal from "../Modal";
import { TUseModal } from "../../hook/useModal";

interface IProp extends DaumPostcodeProps {
 modalProps?: React.ComponentProps<typeof Modal>;
 modalHook: TUseModal;
}

export const AddressModal: React.FC<IProp> = ({
 modalProps,
 modalHook,
 ...props
}) => {
 return (
  <Modal modalHook={modalHook} {...modalProps}>
   <DaumPostcode className="!h-[80vh]" focusInput animation {...props} />
  </Modal>
 );
};
