import {
 XCircleIcon,
 CalendarIcon,
 XMarkIcon,
 PlusIcon,
} from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import { CellType, FgroupFragment, LineType } from "../app/apollo/type/graphql";
import { HeroIconkeys, Icons } from "../data/icon.const";
import { s4, s6 } from "../utils/s4";
import { DayPickerInput } from "./DayPicker";
import { EditBox } from "./EditBox";
import { TitleOfEditBox } from "./EditBoxTitle";
import { IconTypePairOps } from "./InfoLineEditor";
import { Photo } from "./Photo";
import { withDefaultOp } from "./Select";
import { WithEmpty } from "./WithEmpty";
import { AddressInput } from "./addressInput/AddressInput";
import { BasicSelect } from "./basic/Select";
import { SingleImageUploaderBox } from "./basic/SingleImageUploader.box";
import { BasicButton } from "./basic/button";
import { BasicInput } from "./basic/input";
import { VideoEditor } from "./editors/Video";
import { TextArea } from "./textarea";
import { useContext } from "react";
import { PageEditorContext } from "./PageEditor.context";
import { AppContext } from "../hook/useApp.context";

interface IProp {
 g: FgroupFragment;
}

export const GroupEditorTypeRedner: React.FC<IProp> = ({ g }) => {
 const { cellEditModalHook } = useContext(AppContext);
 const { setKr, set } = useContext(PageEditorContext);
 if (g.type === CellType.Video) {
  return <VideoEditor set={set} g={g} />;
 }
 if (g.type === CellType.InfoPageList) {
  const cell = g.cells[0];
  return (
   <div className="w-full">
    {/* <BasicInput
     label="항목명"
     value={cell.name?.ko}
     onChange={(val) => {
      setKr(cell, "name", val);
     }}
    /> */}
    <WithEmpty
     empty={cell?.iconLines}
     Empty={
      <div className="text-sm text-center my-2 text-neutral-400 py-2 border border-dotted rounded-xl">
       추가된정보가 없습니다.
      </div>
     }
    >
     <div className="flex flex-col gap-4">
      {cell?.iconLines?.map((il, index) => {
       return (
        <div key={il.key} className="flex flex-col   w-full gap-1 items-center">
         <div className="text-sm w-full    flex justify-between items-center">
          {/* <div className="w-full text-left mt-3">
                 {"정보" + (index + 1)}
                </div> */}
          {index + 1} 항목
          <XCircleIcon
           className="cursor-pointer w-4 h-4  "
           onClick={() => {
            cell.iconLines?.splice(index, 1);
            set();
           }}
          />
         </div>

         <div className="flex gap-2 w-full">
          <BasicSelect
           optionWrapClasses="overflow-auto max-h-80 "
           label="아이콘"
           className="grow max-w-24"
           onChange={(type) => {
            il.icon = type;
            set();
           }}
           value={il.icon}
           options={withDefaultOp(
            HeroIconkeys?.map((k) => {
             const Icon = Icons[k as keyof typeof Icons];
             return {
              label: (
               <span>
                <Icon className="w-5 h-5" />
               </span>
              ),
              value: k,
             };
            }) || [],
            "없음"
           )}
          />
          {/* <BasicSelect
          label="리스트타입"
          className="grow"
          onChange={(type) => {
           il.type = type;
           set();
          }}
          value={il.type}
          options={IconTypePairOps}
         />{" "} */}
          <BasicInput
           label="리스트 인풋"
           className="grow"
           value={il.title?.ko}
           onChange={(val) => {
            setKr(il, "title", val);
           }}
          />{" "}
         </div>
        </div>
       );
      })}
     </div>
    </WithEmpty>
    <BasicButton
     className="mt-4"
     onClick={() => {
      cell.iconLines = [
       ...(cell.iconLines || []),
       {
        key: s4(),
        title: {
         ko: "",
        },
        type: LineType.Custom,
       },
      ];
      set();
     }}
    >
     정보추가
    </BasicButton>
   </div>
  );
 }
 if (g.type === CellType.Map) {
  return (
   <div className="flex w-full flex-col gap-2">
    {/* <BasicInput
     label="항목명"
     value={g.name?.ko}
     onChange={(val) => {
      setKr(g, "name", val);
     }}
    /> */}
    <AddressInput
     address={g.addressDetail?.roadAddress || ""}
     data={g.addressDetail as any}
     setAddress={(data) => {
      g.addressDetail = data;
      set();
     }}
    />
   </div>
  );
 }
 if (g.type === CellType.PageInfo || g.type === CellType.Coupon) {
  const pageCell = g.cells[0];

  return (
   <div className="w-full flex flex-col gap-1">
    {g.type === CellType.Coupon && (
     <BasicInput
      placeholder="내부라벨"
      value={pageCell?.name?.ko}
      onChange={(val) => {
       setKr(pageCell, "name", val);
      }}
     />
    )}
    <TextArea
     value={pageCell?.desc?.ko}
     onChange={(val) => {
      setKr(pageCell, "desc", val);
     }}
    />
    {g.type === CellType.Coupon && (
     <DayPickerInput
      className="w-full"
      ButtonEle={
       <div className=" flex gap-3 w-full items-center">
        <BasicInput
         className="w-full"
         Icon={<CalendarIcon />}
         value={
          g?.limitDate ? dayjs(g?.limitDate).format("YYYY.MM.DD") : undefined
         }
         placeholder={"만료일"}
        />
       </div>
      }
      calprop={{
       from: g.limitDate,
       to: g.limitDate,
       range: false,
       onChange(from, to) {
        g.limitDate = from;
        set();
       },
      }}
     />
    )}
   </div>
  );
 }

 const isimagesList = [CellType.Gallery, CellType.ImgList]?.includes(g.type);
 return (
  <div className="flex gap-1.5 flex-wrap">
   {g.images?.map((img, index) => {
    return (
     <SingleImageUploaderBox
      className="border"
      setFile={(file) => {
       if (!file) g.images?.splice(index, 1);
       else {
        (g.images as any)[index] = file;
       }
       set();
      }}
      file={img}
     />
    );
   })}
   {g.cells?.map((cell, index) => {
    if ([CellType.Square, CellType.Verti, CellType.Info]?.includes(cell.type))
     return (
      <div className="relative parent-hover">
       <Photo
        onClick={() => {
         cellEditModalHook?.openImmediate({
          cell,
         });
        }}
        defaultSrc="/imgholder2.png"
        className="max-w-[80px] max-h-[80px] w-20 border h-20 cursor-pointer hover:brightness-90 object-cover object-center rounded-lg grow shrink"
        key={cell.key + "Cell"}
        file={cell.image}
       />
       <div
        onClick={(e) => {
         e.preventDefault();
         e.stopPropagation();
         g.cells?.splice(index, 1);
         set();
        }}
        style={{
         borderBottomLeftRadius: 4,
        }}
        className="cursor-pointer child-hover hover:bg-white w-4 h-4 absolute top-0 right-0 flex items-center justify-center bg-white bg-opacity-80"
       >
        <XMarkIcon className="text-red-500 w-3 h-3" strokeWidth={1.5} />
       </div>
      </div>
     );
   })}

   {isimagesList ? (
    <SingleImageUploaderBox
     setFile={(file) => {
      g.images = [file!, ...(g.images || [])];
      set();
     }}
    />
   ) : (
    <div
     onClick={() => {
      g.cells?.push({
       key: s6(),
       link: "",
       type: g.type,
      });
      console.log("@@g.cells?.length", g.cells);
      set();
     }}
     className="flex items-center justify-center bg-gray-50 hover:bg-gray-100 min-w-20 min-h-20 max-w-[80px] max-h-[80px] w-20 h-20 cursor-pointer  object-cover object-center rounded-lg grow shrink"
    >
     <PlusIcon className="w-4 h-4" strokeWidth={2} />
    </div>
   )}
  </div>
 );
};
export default GroupEditorTypeRedner;
