export const isEmpty = (
 data: Date | any | undefined | null | object | string | boolean
): data is null | undefined | "" | void => {
 if (typeof data === "string") {
  if (data === "") return true;
  return false;
 } else {
  for (const key in data) {
   if (Object.prototype.hasOwnProperty.call(data, key)) return false;
  }
 }

 if (typeof data === "object") {
  if (Object.prototype.toString.call(data) === "[object Date]") return false;
 }
 return true;
};
