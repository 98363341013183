import { createBrowserRouter, Outlet } from "react-router-dom";
import { createRoutesFromElements, Route } from "react-router-dom";
// import { Visit } from "./Visit";
// import { User } from "./User";
import { Login } from "./Login";
import Tostify from "../components/Toastfy";
// import { ItemList } from "./ItemList";
import Page404 from "./404";
import { ProtectedRoute } from "./components/ProtectedRouter";
import DashboardLayout from "./DashboardLayout";
import { Intro } from "./Intro";
import { lazy, Suspense } from "react";
import CustomerLayout from "./ConsumerLayout";
import Main from "./Main";
import Page from "./Page";
import { StoreApp } from "./StoreApp";
import Statistic from "./statistic/Statistic";
import BookerList from "./BookerList";

const Map = lazy(() => import("./Map"));
const StoreEditor = lazy(() => import("../components/StoreEditor"));
const ChatRoomList = lazy(() => import("./ChatRoomList"));
const ChatRoom = lazy(() => import("./ChatRoom"));

export const Router = createBrowserRouter(
 createRoutesFromElements(
  <Route path="/" element={<StoreApp />}>
   <Route element={<CustomerLayout />}>
    <Route path="/" element={<Intro />} />
    <Route path="/main" element={<Main />} />
    <Route path="/chat" element={<ChatRoom />} />
    <Route path="/coupon" element={<Map />} />
   </Route>
   <Route path="auth" element={<Login />} />
   <Route
    path="admin"
    element={
     <ProtectedRoute>
      <DashboardLayout />
     </ProtectedRoute>
    }
   >
    <Route path="admin" element={<ChatRoomList />} />
    {/* <Route path="visit" element={<Visit />} />
    <Route path="user" element={<User />} /> */}
    {/* <Route path="item-detail/:id?" element={<ItemDetail />} />
    <Route path="item-list" element={<ItemList />} /> */}
    <Route
     path="/admin"
     element={
      <Suspense>
       <ChatRoomList />
      </Suspense>
     }
    />

    <Route
     path="/admin/user"
     element={
      <Suspense>
       <BookerList />
      </Suspense>
     }
    />

    <Route
     path="/admin/statistic"
     element={
      <Suspense>
       <Statistic />
      </Suspense>
     }
    />
    <Route
     path="/admin/chats"
     element={
      <Suspense>
       <ChatRoomList />
      </Suspense>
     }
    />
    <Route
     path="/admin/chat-in/:id?"
     element={
      <Suspense>
       <ChatRoom adminmode />
      </Suspense>
     }
    />
    <Route
     path="/admin/edit"
     element={
      <Suspense>
       <StoreEditor />
      </Suspense>
     }
    />
   </Route>
   <Route element={<CustomerLayout />}>
    <Route path={"/*"} element={<Page />} />
   </Route>

   <Route path="*" element={<Page404 />} />
  </Route>
 )
);
