import { Dispatch, SetStateAction, useEffect, useState } from "react";
// @ts-ignore
import cloneDeep from "lodash.clonedeep";

export const useCopy = <T>(
 defaultData: T | (() => T)
): [T, Dispatch<SetStateAction<T>>] => {
 const [data, setData] = useState<T>(
  typeof defaultData === "function" ? defaultData : cloneDeep(defaultData)
 );

 // useEffect(()=>{
 //     if(defaultData) {

 //         setData(cloneDeep(defaultData));
 //     }
 // },[defaultData])

 return [data, setData];
};
