export const s4 = () => {
 return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
};

export const s6 = () => {
 return (((1 + Math.random()) * 0x1000000) | 0).toString(16).substring(1);
};

export const s8 = () => {
 return (((1 + Math.random()) * 0x100000000) | 0).toString(16).substring(1);
};
