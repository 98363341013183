import classNames from "classnames";
import { useContext } from "react";
import { twMerge } from "tailwind-merge";
import { AppContext } from "../../hook/useApp.context";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";

interface IProp {
 sizeL?: boolean;
 title: string;
 Icon: typeof PaperAirplaneIcon;
}

export const IconCellUI: React.FC<IProp> = ({ sizeL, title, Icon }) => {
 const { lang } = useContext(AppContext);
 return (
  <li
   className={twMerge(
    classNames(
     "flex items-center py-[1vw] xs:py-[5.12px] gap-x-[2.78vw] xs:gap-x-[12.8px]",
     {
      "gap-[3.34vw] xs:gap-[15.36px] py-[2.78vw] xs:py-[12.8px]": sizeL,
     }
    )
   )}
  >
   {Icon && (
    <Icon
     className={twMerge(
      classNames("shrink-0 text-primary-500 w-[3.89vw] xs:w-[17.92px] h-auto", {
       "w-[5.56vw] xs:w-[25.6px] !h-auto": sizeL,
      })
     )}
    />
   )}
   <span
    className={twMerge(
     classNames("text-[3.33vw] break-keep xs:text-[14.4px]", {
      "text-[3.89vw] xs:text-[17.92px]": sizeL,
     })
    )}
   >
    {title}
   </span>
  </li>
 );
};
