import { useEffect, useState } from "react";
import { getUserLocation } from "../utils/getUserLocation";
import { iamTopWindow } from "../utils/localstorage/StorageAddition";

export const useUserLocation = () => {
 const [clinetLocation, setClientLocation] = useState<{
  lat: number;
  lng: number;
 }>();

 useEffect(() => {
  if (!iamTopWindow) return;
  getUserLocation().then((result) => {
   setClientLocation({
    lat: result?.latitude || 0,
    lng: result?.longitude || 0,
   });
   //    console.log("@@ref.current", ref.current);
  });
 }, []);

 return {
  clinetLocation,
  setClientLocation,
 };
};
