/* eslint-disable react-hooks/rules-of-hooks */
import {
 ApolloClient,
 ApolloError,
 DocumentNode,
 NetworkStatus,
 OperationVariables,
 QueryHookOptions,
 TypedDocumentNode,
} from "@apollo/client";
import { useEffect, useId, useMemo, useRef } from "react";
import { useLazyQuery } from "@apollo/client";
import { apolloClient } from ".";
import useUpdateEffect from "../hook/useUpdateEffect";
import { FoffsetPagingInfoFragment } from "../app/apollo/type/graphql";
import { DEFAULT_PAGE_INFO } from "../data/const";
import { ListInitOptions, useListQuery } from "../hook/userListQuery";

export const generateAsyncQuery = <T, V extends OperationVariables, R>(
 query: DocumentNode | TypedDocumentNode<T, V>,
 params?: Partial<Parameters<typeof apolloClient.query>[0]>
): ((variables?: V) => Promise<R>) => {
 const operationName = getQueryName(query);

 const asyncQuery = async (variables?: V) => {
  return apolloClient
   .query<T, V>({
    query,
    variables: variables as any,
    ...params,
   })
   .then((res: any) => {
    return res.data?.[operationName];
   });
 };
 return asyncQuery;
};

export const getQueryName = (QUERY: DocumentNode) => {
 const operation = QUERY.definitions[0];

 // @ts-ignore
 const operationName = operation && operation.name.value;

 return capitalize(operationName);
};

export const capitalize = (s: string) => {
 if (typeof s !== "string") return "";
 return s.charAt(0).toUpperCase() + s.slice(1);
};

export interface genrateOption<
 Q,
 V extends OperationVariables = OperationVariables
> extends QueryHookOptions<Q, V> {
 queryName?: string;
 skipInit?: boolean;
 overrideVariables?: Partial<V>;
 skipLoadingEffect?: boolean;
 disable?: boolean;
}

const dataCheck = (
 data: any,
 operationName: string,
 checkProperty?: string[]
) => {
 try {
  if (data?.hasOwnProperty(operationName) === false) {
   throw Error(
    `result data object dose not have property ${operationName} look this above object ↑ `
   );
  }

  checkProperty &&
   checkProperty.forEach((p) => {
    if (data?.[operationName].hasOwnProperty(p) === false) {
     console.error(p);
     throw Error(
      `result data object dose not have property ${p} look this above object ↑ `
     );
    }
   });
 } catch (e) {
  console.error("==========FATAL ERROR==========");
  console.error(e);
 }
};

export type TListQueryHook<
 F = any,
 S = any,
 Q = any,
 V extends OperationVariables = any
> = (
 {
  initialPageIndex,
  initialSort,
  initialFilter,
  initialViewCount,
  fixingFilter,
  withFilter,
  unlimited,
 }?: Partial<ListInitOptions<F, S>>,
 options?: genrateOption<Q, V>
) => {
 client: ApolloClient<any>;
 previousData?: Q | undefined;
 error?: ApolloError | undefined;
 networkStatus: NetworkStatus;
 called: true;
 variables: V | undefined;
 [key: string]: any;
};

const queryInMemoryGetAt: Record<string, number> = {};

export const generateListQueryHook = <F, S, Q, V extends OperationVariables, R>(
 QUERY: DocumentNode,
 queryInit: Partial<ListInitOptions<F, S>> = {},
 defaultOptions?: genrateOption<Q, V>
) => {
 const listQueryHook = (
  {
   initialPageIndex = queryInit.initialPageIndex || 0,
   initialSort = queryInit.initialSort || [],
   initialFilter = queryInit.initialFilter || ({} as F),
   initialViewCount = queryInit.initialViewCount || 20,
   fixingFilter,
   withFilter,
   unlimited = false,
  }: Partial<ListInitOptions<F, S>> = { ...queryInit },
  options: genrateOption<Q, V> = { ...defaultOptions }
 ) => {
  const initCheckRef = useRef<boolean>();
  let _option = { ...defaultOptions, ...options };
  const { variables, overrideVariables, ...ops } = _option;
  const { integratedVariable, ...params } = useListQuery({
   initialFilter,
   initialPageIndex,
   initialSort,
   initialViewCount,
   withFilter,
   unlimited: unlimited,
   fixingFilter,
  });

  const [getData, { data, loading: getLoading, ...queryElse }] = useLazyQuery<
   Q,
   V
  >(QUERY, {
   fetchPolicy: "cache-and-network",
   // @ts-ignore
   variables: {
    ...integratedVariable,
    ...variables,
    ...overrideVariables,
   },
   ...ops,
  });

  const operationName = defaultOptions?.queryName || getQueryName(QUERY);

  dataCheck(data, operationName, ["items", "pageInfo"]);
  // @ts-ignore
  const items: R[] = data?.[operationName]?.items || [];
  const pageInfo: FoffsetPagingInfoFragment =
   (data as any)?.[operationName]?.pageInfo || DEFAULT_PAGE_INFO;

  useEffect(() => {
   const isInitied = initCheckRef.current;
   if (!isInitied) initCheckRef.current = true;
   if (!isInitied && _option?.skipInit) return;
   if (_option?.skip) return;
   if (getLoading) return;
   getData();
  }, [
   params.filter,
   params.sort,
   params.paginatorHook.pageItemCount,
   params.paginatorHook.page,
  ]);

  useUpdateEffect(() => {
   params.paginatorHook.setPage(initialPageIndex);
  }, [params.paginatorHook.pageItemCount]);

  const filterStr = useMemo(
   () => JSON.stringify(params.filter || {}),
   [params.filter]
  );
  const uniqId = useId();
  const getAt = ((data as any)?.["getAt"] || queryInMemoryGetAt[uniqId]) as
   | number
   | undefined;
  if (getAt) queryInMemoryGetAt[uniqId] = getAt;

  useUpdateEffect(() => {
   params.paginatorHook.setPage(0);
  }, [filterStr]);

  params.paginatorHook["pageCount"] = pageInfo.totalPageCount || 1;
  return {
   getData,
   pageInfo,
   getLoading,
   items,
   getAt,
   pageCount: pageInfo.totalPageCount || 0,
   ...params,
   ...queryElse,
  };
 };

 return listQueryHook;
};
