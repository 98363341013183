import { useContext } from "react";
import { AppContext } from "../hook/useApp.context";
import { L, LangOps } from "../data/lang.static";
import { useLocation } from "react-router-dom";

import { Photo } from "../components/Photo";
import { BasicButton } from "../components/basic/Button.ratio";
import { useNavigate } from "react-router-dom";
import { NativeSelecter } from "../components/basic/NativeSelecter";
import { Group } from "../components/cell/Group";
import { DevJsonViewer } from "../components/devTools/DevJson";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { iamTopWindow } from "../utils/localstorage/StorageAddition";
import { PageUI } from "./Page.ui";

export const Page = () => {
 const navigate = useNavigate();
 const { page, store, lang, langCode, updateComponent, resetLang } =
  useContext(AppContext);

 return <PageUI page={page!} />;
};

export default Page;
