import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";

import { Fragment, useContext, useEffect, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
 ArrowsPointingInIcon,
 Bars3Icon,
 BellAlertIcon,
 BellIcon,
 BellSlashIcon,
 ClipboardDocumentListIcon,
 Cog6ToothIcon,
 PlusIcon,
 TicketIcon,
 UsersIcon,
 XMarkIcon,
} from "@heroicons/react/24/outline";
import {
 ChevronDownIcon,
 MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import classNames from "classnames";
import { eraseCookie, getCookie } from "../utils/cookie";
import {
 FchatFragment,
 FpageFragment,
 useChatRoomSubscriptionSubscription,
} from "../app/apollo/type/graphql";
import { WSclient } from "../apollo/links";
import {
 WindowNotification,
 checkNotificationSetted,
} from "../hook/windowNotification";
import {
 AppEditContext,
 useAppEditContextProvider,
} from "../hook/useAppEdit.context";
import { whenEnter } from "../utils/whenEnter";
import { AppContext } from "../hook/useApp.context";
import { PageCell } from "../components/PageCell";
import { BasicButton } from "../components/basic/button";
import { s6 } from "../utils/s4";
import { twMerge } from "tailwind-merge";
import {
 SuperAdminContext,
 useSuperAdminContextProvider,
} from "../hook/super.admin.context";
import { iamTopWindow } from "../utils/localstorage/StorageAddition";

{
 /* <div className="flex gap-8">
<nav className="p-8 bg-slate-50">
 <ul className="py-8 flex flex-col  gap-4">
  <li className="p-4 rounded cursor-pointer">
   <Link to={"/item"}>장소</Link>
  </li>
  <li className="p-4 rounded">
   <Link to={"/user"}>유저</Link>
  </li>
  <li className="p-4 rounded">
   <Link to={"/visit"}>유저</Link>
  </li>
 </ul>
</nav>
<section>
 <Outlet />
</section>
</div> */
}

const navigation = [
 { name: "유저", href: "users", icon: UsersIcon },
 { name: "쿠폰", href: "coupons", icon: TicketIcon },
 { name: "사용기록", href: "couponHistory", icon: ClipboardDocumentListIcon },
 //  { name: "방문", href: "visit", icon: PhotoIcon },
];
const teams = [
 { id: 1, name: "Heroicons", href: "#", initial: "H", current: false },
];
const userNavigation = [
 { name: "프로필", href: "#" },
 {
  name: "로그아웃",
  href: "#",
  onClick: () => {
   eraseCookie("qid");
   window.location.href = "/auth";
  },
 },
];

export default function SuperDashboardLayout() {
 const { superAdmin } = useContext(SuperAdminContext);
 const [sidebarOpen, setSidebarOpen] = useState(false);
 const [notiOn, setNotiOn] = useState(false);
 const location = useLocation();
 const navigate = useNavigate();

 return (
  <div className="bg-neutral-200/20">
   <Transition.Root show={sidebarOpen} as={Fragment}>
    <Dialog
     as="div"
     className="relative z-50 lg:hidden"
     onClose={setSidebarOpen}
    >
     <Transition.Child
      as={Fragment}
      enter="transition-opacity ease-linear duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity ease-linear duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
     >
      <div className="fixed inset-0 bg-gray-900/80" />
     </Transition.Child>

     <div className="fixed inset-0 flex">
      <Transition.Child
       as={Fragment}
       enter="transition ease-in-out duration-300 transform"
       enterFrom="-translate-x-full"
       enterTo="translate-x-0"
       leave="transition ease-in-out duration-300 transform"
       leaveFrom="translate-x-0"
       leaveTo="-translate-x-full"
      >
       <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
        <Transition.Child
         as={Fragment}
         enter="ease-in-out duration-300"
         enterFrom="opacity-0"
         enterTo="opacity-100"
         leave="ease-in-out duration-300"
         leaveFrom="opacity-100"
         leaveTo="opacity-0"
        >
         <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
          <button
           type="button"
           className="-m-2.5 p-2.5"
           onClick={() => setSidebarOpen(false)}
          >
           <span className="sr-only">Close sidebar</span>
           <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
          </button>
         </div>
        </Transition.Child>

        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-primary-50 px-5 pb-4">
         <div className="flex h-16 shrink-0 items-center">
          <img className="h-8 w-auto" src="/logo.svg" alt="Your Company" />
         </div>
         <nav className="flex flex-1 flex-col">
          <section className="max-h-[50svh] overflow-auto min-w-72 max-w-72 bg-white p-4  shadow">
           <div className="flex my-2 flex-col gap-y-2 ">
            <PageCell name={"인트로"} path="/" />
            <PageCell name={"채팅"} path="/chat" />
           </div>
          </section>

          <ul role="list" className="flex flex-1 flex-col gap-y-7">
           <li>
            <ul role="list" className="-mx-2 space-y-2">
             {navigation.map((item) => {
              const current = location.pathname.includes(item.href);

              console.log("location.pathname", {
               p: location.pathname,
               h: item.href,
              });

              return (
               <li key={item.name}>
                <a
                 href={item.href}
                 className={classNames(
                  current
                   ? "bg-primary-700 text-white"
                   : "text-primary-200 hover:text-white hover:bg-primary-700",
                  "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                 )}
                >
                 <item.icon
                  className={classNames(
                   current
                    ? "text-white"
                    : "text-primary-200 group-hover:text-white",
                   "h-6 w-6 shrink-0"
                  )}
                  aria-hidden="true"
                 />
                 {item.name}
                </a>
               </li>
              );
             })}
            </ul>
           </li>
           <li>
            <div className="text-xs font-semibold leading-6 text-primary-200">
             Your teams
            </div>
            <ul role="list" className="-mx-2 mt-2 space-y-1">
             {teams.map((team) => {
              const current = location.pathname.includes(team.href);

              return (
               <li key={team.name}>
                <a
                 href={team.href}
                 className={classNames(
                  current
                   ? "bg-primary-700 text-white"
                   : "text-primary-200 hover:text-white hover:bg-primary-700",
                  "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                 )}
                >
                 <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-primary-400 bg-blue-700 text-[0.625rem] font-medium text-white">
                  {team.initial}
                 </span>
                 <span className="truncate">{team.name}</span>
                </a>
               </li>
              );
             })}
            </ul>
           </li>
           <li className="mt-auto">
            <a
             href="#"
             className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-primary-200 hover:bg-primary-700 hover:text-white"
            >
             <Cog6ToothIcon
              className="h-6 w-6 shrink-0 text-primary-200 group-hover:text-white"
              aria-hidden="true"
             />
             Settings
            </a>
           </li>
          </ul>
         </nav>
        </div>
       </Dialog.Panel>
      </Transition.Child>
     </div>
    </Dialog>
   </Transition.Root>

   {/* Static sidebar for desktop */}
   <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-56 lg:flex-col">
    {/* Sidebar component, swap this element with another sidebar if you like */}
    <div className="border flex grow flex-col gap-y-5 overflow-y-auto bg-white px-5 pb-4">
     <div className="flex h-20 shrink-0 items-center">
      <img
       className="h-12 my-4 w-auto mx-auto"
       src="/logo.svg"
       alt="Your Company"
      />
     </div>
     <nav className="flex flex-1 flex-col">
      <ul role="list" className="grow-0 flex flex-1 flex-col gap-y-7">
       <li>
        <ul role="list" className="-mx-2 ">
         {navigation.map((item) => {
          const current = location.pathname.includes(item.href);
          return (
           <li key={item.name} className={""}>
            <Link
             to={item.href}
             className={classNames(
              current
               ? "text-primary-400 "
               : "text-stone-900 hover:text-white hover:bg-primary-500",
              "group flex gap-x-3 py-5 justify-center rounded-md p-2 text-lg  leading-6 font-semibold"
             )}
            >
             <item.icon
              strokeWidth={2}
              className={twMerge(
               classNames(
                current
                 ? "text-stone-900"
                 : "text-stone-900 group-hover:text-white",
                "h-6 w-6 shrink-0",
                {
                 "text-primary-400 ": current,
                }
               )
              )}
              aria-hidden="true"
             />
             {item.name}
            </Link>
           </li>
          );
         })}
        </ul>
       </li>
       {/* <li>
         <div className="text-xs font-semibold leading-6 text-primary-200">
          Your teams
         </div>
         <ul role="list" className="-mx-2 mt-2 space-y-1">
          {teams.map((team) => (
           <li key={team.name}>
            <a
             href={team.href}
             className={classNames(
              team.current
               ? "bg-primary-700 text-white"
               : "text-primary-200 hover:text-white hover:bg-primary-700",
              "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
             )}
            >
             <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-primary-400 bg-blue-700 text-[0.625rem] font-medium text-white">
              {team.initial}
             </span>
             <span className="truncate">{team.name}</span>
            </a>
           </li>
          ))}
         </ul>
        </li>
        <li className="mt-auto">
         <a
          href="#"
          className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-primary-200 hover:bg-primary-700 hover:text-white"
         >
          <Cog6ToothIcon
           className="h-6 w-6 shrink-0 text-primary-200 group-hover:text-white"
           aria-hidden="true"
          />
          Settings
         </a>
        </li> */}
      </ul>
     </nav>
    </div>
   </div>

   <div className="lg:pl-56">
    <main className="">
     <Outlet />
    </main>
   </div>
  </div>
 );
}
