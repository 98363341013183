import dayjs from "dayjs";
import { EventType, FrecordFragment } from "../../app/apollo/type/graphql";

// Helper functions with type annotations
export const getTopPageViews = (
 filteredRecords: FrecordFragment[]
): { [key: string]: number } => {
 const pageViewCount: { [key: string]: number } = {};

 filteredRecords.forEach((record) => {
  if (record.event === EventType.View) {
   pageViewCount[record.cellName || ""] =
    (pageViewCount[record.cellName || ""] || 0) + 1;
  }
 });

 return pageViewCount;
};

export const getTopClicks = (
 filteredRecords: FrecordFragment[]
): { [key: string]: number } => {
 const clickCount: { [key: string]: number } = {};

 filteredRecords.forEach((record) => {
  if (record.event === EventType.Click) {
   clickCount[record.cellName || ""] =
    (clickCount[record.cellName || ""] || 0) + 1;
  }
 });

 return clickCount;
};

export const getHourlyViewsAndClicks = (filteredRecords: FrecordFragment[]) => {
 const hourlyData: { [key: string]: { views: number; clicks: number } } = {};

 filteredRecords.forEach((record) => {
  const hour = dayjs.tz(record.createdAt).format("HH시");
  if (!hourlyData[hour]) {
   hourlyData[hour] = { views: 0, clicks: 0 };
  }
  if (record.event === EventType.View) {
   hourlyData[hour].views += 1;
  } else if (record.event === EventType.Click) {
   hourlyData[hour].clicks += 1;
  }
 });

 return hourlyData;
};
