import { Category, CellType } from "../app/apollo/type/graphql";
import { enumToArray } from "../utils/enum";
import { enumToOps } from "../utils/enumToOp";

export const isSuperAdminMode =
 //  process.env.NODE_ENV === "development" ||
 window.location.host === "admin.hoteltalk.me";
export const DEFAULT_PAGE_INFO = {
 currentPage: 0,
 rowCount: 0,
 totalPage: 0,
};

export const CategoryKr: Record<Category, string> = {
 Attractions: "카페",
 Cafe: "맛집",
 Convention: "엑티비티",
};

export const CategoryOps = enumToOps(Category, CategoryKr);

export const LangMapKr: { [key: string]: string } = {
 ko: "한국어", // Korean
 en: "영어", // English
 zh: "중국어", // Simplified Chinese
 zhTW: "대만어", // Traditional Chinese (Taiwan)
 zhHK: "번체 중국어", // Traditional Chinese (Hong Kong)
 th: "태국어", // Thai
 vi: "베트남어", // Vietnamese
 ms: "말레이시아어", // Malay
 id: "인도네시아어", // Indonesian
 ja: "일본어", // Japanese
 hi: "힌디어", // Hindi
 fr: "프랑스어", // French
 de: "독일어", // German
 it: "이탈리아어", // Italian
 es: "스페인어", // Spanish
};
export const CellTypeKr: Record<CellType, string> = {
 Gallery: "이미지\n슬라이스형",
 ImgList: "이미지\n썸네일형",
 Info: "버튼\n아이콘형",
 Coupon: "쿠폰형",
 InfoPageList: "아이콘",
 Map: "지도",
 Normal: "",
 PageInfo: "텍스트형",
 Square: "버튼\n썸네일형",
 Verti: "버튼\n가로형",
 Video: "비디오형",
};

export const CategoryColor: Record<Category, string> = {
 Attractions: "teal",
 Cafe: "red",
 Convention: "amber",
};

export const CategoryImgMap: Record<Category, string> = {
 Attractions: "https://jw.stayjanda.cloud/keyband/restorant.svg",
 Cafe: "https://jw.stayjanda.cloud/keyband/cafe_Icon.svg",
 Convention: "https://jw.stayjanda.cloud/keyband/convention_Icon.svg",
};
export const Airplan = "https://jw.stayjanda.cloud/keyband/airplane_icon.svg";

export const SiDoList = [
 {
  name: "서울",
  districts: [
   "종로",
   "중구",
   "용산",
   "성동",
   "광진",
   "동대문",
   "중랑",
   "성북",
   "강북",
   "도봉",
   "노원",
   "은평",
   "서대문",
   "마포",
   "양천",
   "강서",
   "구로",
   "금천",
   "영등포",
   "동작",
   "관악",
   "서초",
   "강남",
   "송파",
   "강동",
  ],
 },
 {
  name: "부산",
  districts: [
   "중구",
   "서구",
   "동구",
   "영도",
   "부산진",
   "동래",
   "남구",
   "북구",
   "해운대",
   "사하",
   "금정",
   "강서",
   "연제",
   "수영",
   "사상",
   "기장",
  ],
 },
 {
  name: "대구",
  districts: ["중구", "동구", "서구", "남구", "북구", "수성", "달서", "달성"],
 },
 {
  name: "인천",
  districts: [
   "중구",
   "동구",
   "미추홀",
   "연수",
   "남동",
   "부평",
   "계양",
   "서구",
   "강화",
   "옹진",
  ],
 },
 {
  name: "광주",
  districts: ["동구", "서구", "남구", "북구", "광산"],
 },
 {
  name: "대전",
  districts: ["동구", "중구", "서구", "유성", "대덕"],
 },
 {
  name: "울산",
  districts: ["중구", "남구", "동구", "북구", "울주"],
 },
 {
  name: "세종",
  districts: ["전체"],
 },
 {
  name: "경기",
  districts: [
   "수원",
   "성남",
   "의정부",
   "안양",
   "부천",
   "광명",
   "평택",
   "동두천",
   "안산",
   "고양",
   "과천",
   "구리",
   "남양주",
   "오산",
   "시흥",
   "군포",
   "의왕",
   "하남",
   "용인",
   "파주",
   "이천",
   "안성",
   "김포",
   "화성",
   "광주",
   "양주",
   "포천",
   "여주",
   "연천",
   "가평",
   "양평",
  ],
 },
 {
  name: "강원",
  districts: [
   "춘천",
   "원주",
   "강릉",
   "동해",
   "태백",
   "속초",
   "삼척",
   "홍천",
   "횡성",
   "영월",
   "평창",
   "정선",
   "철원",
   "화천",
   "양구",
   "인제",
   "고성",
   "양양",
  ],
 },
 {
  name: "충북",
  districts: [
   "청주",
   "충주",
   "제천",
   "보은",
   "옥천",
   "영동",
   "증평",
   "진천",
   "괴산",
   "음성",
   "단양",
  ],
 },
 {
  name: "충남",
  districts: [
   "천안",
   "공주",
   "보령",
   "아산",
   "서산",
   "논산",
   "계룡",
   "당진",
   "금산",
   "부여",
   "서천",
   "청양",
   "홍성",
   "예산",
   "태안",
  ],
 },
 {
  name: "전북",
  districts: [
   "전주",
   "군산",
   "익산",
   "정읍",
   "남원",
   "김제",
   "완주",
   "진안",
   "무주",
   "장수",
   "임실",
   "순창",
   "고창",
   "부안",
  ],
 },
 {
  name: "전남",
  districts: [
   "목포",
   "여수",
   "순천",
   "나주",
   "광양",
   "담양",
   "곡성",
   "구례",
   "고흥",
   "보성",
   "화순",
   "장흥",
   "강진",
   "해남",
   "영암",
   "무안",
   "함평",
   "영광",
   "장성",
   "완도",
   "진도",
   "신안",
  ],
 },
 {
  name: "경북",
  districts: [
   "포항",
   "경주",
   "김천",
   "안동",
   "구미",
   "영주",
   "영천",
   "상주",
   "문경",
   "경산",
   "군위",
   "의성",
   "청송",
   "영양",
   "영덕",
   "청도",
   "고령",
   "성주",
   "칠곡",
   "예천",
   "봉화",
   "울진",
   "울릉",
  ],
 },
 {
  name: "경남",
  districts: [
   "창원",
   "진주",
   "통영",
   "사천",
   "김해",
   "밀양",
   "거제",
   "양산",
   "의령",
   "함안",
   "창녕",
   "고성",
   "남해",
   "하동",
   "산청",
   "함양",
   "거창",
   "합천",
  ],
 },
 {
  name: "제주",
  districts: ["제주", "서귀포"],
 },
];
