import { XCircleIcon, XMarkIcon, PlusIcon } from "@heroicons/react/24/outline";
import {
 DragDropContext,
 Droppable,
 Draggable,
 DropResult,
} from "react-beautiful-dnd";
import {
 CellType,
 LineType,
 FcellFragment,
 FpageFragment,
 FstoreFragment,
 FcouponFragment,
} from "../app/apollo/type/graphql";
import { CellTypeKr } from "../data/const";
import { HeroIconkeys, Icons } from "../data/icon.const";
import { s4, s6 } from "../utils/s4";
import { EditBox } from "./EditBox";
import { EditImageBox } from "./EditBox.image";
import { EditBoxTitle } from "./EditBox.title";
import { TitleOfEditBox } from "./EditBoxTitle";
import { IconTypePairOps } from "./InfoLineEditor";
import PageEditBox, { CommonPageEditBox } from "./CommonPageEdit";
import { Photo } from "./Photo";
import { withDefaultOp } from "./Select";
import { Spinner } from "./Spinner";
import { WithEmpty } from "./WithEmpty";
import { AddressInput } from "./addressInput/AddressInput";
import { BasicSelect } from "./basic/Select";
import { SingleImageUploaderBox } from "./basic/SingleImageUploader.box";
import { BasicButton } from "./basic/button";
import { BasicInput } from "./basic/input";
import { VideoEditor } from "./editors/Video";
import { TextArea } from "./textarea";
import { useContext } from "react";
import { AppContext } from "../hook/useApp.context";
import { swap } from "./swap";
import { useIframeHeight } from "../hook/useIframeHeight";
import { useUpdateComponent } from "../hook/useUpdateComponent";
import { IuseIframCommunicate } from "../hook/useIframCommunication";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import { PageEditorContext, usePageEditorContext } from "./PageEditor.context";
import { GroupEditor } from "./GroupEditor";
import { IntroPageEditor } from "./IntroPageEdit";
import { MianPageEditor } from "./MainPageEditor";
import { LayerAdd } from "./LayerAdd";
import { CouponEditor } from "./CouponEditor";
import { CellEditModal } from "./CellEditModal.model";
import GroupEditModal from "./GroupEditModal.modal";
import PageEditModal from "./PageEditModal.modal copy";

interface IProp {
 setKr: (obj: any, key: any, val: any) => void;
 set: () => void;
 loading: boolean;
 store?: FstoreFragment;
 coupon?: FcouponFragment;
 selectedPage?: FpageFragment;
 editingPath: string;
 iframControl: IuseIframCommunicate;
 bodyClasses?: string;
 iframeUrl?: string;
}

export const PageEditUI: React.FC<IProp> = ({
 setKr,
 set,
 store,
 coupon,
 selectedPage,
 editingPath,
 loading,
 iframControl,
 iframeUrl,
 bodyClasses,
}) => {
 const isCouponMode = !!coupon;
 const subject = coupon! || store!;
 const refreshIframe = useUpdateComponent();
 const { height } = useIframeHeight();
 const contextValue = useContext(AppContext);
 const pageEditorContext = usePageEditorContext({
  page: selectedPage,
  coupon,
  store,
  set,
  setKr,
  editingPath,
 });

 const isMain = selectedPage?.path === "/main";
 const isChat = editingPath === "/chat";
 const isIntro = editingPath === "/";

 const sectionLabelCalsess =
  "font-semibold bg-blue-50 rounded px-1 py-2 text-center mb-4";

 const currentIframHeight = iframControl?.selfIframeRef?.current?.height;
 const frameHeight =
  typeof currentIframHeight === "string"
   ? parseInt(currentIframHeight)
   : currentIframHeight;

 const currentViewUrl = `https://${
  store?.domain || ""
 }.hoteltalk.me${editingPath}`;

 const { cellEditModalHook, pageEditModalHook } = useContext(AppContext);

 return (
  <PageEditorContext.Provider value={pageEditorContext}>
   <div
    className={twMerge(
     classNames(" flex justify-around mb-0 m-[5%]  pb-[5%]", bodyClasses)
    )}
   >
    <div
     style={{
      borderRadius: "50px",
     }}
     className="  h-fit sticky top-8"
    >
     <iframe
      key={refreshIframe?.upKey + "Iframe"}
      style={{
       outline: "8px solid rgba(255,255,255,.5)",
       borderRadius: "50px",
       boxShadow: "0px 0px 14px rgba(0,0,0,.05)",
       height: "90svh",
      }}
      ref={iframControl?.selfIframeRef}
      className="max-w-md aspect-[9/16]  box-content  grow  sticky iframe-glow  top-4   "
      src={iframeUrl || `${editingPath}?id=${store?._id}&lang=ko&edit-chat=1`}
     ></iframe>
    </div>
    <div className="max-w-[460px] -ml-28 w-full">
     {/* {editContext.editingPath === "/main" && (
      <section className="shadow bg-white flex p-4 flex-col gap-8 px-4">
       <h6 className={sectionLabelCalsess}>메인페이지</h6>
       <BasicInput></BasicInput>
      </section>
     )} */}
     <section className="sticky top-8 overflow-auto   w-full flex  flex-col gap-2.5 ">
      <div className="border  px-5 py-4 text-sm rounded-full  bg-white">
       페이지 보기:{" "}
       <a
        href={currentViewUrl}
        target="_blank"
        className="text-blue-700 hover:text-blue-800 cursor-pointer"
       >
        {currentViewUrl}
       </a>
      </div>

      {isIntro && <IntroPageEditor />}
      {!isMain && !isIntro && (
       <CommonPageEditBox set={set} setKr={setKr} page={selectedPage!} />
      )}
      <CouponEditor />
      {isMain && <MianPageEditor />}
      {isChat && (
       <EditBox title={"채팅설정"}>
        <>
         <TextArea
          label="채팅 인사말"
          value={store?.introChat?.ko}
          onBlur={() => {
           refreshIframe.updateComponent();
          }}
          onChange={(val) => {
           setKr(subject, "introChat", val);
          }}
         />
         * 인사말은 번역을 위하여 미리보기에 표시되지 않습니다.
        </>
       </EditBox>
      )}
      <GroupEditor />
      {selectedPage && <LayerAdd />}
     </section>
    </div>
   </div>

   {/* 모달에서 전부뺴! */}
   {contextValue.pageEditModalHook.isOpen && (
    <PageEditModal modalhook={contextValue.pageEditModalHook} />
   )}
   {contextValue.cellEditModalHook?.isOpen && (
    <CellEditModal modalhook={contextValue.cellEditModalHook} />
   )}
   {contextValue.groupEditModalHook?.isOpen && (
    <GroupEditModal
     key={contextValue.groupEditModalHook?.info?.group?.key}
     modalhook={contextValue.groupEditModalHook}
    />
   )}
  </PageEditorContext.Provider>
 );
};
