import React, { useRef } from "react";
import { isNumberOnly } from "../../utils/validate";

interface ISingleInputProp extends React.HTMLProps<HTMLInputElement> {
 onInputChange: (e: React.ChangeEvent<HTMLInputElement>, idx: number) => void;
 onBackspace: (idx: number) => void;
 index: number;
 Ref?: any;
}

const SingleInput: React.FC<ISingleInputProp> = ({
 onInputChange,
 onBackspace,
 index,
 Ref,
 ...props
}) => {
 const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
  if (e.key === "Backspace" && !e.currentTarget.value) {
   onBackspace(index);
  }
 };

 return (
  <input
   min="1"
   max="100"
   className="w-[7.8vw] text-center rounded-none xs:w-[30.72px] h-[8.89vw] xs:h-[40.96px] p-[1.67vw] xs:p-[7.68px] border-b outline-none border-neutral-200 flex-col justify-center items-center gap-[2.78vw] xs:gap-[12.8px] inline-flex text-black text-[5.56vw] xs:text-[25.6px] font-semibold font-['Pretendard'] leading-tight"
   onChange={(e) => onInputChange(e, index)}
   onKeyDown={handleKeyDown}
   maxLength={1}
   placeholder={props.disabled ? "*" : ""}
   ref={Ref}
   {...props}
  />
 );
};

interface IProp {
 value: string;
 onChange: (val: string) => void;
 id?: string;
 disabled?: boolean;
 numDigits: number;
}

export const NDigitInput: React.FC<IProp> = ({
 disabled,
 id,
 value,
 onChange,
 numDigits,
}) => {
 const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

 const handleInputChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  idx: number
 ) => {
  const nextValue = e.target.value;
  if (nextValue) {
   if (!isNumberOnly(nextValue)) return;
  }
  onChange(value.substring(0, idx) + nextValue + value.substring(idx + 1));
  if (nextValue && idx < inputRefs.current.length - 1) {
   inputRefs.current[idx + 1]?.focus();
  }
 };

 const handleBackspace = (idx: number) => {
  if (idx > 0) {
   inputRefs.current[idx - 1]?.focus();
  }
 };

 const createInputs = (count: number) =>
  Array(count)
   .fill(null)
   .map((_, idx) => (
    <SingleInput
     value={value[idx] || ""}
     disabled={disabled}
     key={idx}
     onInputChange={handleInputChange}
     onBackspace={handleBackspace}
     index={idx}
     Ref={(el: any) => (inputRefs.current[idx] = el)}
    />
   ));

 return (
  <div
   id={id}
   onClick={(e) => {
    inputRefs.current.some((c) => {
     if (!c?.value) {
      c?.focus();
      return true;
     }
    });
   }}
   className="w-[48.89vw] xs:w-[225.92px] h-[13.33vw] xs:h-[61.44px] p-[2.78vw] xs:p-[11.52px] bg-white justify-start items-start gap-[5.56vw] xs:gap-[25.6px] inline-flex"
  >
   {createInputs(numDigits)}
  </div>
 );
};
