import { useContext } from "react";
import { FcellFragment, FgroupFragment } from "../../app/apollo/type/graphql";
import { Photo } from "../Photo";
import { AppContext } from "../../hook/useApp.context";
import { useNavigate } from "react-router-dom";
import { CellWrap } from "./CellWrap";
import YouTube from "react-youtube";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import { extractYouTubeVideoCode } from "../editors/Video";

interface IProp {
 group: FgroupFragment;
}
export const Video: React.FC<IProp> = ({ group }) => {
 const { lang } = useContext(AppContext);
 const navigate = useNavigate();

 if (group?.youtubeCode)
  return (
   <YouTube
    key={group?.youtubeCode}
    //  allowFullScreen={1}
    //  allow: "autoplay",
    className={twMerge(classNames("w-full aspect-video"))}
    opts={{
     height: "100%",
     width: "100%",
     playerVars: {
      autoplay: 0,
      disablekb: 1,
      wmode: "transparent",
      loop: 1,
      controls: 1,
      rel: 0,
      mute: 1,
      cc_load_policy: 0,
      modestbranding: 1,
      showinfo: 0,
      playlist: group?.youtubeCode,
      hd: 1,
      playsinline: 1,
      enablejsapi: 1,
     },
    }}
    onReady={() => {}}
    onPause={() => {}}
    onPlay={() => {}}
    videoId={extractYouTubeVideoCode(group?.youtubeCode)!}
    iframeClassName={twMerge()}
   />
  );

 return (
  <video
   key={group?.video?.uri}
   controls
   src={group?.video?.uri}
   className={"w-full"}
   playsInline
  />
 );
};
