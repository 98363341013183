import { OffsetPagingInput } from "../app/apollo/type/graphql";
import { usePagination } from "./usePagination";
import { IUseQueryFilter, useQueryFilter } from "./useQueryFilter";
import { IUseQuerySort, useQuerySort } from "./useQuerySort";
import { Dispatch, useState } from "react";

export type ISet<T> = Dispatch<React.SetStateAction<T>>;

export interface ListInitOptions<F, S> {
 unlimited?: boolean;
 initialPageIndex: number;
 initialViewCount: number;
 initialFilter: F;
 initialSort: S[];
 fixingFilter?: F;
 withFilter?: F;
}

export interface IListHook<F, S> extends IUseQueryFilter<F>, IUseQuerySort<S> {
 sort: S[];
 page: number;
 setPage: (page: number) => void;
 viewCount: number;
 setViewCount: (page: number) => void;
}

export function useListQuery<F, S>({
 initialFilter,
 initialPageIndex,
 initialSort,
 initialViewCount,
 fixingFilter,
 withFilter,
 unlimited,
}: ListInitOptions<F, S>) {
 const {
  filter,
  setFilter: _setFilter,

  ...useFilters
 } = useQueryFilter<F>(initialFilter || ({} as F), fixingFilter);
 const { sort, ...useSort } = useQuerySort<S>(initialSort);
 const [viewCount, setViewCount] = useState(initialViewCount);
 const paginatorHook = usePagination(initialPageIndex, viewCount);
 const { page, pageItemCount, setPage } = paginatorHook;

 const pagingInput: OffsetPagingInput = {
  pageIndex: page,
  pageItemCount,
 };

 const filterFn: any = (filter: F) => {
  _setFilter({ ...withFilter, ...filter, ...fixingFilter });
 };

 const setFilter: ISet<F> = filterFn;

 const integratedVariable = {
  pagingInput,
  filter,
  sort,
 };

 return {
  viewCount: unlimited ? 100000 : viewCount,
  setViewCount,
  filter,
  page,
  integratedVariable,
  sort,
  setPage,
  paginatorHook,
  setFilter,
  ...useFilters,
  ...useSort,
 };
}
