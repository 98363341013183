import { useContext } from "react";
import { EditBoxTitle } from "./EditBox.title";
import { PageEditorContext } from "./PageEditor.context";

export const MianPageEditor = () => {
 const { setKr, store, subject } = useContext(PageEditorContext);
 return (
  <>
   <EditBoxTitle
    title={"대표문구"}
    onChange={(val) => {
     setKr(subject, "mainMain", val);
    }}
    value={store?.mainMain?.ko}
   />
   <EditBoxTitle
    textarea
    title={"상세문구"}
    onChange={(val) => {
     setKr(subject, "mainIntro", val);
    }}
    value={store?.mainIntro?.ko}
   />
  </>
 );
};
