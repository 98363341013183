// Chart components with typed props

import dayjs from "dayjs";
import { EventType, FrecordFragment } from "../../app/apollo/type/graphql";
import {
 getHourlyViewsAndClicks,
 getTopClicks,
 getTopPageViews,
} from "./chart.helper";
import { Doughnut, Line, Radar, Bar } from "react-chartjs-2";
import isoWeek from "dayjs/plugin/isoWeek";

import {
 Chart as ChartJS,
 ArcElement, // For Doughnut, Pie charts
 BarElement, // For Bar charts
 LineElement, // For Line charts
 CategoryScale, // For scales on the X-axis
 LinearScale, // For scales on the Y-axis
 PointElement, // For Line charts
 Title,
 RadialLinearScale,
 Tooltip,
 Legend,
} from "chart.js";
import { LangMapKr } from "../../data/const";

// Register the required elements
ChartJS.register(
 ArcElement,
 BarElement,
 LineElement,
 PointElement,
 CategoryScale,
 RadialLinearScale,
 LinearScale,
 Title,
 Tooltip,
 Legend
);
// Props type for chart components
export interface ChartProps {
 data: FrecordFragment[];
 aspectRatio?: number;
}

// Pie chart for top page views
export const TopPageViewsPieChart: React.FC<ChartProps> = ({ data }) => {
 const topPageViews = getTopPageViews(data);
 console.log("!!topPageViews", data);
 const chartData = {
  labels: Object.keys(topPageViews),
  datasets: [
   {
    data: Object.values(topPageViews),
    backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
   },
  ],
 };
 console.log("@@chartData", chartData);
 return <Doughnut data={chartData} />;
};

// Bar chart for top click events
export const TopClicksBarChart: React.FC<ChartProps> = ({
 data,
 aspectRatio,
}) => {
 const topClicks = getTopClicks(data);
 const chartData = {
  labels: Object.keys(topClicks),
  datasets: [
   {
    label: "클릭 카운트",
    data: Object.values(topClicks),
    backgroundColor: "#36A2EB",
   },
  ],
 };

 return (
  <Bar
   data={chartData}
   options={{
    aspectRatio: aspectRatio || 1,
   }}
  />
 );
};

// Helper function to calculate the week number
// Helper function to calculate the week number within the month
const getWeekOfMonth = (date: string): number => {
 const startOfMonth = dayjs(date).startOf("month");
 const currentDayOfMonth = dayjs(date).diff(startOfMonth, "day");
 return Math.ceil((currentDayOfMonth + startOfMonth.day()) / 7);
};

// Bar chart for hourly views
export const HourlyViewsBarChart: React.FC<ChartProps> = ({ data }) => {
 const hourlyData = getHourlyViewsAndClicks(data);
 const chartData = {
  labels: Object.keys(hourlyData),
  datasets: [
   {
    label: "시간별 페이지뷰",
    data: Object.values(hourlyData).map((entry) => entry.views),
    backgroundColor: "#FFCE56",
   },
  ],
 };

 return <Bar data={chartData} />;
};

// Line chart for events (views and clicks) by hour
export const EventsOverTimeLineChart: React.FC<ChartProps> = ({
 data,
 aspectRatio,
}) => {
 const hourlyData = getHourlyViewsAndClicks(data);
 const chartData = {
  labels: Object.keys(hourlyData),
  datasets: [
   {
    label: "페이지뷰",
    data: Object.values(hourlyData).map((entry) => entry.views),
    borderColor: "#36A2EB",
    fill: false,
   },
   {
    label: "클릭",
    data: Object.values(hourlyData).map((entry) => entry.clicks),
    borderColor: "#FF6384",
    fill: false,
   },
  ],
 };

 return (
  <Line
   options={{
    aspectRatio: aspectRatio || 1,
   }}
   data={chartData}
  />
 );
};

export const EventDistributionRadarChart: React.FC<ChartProps> = ({ data }) => {
 const paths = Array.from(new Set(data.map((record) => record.cellName)));
 const viewsPerPath: { [key: string]: number } = {};
 const clicksPerPath: { [key: string]: number } = {};

 // Initialize counters for each path
 paths.forEach((path) => {
  viewsPerPath[path!] = 0;
  clicksPerPath[path!] = 0;
 });

 // Count views and clicks per path
 data.forEach((record) => {
  if (record.event === EventType.View) {
   viewsPerPath[record.cellName!]++;
  } else if (record.event === EventType.Click) {
   clicksPerPath[record.cellName!]++;
  }
 });

 const chartData = {
  labels: paths,
  datasets: [
   {
    label: "페이지뷰",
    data: paths.map((path) => viewsPerPath[path!]),
    backgroundColor: "rgba(54, 162, 235, 0.2)",
    borderColor: "rgba(54, 162, 235, 1)",
    pointBackgroundColor: "rgba(54, 162, 235, 1)",
    pointBorderColor: "#fff",
   },
   {
    label: "클릭",
    data: paths.map((path) => clicksPerPath[path!]),
    backgroundColor: "rgba(255, 99, 132, 0.2)",
    borderColor: "rgba(255, 99, 132, 1)",
    pointBackgroundColor: "rgba(255, 99, 132, 1)",
    pointBorderColor: "#fff",
   },
  ],
 };

 return (
  <Radar
   data={chartData}
   options={{
    aspectRatio: 1,
   }}
  />
 );
};

// Bar chart for daily events (views and clicks)
export const DailyEventsBarChart: React.FC<ChartProps> = ({ data }) => {
 const dailyData: { [key: string]: { views: number; clicks: number } } = {};

 // Aggregate data by day
 data.forEach((record) => {
  const day = dayjs(record.createdAt).format("YYYY-MM-DD");
  if (!dailyData[day]) {
   dailyData[day] = { views: 0, clicks: 0 };
  }
  if (record.event === EventType.View) {
   dailyData[day].views += 1;
  } else if (record.event === EventType.Click) {
   dailyData[day].clicks += 1;
  }
 });

 const chartData = {
  labels: Object.keys(dailyData),
  datasets: [
   {
    label: "Views",
    data: Object.values(dailyData).map((entry) => entry.views),
    backgroundColor: "#36A2EB",
   },
   {
    label: "Clicks",
    data: Object.values(dailyData).map((entry) => entry.clicks),
    backgroundColor: "#FF6384",
   },
  ],
 };

 return (
  <Bar
   options={{
    aspectRatio: 1,
   }}
   data={chartData}
  />
 );
};

// Bar chart for weekly events (views and clicks)
export const WeeklyEventsBarChart: React.FC<ChartProps> = ({ data }) => {
 const weeklyData: { [key: string]: { views: number; clicks: number } } = {};

 // Aggregate data by week in N주차 format
 data.forEach((record) => {
  const weekNumber = getWeekOfMonth(record.createdAt); // Get the week of the month
  const month = dayjs(record.createdAt).format("M"); // Get the month (e.g., 1 for January, 12 for December)
  const weekLabel = `${month}월 ${weekNumber}주차`; // Format as 0월 0주차

  if (!weeklyData[weekLabel]) {
   weeklyData[weekLabel] = { views: 0, clicks: 0 };
  }

  if (record.event === EventType.View) {
   weeklyData[weekLabel].views += 1;
  } else if (record.event === EventType.Click) {
   weeklyData[weekLabel].clicks += 1;
  }
 });

 const chartData = {
  labels: Object.keys(weeklyData),
  datasets: [
   {
    label: "페이지뷰",
    data: Object.values(weeklyData).map((entry) => entry.views),
    backgroundColor: "#36A2EB",
   },
   {
    label: "클릭",
    data: Object.values(weeklyData).map((entry) => entry.clicks),
    backgroundColor: "#FF6384",
   },
  ],
 };

 return (
  <Bar
   options={{
    aspectRatio: 1,
   }}
   data={chartData}
  />
 );
};
// Heatmap-style chart for events by day and hour
export const HeatmapEventsByDayHour: React.FC<ChartProps> = ({ data }) => {
 const heatmapData: { [key: string]: { [hour: string]: number } } = {};

 // Initialize days and hours
 const days = ["월", "화", "수", "목", "금", "토", "일"];
 days.forEach((day) => {
  heatmapData[day] = {};
  for (let hour = 0; hour < 24; hour++) {
   heatmapData[day][hour.toString()] = 0;
  }
 });

 // Populate heatmap data by day and hour
 data.forEach((record) => {
  if (record.createdAt) {
   const dayOfWeek = dayjs.tz(record.createdAt).locale("ko").format("ddd"); // Get the full day of the week, e.g., 'Monday'
   const hourOfDay = dayjs(record.createdAt).format("H"); // Get the hour in 24-hour format (0-23)
   console.log({
    dayOfWeek,
   });

   // Check if both dayOfWeek and hourOfDay exist in the initialized structure
   if (
    heatmapData[dayOfWeek] &&
    heatmapData[dayOfWeek][hourOfDay] !== undefined
   ) {
    heatmapData[dayOfWeek][hourOfDay] += 1; // Increment the count for the specific day and hour
   }
  }
 });

 // Prepare chart data
 const chartData = {
  labels: days,
  datasets: Array.from({ length: 24 }, (_, hour) => ({
   label: `${hour}:00`,
   data: days.map((day) => heatmapData[day][hour.toString()]),
   backgroundColor: `rgba(75,192,192,${hour / 24})`, // Dynamic color for each hour
  })),
 };

 const options = {
  scales: {
   x: {
    stacked: true,
   },
   y: {
    stacked: true,
    ticks: {
     beginAtZero: true,
    },
   },
  },
 };

 console.log("cccc-chartData", chartData);

 return <Bar data={chartData} options={options as any} />;
};

// Helper function to calculate stats by language
const getLanguageStats = (data: FrecordFragment[]) => {
 const languageStats: { [key: string]: { views: number; clicks: number } } = {};

 data.forEach((record) => {
  const lang = record.lang || "Unknown"; // If lang is missing, label it as 'Unknown'

  if (!languageStats[lang]) {
   languageStats[lang] = { views: 0, clicks: 0 };
  }

  if (record.event === EventType.View) {
   languageStats[lang].views += 1;
  } else if (record.event === EventType.Click) {
   languageStats[lang].clicks += 1;
  }
 });

 return languageStats;
};

// Doughnut chart for language statistics
export const LanguageStatsDoughnutChart: React.FC<{
 data: FrecordFragment[];
}> = ({ data }) => {
 const languageStats = getLanguageStats(data);

 const chartData = {
  labels: Object.keys(languageStats)?.map(
   (langcode) => LangMapKr[langcode] || langcode
  ), // Languages as labels
  datasets: [
   {
    label: "활동량",
    data: Object.values(languageStats).map(
     (entry) => entry.views + entry.clicks // Combine views and clicks
    ),
    backgroundColor: [
     "#36A2EB",
     "#FF6384",
     "#FFCE56",
     "#4BC0C0",
     "#9966FF",
     "#FF9F40",
    ],
   },
  ],
 };

 const options = {
  aspectRatio: 1, // 1:1 ratio for the doughnut chart
 };

 return <Doughnut data={chartData} options={options} />;
};
