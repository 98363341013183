import { CHATROOM_LIST } from "../apollo/gql/chat";
import { COUPON_HISTORY_LIST } from "../apollo/gql/item";
import { generateListQueryHook } from "../apollo/query";
import {
 FcouponHistoryFragment,
 CouponHistoryListQuery,
 CouponHistoryListQueryVariables,
 _ChatRoomFilter,
 _ChatRoomSort,
 _CouponHistoryFilter,
 _CouponHistorySort,
} from "../app/apollo/type/graphql";

export const useCouponHistoryListHook = generateListQueryHook<
 _CouponHistoryFilter,
 _CouponHistorySort,
 CouponHistoryListQuery,
 CouponHistoryListQueryVariables,
 FcouponHistoryFragment
>(COUPON_HISTORY_LIST);
