import { useContext } from "react";
import { FcellFragment } from "../../app/apollo/type/graphql";
import { Photo } from "../Photo";
import { AppContext } from "../../hook/useApp.context";
import { KakaoMap } from "../Map";
import { MapPin } from "../MapPin";
import { CellWrap } from "./CellWrap";
import { iamTopWindow } from "../../utils/localstorage/StorageAddition";

interface IProp {
 cell: FcellFragment;
}

export const MapCell: React.FC<IProp> = ({ cell }) => {
 const { lang } = useContext(AppContext);

 const lat = cell.addressDetail?.lat || 0;
 const lng = cell.addressDetail?.lng || 0;

 return (
  <CellWrap
   cell={cell}
   className="rounded-[2.22vw] xs:rounded-[10.24px] overflow-hidden col-span-2"
  >
   {cell.name?.ko && (
    <div
     onClick={() => {
      // Functionality for the name click event (if needed)
     }}
     className="text-[4.17vw] xs:text-[19.2px] font-bold !leading-none"
    >
     {lang(cell.name)}
    </div>
   )}

   <KakaoMap
    defaultZoom={12}
    key={lat + lng}
    defaultCenter={{
     lat: lat || 35.1002169222157,
     lng: lng || 129.032488274285,
    }}
    style={{
     width: "100%",
     height: "55.56vw",
     maxHeight: "320px",
    }}
   />
  </CellWrap>
 );
};
