import React, { useContext, useEffect, useState } from "react";
import {
 FcouponFragment,
 useAdminQuery,
 useSuperAdminQuery,
} from "../app/apollo/type/graphql";
import { useModal } from "./useModal";

export type TUseSuperAdminContext = ReturnType<
 typeof useSuperAdminContextProvider
>;

export const useSuperAdminContextProvider = ({ ...props }) => {
 const [editingCoupon, setEditingCoupon] = useState<FcouponFragment>();
 const {
  loading: authLoading,
  data: superAdminResult,
  refetch: refetchSuperAdmin,
 } = useSuperAdminQuery({
  fetchPolicy: "no-cache",
  //   skip: !hasAdminCookie,
 });

 //  const couponEditModalHook = useModal();
 useEffect(() => {
  refetchSuperAdmin();
 }, []);
 const superAdmin = superAdminResult?.SuperAdmin;
 return {
  editingCoupon,
  setEditingCoupon,
  //   couponEditModalHook,
  refetchSuperAdmin,
  authLoading,
  superAdmin,
 };
};

export const SuperAdminContext = React.createContext<TUseSuperAdminContext>(
 {} as any
);
export const useSuperAdminContext = () => {
 return useContext(SuperAdminContext);
};
