import {
 Category,
 UserRole,
 _ChatRoomSort,
 _CouponHistorySort,
 useUserDeleteMutation,
} from "../app/apollo/type/graphql";
import { SkipUpdate } from "../components/SkipUpdate";
import { hhmmA, yyyymmdd, yyyymmddHHmm } from "../utils/date";
import { useContext } from "react";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import Pagination from "../components/Pagination";
import { SuperAdminContext } from "../hook/super.admin.context";
import { userListHook } from "../hook/userListHook";
import {
 GlobeAltIcon,
 PlusIcon,
 SpeakerWaveIcon,
} from "@heroicons/react/24/outline";
import { Badge } from "../components/Badge";
import { JWtable } from "../components/Table";
import { Button } from "../components/button";
import { CategoryKr } from "../data/const";
import { getSppech } from "../utils/speech";
import { AppContext } from "../hook/useApp.context";
import { getRefetch } from "../utils/getRefetch";
import { USER_LIST } from "../apollo/gql/user";
import { useCouponHistoryListHook } from "../hook/useCouponHistory";

export const CouponUseList = () => {
 const { authLoading, refetchSuperAdmin, superAdmin } =
  useContext(SuperAdminContext);
 const { userCreateModalHook } = useContext(AppContext);
 const {
  items: histories,
  getLoading,
  pageInfo,
  setPage,
 } = useCouponHistoryListHook({
  initialSort: [_CouponHistorySort.CreatedAtDesc],
 });

 const iconClasses = "cursor-pointer";
 return (
  <div className="flex  ">
   <div className="w-full">
    {/* {search && (
        <div className="p-4 tracking-wide">
         <span className=" text-primary-200 font-semibold">{search}</span>에 대한
         검색결과 총
         <b className="text-primary-200 tracking-widest">
          {pageInfo?.totalPageCount || 0}
         </b>
         건{" "}
         <XMarkIcon
          className="w-5 h-5 cursor-pointer inline-block"
          onClick={() => {
           setSearch("");
          }}
         />
        </div>
       )} */}

    <Button
     onClick={() => {
      userCreateModalHook.openImmediate({});
     }}
     color="primary"
     className="w-[90%] mx-auto mt-20 px-4 mb-8"
    >
     등록하기 <PlusIcon className="ml-1 w-5" strokeWidth={2} />
    </Button>

    <SkipUpdate skip={getLoading}>
     <JWtable
      className="mb-8"
      data={histories}
      columns={[
       {
        title: "접속 호텔명",
        accessor: "storeName",
        Cell: ({ original }) => {
         return (
          <div className="relative rounded overflow-hidden">
           {original.storeName}
          </div>
         );
        },
       },
       {
        title: "쿠폰명/항목명",
        accessor: "couponName",
        Cell: ({ original }) => {
         return (
          <div className="flex justify-center flex-col items-center">
           {original?.pageName || "-"} / {original.couponName}
          </div>
         );
        },
       },
       {
        title: "좌표",
        accessor: "lat",
        Cell: ({ original }) => {
         return (
          <div>
           <div className="text-xs line-clamp-3">
            {original.lat} /{original.lng}
           </div>
          </div>
         );
        },
       },
       {
        title: "유저",
        accessor: "mid",
        Cell: ({ original }) => {
         return (
          <div>
           <div className="text-xs line-clamp-3">{original.mid || "-"}</div>
          </div>
         );
        },
       },
       {
        title: "일시",
        accessor: "createdAt",
        Cell: ({ original }) => {
         return (
          <div className="flex gap-1 flex-col">
           {yyyymmddHHmm(original?.createdAt)}
          </div>
         );
        },
       },
      ]}
     />
     {pageInfo.totalPageCount > 1 && (
      <div className="mt-20">
       <Pagination
        onPageChange={(page) => {
         setPage(page - 1);
        }}
        currentPage={pageInfo.pageIndex + 1}
        totalPages={pageInfo.totalPageCount}
       />
      </div>
     )}
    </SkipUpdate>
   </div>
  </div>
 );
};

export default CouponUseList;
