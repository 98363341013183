import React, { useEffect, useRef } from "react";
import DaumPostcode, { Address } from "react-daum-postcode";
import { AddressModal } from "./AddressModal";
import { Input } from "../input";
import { Button } from "../button";
import { useModal } from "../../hook/useModal";
import { FdetailLocationFragment } from "../../app/apollo/type/graphql";
import { useAddressSearchWithIframe } from "../../page/addressProxy";
import useUpdateEffect from "../../hook/useUpdateEffect";

export type LatLang = {
 lat: number;
 lng: number;
};

interface TMapData extends Address {
 zonecode: string;
 address: string;
 addressEnglish: string;
 addressType: "R" | "J";
 userSelectedType: "R" | "J";
 noSelected: "Y" | "N";
 userLanguageType: "K" | "E";
 roadAddress: string;
 roadAddressEnglish: string;
 jibunAddress: string;
 jibunAddressEnglish: string;
 autoRoadAddress: string;
 autoRoadAddressEnglish: string;
 autoJibunAddress: string;
 autoJibunAddressEnglish: string;
 buildingCode: string;
 buildingName: string;
 apartment: "Y" | "N";
 sido: string;
 sidoEnglish: string;
 sigungu: string;
 sigunguEnglish: string;
 sigunguCode: string;
 roadnameCode: string;
 bcode: string;
 roadname: string;
 roadnameEnglish: string;
 bname: string;
 bnameEnglish: string;
 bname1: string;
 bname1English: string;
 bname2: string;
 bname2English: string;
 hname: string;
 query: string;
}

interface IProp {
 inputProps?: React.ComponentProps<typeof Input>;
 address: string;
 setAddress: (data: FdetailLocationFragment) => void;
 modalProps?: React.ComponentProps<typeof AddressModal>;
 mode?: "modal" | "inline";
 label?: string;
 data?: FdetailLocationFragment;
 className?: string;
}

export const AddressInput: React.FC<IProp> = ({
 inputProps,
 address,
 setAddress,
 modalProps,
 data,
 label,
 mode = "modal",
 ...props
}) => {
 const modalHook = useModal();
 const modalMode = mode === "modal";
 const ref = useRef<HTMLIFrameElement>(null);

 const handleDaumPostalComplete = (data: TMapData) => {
  setAddress({ ...data } as FdetailLocationFragment);
  modalHook.close();
 };

 const { onSearch } = useAddressSearchWithIframe({
  ref,
  data,
  onResult(lat, lng) {
   console.log("resultAddressSet", { ...data, lng, lat });
   setAddress({ ...data, lng, lat });
  },
 });
 useUpdateEffect(() => {
  if (data?.roadAddress) onSearch(data?.roadAddress || "");
 }, [data?.roadAddress]);

 return (
  <div {...props}>
   <div className="flex mb-2 gap-2 items-end">
    <Input
     label={label}
     placeholder="도로명주소"
     value={address}
     readOnly
     onDoubleClick={() => {
      modalHook.open();
     }}
     style={{
      cursor: "pointer",
     }}
     {...inputProps}
    />
    <Button
     color="primary"
     className="w-fit px-14 whitespace-nowrap"
     onClick={modalHook.open}
    >
     검색
    </Button>
   </div>

   <Input
    disabled={!data}
    label={"상세주소"}
    placeholder="상세주소"
    value={data?.addressDetail || ""}
    onChange={(val) => {
     setAddress({ ...data, addressDetail: val });
    }}
    {...inputProps}
   />

   {!modalMode && modalHook.isOpen && (
    <div
     style={{
      maxHeight: 390,
      overflow: "auto",
     }}
     className="addressModal__inlineInput"
    >
     <DaumPostcode onComplete={handleDaumPostalComplete} />
    </div>
   )}
   {modalMode && (
    <AddressModal
     modalHook={modalHook}
     focusContent
     modalProps={modalProps}
     onComplete={handleDaumPostalComplete}
     key={modalHook.isOpen ? "addressMOdal--open" : "addressMOdal--close"}
     defaultQuery={address}
    />
   )}
   <iframe className="hidden" ref={ref} src="https://address.hoteltalk.me" />
  </div>
 );
};
