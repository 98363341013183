import { useContext } from "react";
import { AppContext } from "../hook/useApp.context";
import { L, LangOps } from "../data/lang.static";
import { useNavigate } from "react-router-dom";
import { NativeSelecter } from "../components/basic/NativeSelecter";
import { Group } from "../components/cell/Group";
import { useLocation } from "react-router-dom";

export const Main = () => {
 const { pathname } = useLocation();
 const navigate = useNavigate();
 const { store, lang, langCode, updateComponent, resetLang } =
  useContext(AppContext);
 const page = store?.pages?.find((p) => p.path === pathname);

 return (
  <div className="w-full px-[5.56vw] xs:px-[25.6px] relative py-[11.11vw] xs:py-[51.2px] flex flex-col bg-white shadow">
   <div className="flex flex-wrap gap-4 justify-between">
    <div className="flex gap-[2.5] flex-col">
     <div className="text-[5.56vw] xs:text-[25.6px] font-normal !leading-tight">
      {lang(store?.mainIntro)}
     </div>
     <div className="text-[5.56vw] xs:text-[25.6px] font-bold">
      {lang(store?.mainMain)}
     </div>
    </div>
    <div className=" ">
     <NativeSelecter
      onChange={resetLang}
      options={LangOps}
      value={langCode}
      placeholder={L["Language"]}
      selectClasses="w-fit text-center py-[1.11vw] xs:py-[5.12px] px-[2.22vw] xs:px-[10.24px]"
      className="border xs:border text-[3.33vw] xs:text-[14.4px]"
      noCicon
     />
    </div>
   </div>
   <div className="flex flex-col pb-[16.11vw] xs:pb-[76.8px] py-[11.11vw] xs:py-[51.2px] gap-[11.11vw] xs:gap-[51.2px] ">
    {page?.groups?.map((g) => {
     return <Group key={g.key} group={g} />;
    })}
   </div>
  </div>
 );
};

export default Main;
