import { XMarkIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";

import { BasicInput } from "./input";
import { ValidaterView } from "./validater";
import { BasicBadge } from "./Badge";
import { useInput } from "../../hook/useInput";
import { s4 } from "../../utils/s4";
import { whenEnter } from "../../utils/whenEnter";

type KeyLabel = { key: string; label: string };
interface IProp {
 options: KeyLabel[];
 onChange: (options: KeyLabel[]) => void;
 className?: string;
 placeholder?: string;
}

export const BasicTagInput: React.FC<IProp> = ({
 className,
 options,
 onChange,
 placeholder,
}) => {
 const writingHook = useInput("");
 return (
  <div className={classNames(" flex flex-col gap-1", className)}>
   <BasicInput
    {...writingHook}
    onKeyPress={whenEnter((e: any) => {
     const val = e.currentTarget.value;
     writingHook.onChange("");
     const key = s4();
     onChange([...options, { key, label: val }]);
    })}
    placeholder={placeholder || "Enter로 추가입력"}
   />

   <div className="flex  flex-row flex-wrap gap-1">
    {options.map(({ key, label }, index) => {
     return (
      <BasicBadge
       innerClasses="flex gap-1"
       color="green"
       key={key + index}
       size="M"
      >
       {label}{" "}
       <XMarkIcon
        className="w-4 cursor-pointer text-neutral-400  hover:text-neutral-800"
        onClick={() => {
         //  const result = confirm("정말로 카테고리를 삭제 하시겠습니까?");
         //  if (!result) return;
         const copy = [...options];
         copy.splice(index, 1);
         onChange(copy);
        }}
       />{" "}
      </BasicBadge>
     );
    })}
   </div>
   <ValidaterView
    validates={[
     {
      ok: !writingHook.value,
      noMessage: "Enter키로 추가입력",
     },
    ]}
    show
   />
  </div>
 );
};
