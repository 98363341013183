import { useContext, useState } from "react";
import {
 FpageFragment,
 FpageFragmentDoc,
 FfileFragment,
} from "../app/apollo/type/graphql";
import { TUseModal } from "../hook/useModal";
import { useSingleUpload } from "../hook/useSingleUpload";
import Modal from "./Modal";
import { SingleImageUploader } from "./basic/SingleImageUploader";
import { BasicInput } from "./basic/input";
import { s4, s6 } from "../utils/s4";
import { useSelect } from "../hook/useSelect";
import { AppEditContext, useAppEditContext } from "../hook/useAppEdit.context";
import { useInput } from "../hook/useInput";
import { BasicButton } from "./basic/button";

// Square: "Square",
// Info:  "Info",
// Verti: "Verti",
// PageBlock: "PageBlock",
// PageInfo: "PageInfo",

export interface IPageEditInfo {
 page?: FpageFragment;
}

interface IProp {
 modalhook: TUseModal<IPageEditInfo>;
}

export const PageEditModal: React.FC<IProp> = ({ modalhook }) => {
 const { store, set } = useContext(AppEditContext);
 const defaultPage = modalhook.info?.page;
 const [loading, setLoading] = useState(false);

 const { name, ...otherProp } = defaultPage || ({} as any);

 const nameHook = useInput(defaultPage?.name?.ko || "");
 const imageUploaderHook = useSingleUpload(defaultPage?.image || undefined);

 const nextPage: FpageFragment = {
  name: {
   ...defaultPage?.name,
   ko: nameHook?.value,
  },
  ...otherProp,
 };

 const handleSubmit = () => {
  setLoading(true);
  if (defaultPage) {
   const targetIndex = store?.pages?.findIndex(
    (p) => p.key === defaultPage.key
   )!;
   store!.pages[targetIndex] = nextPage;
   set();
  } else {
   store!.pages?.push(nextPage);
   set();
  }
  setLoading(false);
 };

 return (
  <Modal modalHook={modalhook}>
   <BasicInput
    label="페이지명"
    placeholder="타이틀을 입력해주세요"
    {...nameHook}
   />
   <BasicButton
    className="mt-5"
    onClick={() => {
     handleSubmit();
     modalhook.close();
    }}
   >
    완료하기
   </BasicButton>
  </Modal>
 );
};

export default PageEditModal;
