import { useContext } from "react";
import { AppContext } from "../hook/useApp.context";
import { L, LangOps } from "../data/lang.static";
import { Photo } from "../components/Photo";
import { BasicButton } from "../components/basic/Button.ratio";
import { useNavigate } from "react-router-dom";
import { NativeSelecter } from "../components/basic/NativeSelecter";
import { FieldType, useBookerSignupMutation } from "../app/apollo/type/graphql";
import ControlledRadioComponent from "./Radio";
import { BasicInput } from "../components/basic/input";
import { useSignupForm } from "../hook/useSignupForm";
import { iamTopWindow } from "../utils/localstorage/StorageAddition";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

export const Intro = () => {
 const navigate = useNavigate();
 const { store, lang, langCode, previewStore, updateComponent, resetLang } =
  useContext(AppContext);
 const { fields, setFields, needSignUp, handleSignUp, validate } =
  useSignupForm();

 //   h-[83.33vw] xs:h-[384px]
 return (
  <div className="w-full relative min-h-screen  flex flex-col bg-white shadow">
   <div className="w-full h-[83.33vw] xs:h-[384px] relative rounded-bl-[13.89vw] xs:rounded-bl-[61.44px] overflow-hidden rounded-br-[13.89vw] xs:rounded-br-[61.44px]">
    <Photo
     className="object-cover bg-fixed object-center w-full h-full"
     size={1000}
     file={store?.mainImage}
     defaultSrc={"/360x300.png"}
    />
   </div>
   <div className="flex h-full min-h-[50svh] my-[11.11vw] xs:my-[51.2px] gap-[0.69vw] xs:gap-[2.56px] flex-col items-center justify-between">
    <div className="px-4 text-center text-[5vw] xs:text-[23.04px] font-bold">
     <h6 className="font-bold text-[5.56vw] xs:text-[25.6px]">
      {lang(store?.introMain)}
     </h6>
     <h1 className="font-semibold text-[4.44vw] xs:text-[20.48px] whitespace-pre-wrap">
      {lang(store?.introduce)}
     </h1>
    </div>
    <div className="mx-auto ">
     <NativeSelecter
      onChange={resetLang}
      options={LangOps}
      value={langCode}
      placeholder={L["Language"]}
      className="w-[66.67vw] xs:w-[307.2px]"
     />
     <div className="text-gray-800 text-center mt-[5.56vw] xs:mt-[25.6px] !leading-none text-[3.89vw] xs:text-[17.92px] font-normal">
      {L["Language_Desc"]}
     </div>
    </div>

    {(!iamTopWindow || needSignUp) && !!fields?.length && (
     <div className="flex text-[3.33vw] xs:text-[16px] flex-col my-[6.67vw] xs:my-[32px] gap-[2.8vw] xs:gap-[14px]  w-[66.67vw] xs:w-[307.2px]">
      {fields?.map((field) => {
       if (field.type === FieldType.Select) {
        return (
         <div className="w-full relative" key={field.key}>
          <div className="mb-[1.67vw] xs:mb-[8px] flex gap-1">
           <span className="text-[3.33vw] xs:text-[16px]">
            {lang(field.label)}
           </span>
           <span className="text-[3.33vw] xs:text-[16px] text-rose-500">
            {field.require ? "*필수" : ""}
           </span>
          </div>
          <div className="relative">
           <ChevronDownIcon
            strokeWidth={2}
            className="absolute right-2 w-4 h-4 z-20 top-4"
           />
           <select
            onChange={(e) => {
             if (!iamTopWindow) {
              alert("미리보기 화면에서는 선택 하실수 없습니다.");
              return;
             }
             console.log("@@@e.currentTarget.value", e?.currentTarget?.value);
             field.value = e.currentTarget.value;
             setFields([...fields]);
            }}
            value={field.value || undefined}
            className="border appearance-none relative px-[2.5vw] xs:px-[12px] h-[11vw] xs:h-[48px] border-solid rounded w-full text-[4.44vw] xs:text-[19.2px]"
           >
            <option className="text-[3.33vw] xs:text-[16px]">
             {L["select"]}
            </option>
            {field.options?.map((op) => {
             return (
              <option
               key={lang(op) + field.key}
               value={lang(op)}
               className="text-[3.33vw] xs:text-[16px]"
              >
               {lang(op) || op.ko}
              </option>
             );
            })}
           </select>
          </div>
         </div>
        );
       } else if (field.type === FieldType.Input) {
        return (
         <div className="w-full" key={field.key}>
          <div className="mb-[1.67vw] xs:mb-[8px] flex gap-1">
           <span className="text-[3.33vw] xs:text-[16px]">
            {lang(field.label)}
           </span>
           <span className="text-[3.33vw] xs:text-[16px] text-rose-500">
            {field.require ? "*필수" : ""}
           </span>
          </div>
          <input
           className="border px-[2.5vw] xs:px-[12px] w-full h-[11vw] xs:h-[48px] rounded border-solid border-neutral-200 text-[4.44vw] xs:text-[19.2px]"
           value={field.value || ""}
           onChange={(e) => {
            if (!iamTopWindow) {
             alert("미리보기 화면에서는 선택 하실수 없습니다.");
             return;
            }
            field.value = e.currentTarget.value;
            setFields([...fields]);
           }}
          />
         </div>
        );
       } else {
        return <div>-</div>;
       }
      })}
     </div>
    )}

    <div>
     <BasicButton
      onClick={() => {
       if (validate()) {
        if (iamTopWindow) {
         handleSignUp();
        }
        navigate("/main");
       }
      }}
      color="primary"
      className="font-semibold w-[88.89vw] text-[5vw]  xs:text-[23.04px] xs:w-[409.6px] rounded-full"
     >
      {L["Start Now"]}
     </BasicButton>
    </div>
   </div>
  </div>
 );
};

export default Intro;
