import React, { useContext } from "react";
import { useMemorialState } from "./useMemorialState";
import { AppContext } from "./useApp.context";

export type TUseAdminContext = ReturnType<typeof useAdminContextProvider>;

export const useAdminContextProvider = ({ ...props }) => {
 const { store } = useContext(AppContext);
 const { val: roomId, setVal: setRoomId } = useMemorialState(
  "",
  "lastOpenAdminChatRoomId" + store?._id
 );
 return {
  roomId,
  setRoomId,
 };
};

export const AdminContext = React.createContext<TUseAdminContext>({} as any);
export const useAdminContext = () => {
 return useContext(AdminContext);
};
