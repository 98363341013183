import { useContext, useEffect } from "react";
import { useCopy } from "./useCopy";
import { AppContext } from "./useApp.context";
import { getFromUrl } from "../utils/getFromUrl";
import { LocalStorage } from "../utils/localstorage/StorageAddition";
import { s8 } from "../utils/s4";
import { useBookerSignupMutation } from "../app/apollo/type/graphql";
import { Validater } from "../utils/validater";
import { jsonSafeStringFy } from "../utils/localstorage/safeParse";
import { cloneDeep } from "@apollo/client/utilities";

export const useSignupForm = () => {
 const { store, langCode } = useContext(AppContext);
 const [fields, setFields] = useCopy(store?.fields || []);
 const [bookerSignUp] = useBookerSignupMutation();

 const mid = LocalStorage.getItem("mid");
 const bid = LocalStorage.getItem("bid");
 const needSignUp = !bid;

 const { validate } = new Validater(
  fields
   ?.filter((v) => v.require)
   .map((field) => {
    return {
     value: field.value || !needSignUp,
     failMsg: "필수 사항을 입력 해주세요.",
    };
   })
 );

 const handleSignUp = () => {
  const bookerId = bid || s8();
  if (!needSignUp) return;

  bookerSignUp({
   variables: {
    input: {
     bookerId,
     fields: fields,
     lang: langCode,
     storeId: store?._id,
     machineId: mid,
    },
   },
  }).then(() => {
   LocalStorage.setItem("bid", bookerId);
  });
 };

 useEffect(() => {
  setFields(cloneDeep([...(store?.fields || [])]));
 }, [jsonSafeStringFy(store?.fields || {})]);

 return {
  validate,
  needSignUp: !bid,
  handleSignUp,
  fields,
  setFields,
 };
};
