import { BOOKER_LIST } from "../apollo/gql/booker";
import { generateListQueryHook } from "../apollo/query";
import {
 BookerListQuery,
 BookerListQueryVariables,
 FbookerFragment,
 _BookerFilter,
 _BookerSort,
 _ChatRoomFilter,
 _ChatRoomSort,
 _UserFilter,
 _UserSort,
} from "../app/apollo/type/graphql";

export const bookerListHook = generateListQueryHook<
 _BookerFilter,
 _BookerSort,
 BookerListQuery,
 BookerListQueryVariables,
 FbookerFragment
>(BOOKER_LIST);
