import { ReactNode, useState } from "react";

import { TUseModal } from "../hook/useModal";
import Modal from "./Modal";
import { Button } from "./button";
import {
 CouponFindByIdQuery,
 FuserFragment,
 useCouponCreateMutation,
 useCouponFindByIdQuery,
 useCouponUpdateMutation,
 useUserRegistMutation,
 useUserUpdateMutation,
} from "../app/apollo/type/graphql";
import { Input } from "./input";
import { TextArea } from "./textarea";
import { AddressInput } from "./addressInput/AddressInput";
import { useAddressInput } from "../hook/useAddress";
import { useSingleUpload } from "../hook/useSingleUpload";
import { useInput } from "../hook/useInput";
import { s6, s8 } from "../utils/s4";
import { BasicInput } from "./basic/input";
import { BasicButton } from "./basic/button";
import { getRefetch } from "../utils/getRefetch";
import { USER_LIST } from "../apollo/gql/user";
import { ListUser } from "../hook/userListHook";

export interface IUserCreateModalInfo {
 user?: ListUser;
}

interface IProp {
 modalHook: TUseModal<IUserCreateModalInfo>;
}

const UserCreateModal: React.FC<IProp> = ({ modalHook }) => {
 const user = modalHook?.info?.user;
 const isCreate = !modalHook?.info?.user;
 const [updateMu, { loading: updateLoading }] = useUserUpdateMutation({
  ...getRefetch(USER_LIST),
  onCompleted(data, clientOptions) {
   if (data.UserUpdate.ok) {
    modalHook.close();
   }
  },
 });
 const [regiMu, { loading: createLoading }] = useUserRegistMutation({
  ...getRefetch(USER_LIST),
  onCompleted(data, clientOptions) {
   if (data.UserRegist.ok) {
    modalHook.close();
   }
  },
 });
 const nameHook = useInput(user?.name?.ko || "");
 const domainHook = useInput(user?.Store?.domain || "");
 const passwordHook = useInput("");

 return (
  <Modal modalHook={modalHook}>
   <div className="max-h-[80vh] overflow-auto">
    <h6 className="mb-6 text-neutral-800 text-2xl font-semibold">
     유저 등록정보
    </h6>
    <div className="flex flex-col gap-4">
     <BasicInput {...nameHook} label="명칭" />

     <BasicInput
      {...passwordHook}
      placeholder={user ? "비워둘시 비밀번호 유지" : ""}
      label="패스워드"
     />

     <BasicInput {...domainHook} label="도메인(알파벳만입력)" />

     <BasicButton
      color="primary"
      disabled={createLoading || updateLoading}
      onClick={() => {
       if (isCreate)
        regiMu({
         variables: {
          domain: domainHook.value,
          name: nameHook.value?.toString(),
          password: passwordHook.value,
         },
        });
       else {
        updateMu({
         variables: {
          domain: domainHook.value,
          name: nameHook.value?.toString(),
          password: passwordHook.value,
          userId: user?._id!,
         },
        });
       }
      }}
     >
      {isCreate ? "등록하기" : "수정하기"}
     </BasicButton>
    </div>
   </div>
  </Modal>
 );
};

export default UserCreateModal;
