import { useContext } from "react";
import { PageEditorContext } from "./PageEditor.context";
import { PlusIcon } from "@heroicons/react/24/outline";
import { CellType, FcellFragment } from "../app/apollo/type/graphql";
import { CellTypeKr } from "../data/const";
import { s6 } from "../utils/s4";
import { EditBox } from "./EditBox";
import PageEditBox from "./CommonPageEdit";
import { orNull } from "../utils/localstorage/boolean";

export const LayerAdd = () => {
 const { page, set, coupon } = useContext(PageEditorContext);
 if (!page) return null;

 return (
  <>
   <div>
    <div className=" flex gap-2 flex-wrap flex-col">
     <EditBox
      title={
       <div className="flex items-center gap-2">
        <PlusIcon strokeWidth={2} className="w-4 h-4" />
        레이어 추가
       </div>
      }
     >
      <div className="flex flex-wrap gap-1.5">
       {[
        CellType.PageInfo,
        CellType.Square,
        CellType.Verti,
        CellType.Info,
        CellType.Gallery,
        CellType.ImgList,
        CellType.Map,
        CellType.InfoPageList,
        CellType.Video,
        orNull(!!coupon, CellType.Coupon),
       ]
        ?.filter((v) => v)
        ?.map((cellType) => {
         if ([CellType.Normal].includes(cellType as CellType)) return null;
         return (
          <div
           onClick={() => {
            const isPageInfo = [CellType.PageInfo, CellType.Coupon]?.includes(
             cellType
            );
            const isInfoPageList = cellType === CellType.InfoPageList;

            const cells: FcellFragment[] = [];
            if (isPageInfo) {
             cells.push({
              key: s6(),
              link: "",
              type: cellType as CellType,
              desc: {},
              name: {
               ko: "새항목",
              },
             });
            }

            if (isInfoPageList) {
             cells.push({
              key: s6(),
              link: "",
              type: cellType as CellType,
              desc: {},
              name: {
               ko: "새항목",
              },
              iconLines: [],
             });
            }

            page?.groups?.push({
             cells,
             name: {
              ko: "새 항목",
             },
             key: s6(),
             type: cellType as CellType,
            });

            set();
           }}
           className="text-center whitespace-pre cursor-pointer text-neutral-700 text-xs font-bold flex items-center justify-center w-20 min-w-20 max-w-[80px] max-h-[80px] min-h-20 hover:bg-slate-300  bg-slate-200 h-20 rounded-lg"
          >
           {CellTypeKr[cellType as CellType]}
          </div>
         );
        })}
      </div>
     </EditBox>
    </div>
   </div>
  </>
 );
};
