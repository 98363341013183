import { useEffect } from "react";

export const useIframeHeightSender = () => {
 useEffect(() => {
  const num = setInterval(() => {
   let height;
   const target = window;
   if (!target) return;
   if (height !== window.innerHeight) {
    const height = document.getElementById("App")?.scrollHeight;
    window.parent.postMessage(
     {
      frameHeight: height,
     },
     "*"
    );
   }
  }, 300);

  return () => clearInterval(num);
 }, []);
};
