import {
 XCircleIcon,
 XMarkIcon,
 PlusIcon,
 CalendarIcon,
} from "@heroicons/react/24/outline";
import {
 DragDropContext,
 Droppable,
 Draggable,
 DropResult,
} from "react-beautiful-dnd";
import { CellType, LineType } from "../app/apollo/type/graphql";
import { HeroIconkeys, Icons } from "../data/icon.const";
import { s4, s6 } from "../utils/s4";
import { EditBox } from "./EditBox";
import { TitleOfEditBox } from "./EditBoxTitle";
import { IconTypePairOps } from "./InfoLineEditor";
import { Photo } from "./Photo";
import { withDefaultOp } from "./Select";
import { WithEmpty } from "./WithEmpty";
import { AddressInput } from "./addressInput/AddressInput";
import { BasicSelect } from "./basic/Select";
import { SingleImageUploaderBox } from "./basic/SingleImageUploader.box";
import { BasicButton } from "./basic/button";
import { BasicInput } from "./basic/input";
import { VideoEditor } from "./editors/Video";
import { TextArea } from "./textarea";
import { useContext } from "react";
import { PageEditorContext } from "./PageEditor.context";
import { AppContext } from "../hook/useApp.context";
import { swap } from "./swap";
import { DayPickerInput } from "./DayPicker";
import dayjs from "dayjs";
import GroupEditorTypeRedner from "./GroupEditorTypeRender";

interface IProp {}

export const GroupEditor: React.FC<IProp> = () => {
 const { cellEditModalHook } = useContext(AppContext);
 const { page, set, setKr } = useContext(PageEditorContext);

 const handleDrop = (result: DropResult) => {
  swap(
   page?.groups || ([] as any),
   result.source.index,
   result.destination?.index!,
   false
  );
  set();
 };

 return (
  <DragDropContext
   onDragEnd={(result) => {
    handleDrop(result);
   }}
  >
   <Droppable direction="vertical" droppableId={"ROOT"} type="group">
    {(provided, snapshot) => {
     return (
      <div
       className="flex flex-col gap-2.5"
       {...provided.droppableProps}
       ref={provided.innerRef}
      >
       {page?.groups?.map((g, index) => {
        return (
         <Draggable draggableId={g.key} index={index} key={g.key}>
          {(provided, snapshot) => {
           return (
            <EditBox
             Ref={provided.innerRef}
             {...provided.draggableProps}
             title={
              <TitleOfEditBox
               set={set}
               page={page}
               group={g}
               index={index}
               dragProp={provided.dragHandleProps}
              />
             }
            >
             <GroupEditorTypeRedner g={g} />
            </EditBox>
           );
          }}
         </Draggable>
        );
       })}
       {provided.placeholder}
      </div>
     );
    }}
   </Droppable>
  </DragDropContext>
 );
};
