import { useEffect, useMemo } from "react";
import useWindowEventListener from "../hook/useEventListener";

interface IProp {
 ref: any;
 data: any;
 onResult: (lat: number, lng: number) => void;
}
export const useAddressSearchWithIframe = ({ data, ref, onResult }: IProp) => {
 const onSearch = (str: string) => {
  const iframe = ref?.current as HTMLIFrameElement;

  //   alert("post message" + str);
  try {
   iframe.contentWindow!.postMessage(
    {
     type: "searchAddress",
     str,
     original: window.origin,
    },
    "https://address.hoteltalk.me"
   );
   console.log(iframe.contentWindow?.location.origin);
  } catch {}
 };

 const onMessage = useMemo(() => {
  function eventFn(
   _event: MessageEvent<{ type: "kakao-address"; lat: number; lng: number }>
  ) {
   const event = _event?.data;
   if (event?.type === "kakao-address") {
    console.log("gottttttt2", event);
    if (!event?.lat && !event.lng) {
     alert(
      "해당 주소의 좌표를 찾을 수 없습니다.\n지도 표시에 어려움이 있을 수 있으니 지번주소/도로명 선택을 변경해보세요."
     );
     return;
    }
    onResult(event.lat, event.lng);
   }
  }
  return eventFn;
 }, [!!ref?.current, onResult, data]);
 useWindowEventListener("message", onMessage);

 return {
  onSearch,
 };
};
