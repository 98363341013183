import { createBrowserRouter, Outlet } from "react-router-dom";
import { createRoutesFromElements, Route } from "react-router-dom";
// import { Visit } from "./Visit";
// import { User } from "./User";
import { Login } from "./Login";
import Tostify from "../components/Toastfy";
// import { ItemList } from "./ItemList";
import Page404 from "./404";
import { ProtectedRoute } from "./components/ProtectedRouter";
import DashboardLayout from "./DashboardLayout";
import { Intro } from "./Intro";
import { lazy, Suspense } from "react";
import CustomerLayout from "./ConsumerLayout";
import Main from "./Main";
import Page from "./Page";
import { StoreApp } from "./StoreApp";
import { SuperProtectedRoute } from "./components/SuperProtectedRouter";
import SuperDashboardLayout from "./SuperDashboardLayout";
import UserList from "./UserList";
import CouponList from "./CouponList";
import Map from "./Map";
import CouponPage, { CouponPageWrap, CouponPreviewWrap } from "./CouponPage";
import CouponUseList from "./Visit";

export const SuperAdminRouter = createBrowserRouter(
 createRoutesFromElements(
  <Route path="/" element={<StoreApp />}>
   <Route path="auth" element={<Login />} />
   <Route
    path="/"
    element={
     <SuperProtectedRoute>
      <SuperDashboardLayout />
     </SuperProtectedRoute>
    }
   >
    <Route path="/users" element={<UserList />} />
    <Route path="/coupon" element={<CouponPreviewWrap />} />
    <Route path="/coupons" element={<CouponList />} />
    <Route path="/couponHistory" element={<CouponUseList />} />
   </Route>
  </Route>
 )
);
