import classNames from "classnames";
import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

interface IProp {
 color: string;
 children: ReactNode;
 size?: "S" | "M";
 className?: string;
 innerClasses?: string;
}

export const BasicBadge: React.FC<IProp> = ({
 className,
 color,
 children,
 innerClasses,
 size,
}) => {
 return (
  <div
   className={twMerge(
    classNames(
     `p-1 lg:px-4 bg-${color}-50 rounded-lg justify-center items-center gap-2.5 lg:gap-5 inline-flex`,
     {
      "w-auto h-5 lg:h-5 px-2.5 lg:px-2.5  lg:w-auto": size === "S",
     },
     className
    )
   )}
  >
   <div
    className={twMerge(
     classNames(
      `text-${color}-500 whitespace-nowrap text-xs lg:text-sm `,
      {
       "text-[10px] font-semibold lg:text-[10px]": size === "S",
      },
      innerClasses
     )
    )}
   >
    {children}
   </div>
  </div>
 );
};
