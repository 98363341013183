import Tostify from "../components/Toastfy";
import { Outlet } from "react-router-dom";
import {
 AppContext,
 useAppContext,
 useAppContextProvider,
} from "../hook/useApp.context";
import { useIframCommunicate } from "../hook/useIframCommunication";
import { useLayoutEffect, useMemo } from "react";
import { colorInlineObjectGenerater } from "../utils/cssvar";
import AOS from "aos";
import { CouponUseModal } from "../components/CouponUseModal";
import AlertModal from "../components/systemModal/AlertModal";
import PromptModal from "../components/systemModal/PromptModal";
import UserCreateModal from "../components/UserCreateModal";

export const StoreApp = () => {
 const contextValue = useAppContextProvider({});
 const colorStyle = useMemo(() => {
  return colorInlineObjectGenerater("#264186");
 }, []);

 useLayoutEffect(() => {
  const Colors = Object.entries(colorStyle);
  for (const [key, val] of Colors) {
   document.documentElement.style.setProperty(key, val);
  }
  return () => {};
 }, []);

 useLayoutEffect(() => {
  // SSR 캐시가 완전히 생성되지 않은 상태라면 init이 너무 빠를수도 있다.
  // 위 현상을 방지하려면... 블럭들의 높이가 어느정도 고정되어야한다.
  // 그러나 로드되기전까지 블럭들의 높이를 가늠하긴 어려운 문제다.
  // refresh 되기전까지 offset을 엄청나게 높여두는것도 좋은방법이지 않을까?

  AOS.init({ duration: 1000 });

  const body = document.getElementsByTagName("body");
  const observer = new ResizeObserver((entries) => {
   for (let entry of entries) {
    AOS.refresh();
   }
  });

  if (body.item(0)) {
   observer.observe(body.item(0)!);
  }

  return () => observer.disconnect();
 }, []);

 const context = contextValue;
 const iframHook = useIframCommunicate({
  setStore: context.setPreviewStore as any,
  setCoupon: context.setPreviewCoupon as any,
 });

 useLayoutEffect(() => {
  setTimeout(() => {
   iframHook.postToParent({
    command: "inited",
   });
  }, 100);
 }, []);
 return (
  <AppContext.Provider value={contextValue}>
   <div id="App">
    <Tostify />
    <Outlet />

    {contextValue.userCreateModalHook.isOpen && (
     <UserCreateModal
      key={
       contextValue?.userCreateModalHook?.info?.user?._id + "UserCreateModal"
      }
      modalHook={contextValue.userCreateModalHook}
     />
    )}
    {contextValue.promptModalHook.isOpen && (
     <PromptModal modalHook={contextValue.promptModalHook} />
    )}
    {contextValue.confirmModalHook.isOpen && (
     <PromptModal modalHook={contextValue.promptModalHook} />
    )}
    {contextValue.alertModalHook.isOpen && (
     <AlertModal modalHook={contextValue.alertModalHook} />
    )}
    {/* {contextValue.couponDetailModalHook.isOpen && (
    <CouponModalDetail modalHook={contextValue.couponDetailModalHook} />
   )} */}
    {contextValue.couponUseModalHook?.isOpen && (
     <CouponUseModal modalhook={contextValue.couponUseModalHook} />
    )}
   </div>
  </AppContext.Provider>
 );
};
