export async function getUserLocation() {
 if (!navigator.geolocation) {
  console.log("Geolocation is not supported by your browser");
  return undefined;
 }

 return new Promise<{ latitude: number; longitude: number }>(
  (resolve, reject) => {
   navigator.geolocation.getCurrentPosition(
    (position) => {
     const { latitude, longitude } = position.coords;
     resolve({ latitude, longitude });
    },
    (error) => {
     // Either user denied the permission or another error occurred
     console.warn(`ERROR(${error.code}): ${error.message}`);
     resolve({
      latitude: 0,
      longitude: 0,
     }); // Resolve with undefined on error to match your requirement
    }
   );
  }
 );
}
