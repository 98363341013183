import { CellType, FgroupFragment } from "../app/apollo/type/graphql";
import { TUseModal } from "../hook/useModal";
import Modal from "./Modal";
import { BasicInput } from "./basic/input";
import { s4 } from "../utils/s4";
import { useInput } from "../hook/useInput";
import { BasicButton } from "./basic/button";

// Square: "Square",
// Info:  "Info",
// Verti: "Verti",
// PageBlock: "PageBlock",
// PageInfo: "PageInfo",

export interface IGroupEditInfo {
 type: CellType;
 group?: FgroupFragment;
 onSubmit: (group: FgroupFragment) => void;
}

interface IProp {
 modalhook: TUseModal<IGroupEditInfo>;
}

export const GroupEditModal: React.FC<IProp> = ({ modalhook }) => {
 const defaultGroup = modalhook.info?.group;

 const { name, ...overrideGroup } = defaultGroup || ({} as any);

 const nameHook = useInput(defaultGroup?.name?.ko || "");

 const nextGroup: FgroupFragment = {
  key: defaultGroup?.key || s4(),
  name: {
   ...defaultGroup?.name,
   ko: nameHook?.value,
  },
  cells: [],
  type: modalhook?.info?.type || CellType.Normal,
  ...overrideGroup,
 };

 return (
  <Modal modalHook={modalhook}>
   <div className="flex flex-col gap-2">
    <BasicInput
     label="라벨"
     placeholder="타이틀을 입력해주세요"
     {...nameHook}
    />
    <BasicButton
     onClick={() => {
      modalhook.info?.onSubmit(nextGroup);
      modalhook.close();
     }}
    >
     완료하기
    </BasicButton>
   </div>
  </Modal>
 );
};

export default GroupEditModal;
