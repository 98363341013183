import { useLocation } from "react-router-dom";
import { useModal } from "./useModal";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import {
 EventType,
 FcouponFragment,
 FstoreFragment,
 RecordInput,
 useAdminQuery,
 useChatRoomUpdateMutation,
 useStoreFindByIdQuery,
 useStoreUpdateMutation,
} from "../app/apollo/type/graphql";
import { IPromptModalInfo } from "../components/systemModal/PromptModal";
import { IConfirmModalInfo } from "../components/systemModal/ConfirmModal";
import { IAlertModalInfo } from "../components/systemModal/AlertModal";
import { getFromUrl } from "../utils/getFromUrl";
import { CACHE, LangSet, getLangCode } from "../data/lang.static";
import {
 LocalStorage,
 iamTopWindow,
} from "../utils/localstorage/StorageAddition";
import { IRoomNumberPopModalInfo } from "../components/RoomNumberPop.modal";
import { useUpdateComponent } from "./useUpdateComponent";
import { useIframCommunicate } from "./useIframCommunication";
import { IS_DEV } from "../data/env.short";
import { ICellEditInfo } from "../components/CellEditModal.model";
import { IGroupEditInfo } from "../components/GroupEditModal.modal";
import { IPageEditInfo } from "../components/PageEditModal.modal copy";
import { ICouponUseModalInfo } from "../components/CouponUseModal";
import { IUserCreateModalInfo } from "../components/UserCreateModal";
import { IImageViewModalInfo } from "../components/ImagesView.modal";
import { useRecordCreateMutation } from "../app/apollo/type/graphql";
import ReactGA from "react-ga4";
export type TUseAppContext = ReturnType<typeof useAppContextProvider>;

const subdomain = window?.location.host?.replace(".hoteltalk.me", "");

export const useAppContextProvider = ({ ...props }) => {
 const [recordCreateMu] = useRecordCreateMutation();
 const { upKey, updateComponent } = useUpdateComponent();
 const [previewStore, setPreviewStore] = useState<FstoreFragment>();
 const [previewCoupon, setPreviewCoupon] = useState<FcouponFragment>();
 const [updateMu] = useChatRoomUpdateMutation();
 const location = useLocation();

 const resetLang = (langcode: string) => {
  if (!iamTopWindow) {
   alert("미리보기에서는 언어변경을 하실 수 없습니다.");
   return;
  }
  LocalStorage.set("lang", langcode);
  CACHE.langCode = langcode;
  const roomNum = LocalStorage.getItem("room") || "";
  const mid = LocalStorage.getItem("mid") || "";
  const key = roomNum + mid;
  if (roomNum && mid) {
   updateMu({
    variables: {
     roomkey: key,
     input: {
      customerLang: langcode,
     },
    },
   });
  }
  LangSet(langcode);
  updateComponent();
 };

 const devstoreId =
  // "citadines" ||
  // "hospital" ||
  "phoenix" ||
  // "cn" ||
  // "jejustonepark" ||
  // "ecoland" ||
  // "caffeinated" ||
  // "mice" ||
  // "busan1" ||
  // "jeju" ||
  "busan" ||
  "psyburger" ||
  "hotel2";
 const domainPart = IS_DEV ? devstoreId! : subdomain!;
 const {
  loading: authLoading,
  data: adminResult,
  refetch: refetchAdmin,
 } = useAdminQuery({
  fetchPolicy: "no-cache",
  variables: {
   domain: domainPart,
  },
  //   skip: !hasAdminCookie,
 });

 const { data, loading, called } = useStoreFindByIdQuery({
  fetchPolicy: "cache-first",
  variables: {
   storeId: domainPart,
  },
 });

 const [isGAInitialized, setIsGAInitialized] = useState(false);

 const sendRecord = (recordInput: Partial<RecordInput>) => {
  if (isGAInitialized && recordInput.event === EventType.Click) {
   ReactGA.event({
    category: "User",
    action: "Button Click",
    label: recordInput.cellName || "",
   });
  }
  recordCreateMu({
   variables: {
    input: {
     bookerId: undefined,
     duration: undefined,
     lang: langCode,
     path: location.pathname,
     storeId: store?._id,
     ...recordInput,
    },
   },
  });
 };

 useEffect(() => {
  if (data?.StoreFindById?._id)
   LocalStorage.setItem("sid", data?.StoreFindById?._id);
 }, [data?.StoreFindById?._id]);

 const store = previewStore || data?.StoreFindById;

 console.log("@@@store", store);

 const [search, setSearch] = useState("");

 const roomNumberPOpModal = useModal<IRoomNumberPopModalInfo>();
 const promptModalHook = useModal<IPromptModalInfo>();
 const userCreateModalHook = useModal<IUserCreateModalInfo>();
 const confirmModalHook = useModal<IConfirmModalInfo>();
 const alertModalHook = useModal<IAlertModalInfo>();
 //  const couponDetailModalHook = useModal<IDetailModalInfo>();
 const pageEditModalHook = useModal<IPageEditInfo>();
 const cellEditModalHook = useModal<ICellEditInfo>();
 const groupEditModalHook = useModal<IGroupEditInfo>();
 const couponUseModalHook = useModal<ICouponUseModalInfo>();
 const imgViewModalHook = useModal<IImageViewModalInfo>();

 const [updateKey, setUpdateKey] = useState(0);

 const admin = adminResult?.Admin;
 const redirectToAuth = !authLoading && !admin;

 useEffect(() => {
  if (!store) return;
  const target = document.getElementById("Splash");
  if (!target) return;
  target?.classList?.add("fade-out");
  target.style.opacity = "1";
  target.addEventListener("animationend", () => {
   // Option 1: Set pointer-events to none after animation
   // element.style.pointerEvents = 'none';

   // Option 2: Set display to none after animation
   target.style.display = "none";
  });
 }, [!!store]);

 const langCode = CACHE.langCode || getLangCode(navigator.language);

 const langSearch = <T>(Obj: T): T => {
  if (Array.isArray(Obj)) {
   return Obj.map((obj) => {
    return langSearch(obj);
   }) as any;
  }
  return Object.fromEntries(
   Object.entries(Obj as any).map(([key, value]) => {
    const newLangKey = key.replace("_ko__", `_${langCode}__`);
    return [newLangKey, value] as any;
   })
  ) as any;
 };

 useEffect(() => {
  LocalStorage.setItem("lang", langCode);
  CACHE.langCode = langCode;
  LangSet(langCode!);
  setUpdateKey(updateKey + 1);
 }, [langCode]);

 //lang for dynamic
 const lang = (obj: any) => {
  console.log("langCode??", langCode);
  if (!obj) return "";
  return obj[langCode];
 };

 const page = store?.pages?.find((p) => p.path === location?.pathname);

 useLayoutEffect(() => {
  if (!isGAInitialized) return;
  // 페이지가 변경될 때 두 개의 측정 ID에 페이지 뷰 전송
  ReactGA.send({ hitType: "pageview", page: page?.path || location.pathname });
  sendRecord({
   event: EventType.View,
   cellName: page?.name?.ko || window.document?.title,
  });
 }, [isGAInitialized, location]);

 useEffect(() => {
  if (!called || loading || isGAInitialized) return;
  ReactGA.initialize(
   [
    {
     trackingId: "G-CBMC6YK9BM",
    },
    {
     trackingId: store?.gaCode || "",
    },
   ]?.filter((v) => v.trackingId)
  );
  setIsGAInitialized(true); // 한 번만 초기화
 }, [data?.StoreFindById, loading, called, isGAInitialized]);

 return {
  page,
  loading,
  langCode,
  lang,
  store,
  admin,
  search,
  setSearch,
  imgViewModalHook,
  pageEditModalHook,
  cellEditModalHook,
  groupEditModalHook,
  userCreateModalHook,
  previewCoupon,
  sendRecord,
  previewStore,
  setPreviewCoupon,
  setPreviewStore,
  updateComponent,
  refetchAdmin,
  authLoading,
  redirectToAuth,
  promptModalHook,
  confirmModalHook,
  alertModalHook,
  couponUseModalHook,
  // couponDetailModalHook,
  roomNumberPOpModal,
  resetLang,
 };
};

export const AppContext = React.createContext<TUseAppContext>({} as any);
export const useAppContext = () => {
 return useContext(AppContext);
};
