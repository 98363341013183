import { useContext, useEffect } from "react";
import { FcellFragment, FcellFragmentDoc } from "../app/apollo/type/graphql";
import { useInput } from "../hook/useInput";
import { TUseModal } from "../hook/useModal";
import { useSingleUpload } from "../hook/useSingleUpload";
import Modal from "./Modal";
import { NDigitInput } from "./basic/NDigitInput";
import { SingleImageUploader } from "./basic/SingleImageUploader";
import { BasicInput } from "./basic/input";
import { AppContext } from "../hook/useApp.context";
import { BasicButton } from "./basic/Button.ratio";
import { L } from "../data/lang.static";

export interface IRoomNumberPopModalInfo {
 onSubmit: (roomNumber: string) => void;
}

interface IProp {
 modalhook: TUseModal<IRoomNumberPopModalInfo>;
}

export const RoomNumberPopModal: React.FC<IProp> = ({ modalhook }) => {
 const numberHook = useInput("");
 const { lang } = useContext(AppContext);

 const buttonDisabeld = numberHook?.value?.length !== 4;

 return (
  <Modal
   bodyclasses="p-[2.78vw] xs:p-[9.2px] w-[60vw] xs:w-[274.56px]"
   outsideClickClose={false}
   modalHook={modalhook}
  >
   <div className="flex-col mx-auto justify-center items-center gap-[2.78vw] xs:gap-[12.8px] flex w-fit">
    <div
     style={{
      lineHeight: 1.3,
     }}
     className="w-[53.33vw] xs:w-[245.76px] text-gray-800 text-[3.33vw] xs:text-[14.4px] font-normal font-['Pretendard'] leading-none"
    >
     {L["RoomPopIntro"]}
     <br />
     {L["RoomPopIntroExample"]}
    </div>
    <NDigitInput numDigits={4} {...numberHook} />
    <BasicButton
     className="text-[4.44vw] xs:text-[19.2px]"
     disabled={buttonDisabeld}
     onClick={() => {
      modalhook.info?.onSubmit?.(numberHook?.value);
      modalhook.close();
     }}
     variant="flat"
     color="primary"
    >
     {L["Submit"]}
    </BasicButton>
   </div>
  </Modal>
 );
};
