import { ReactNode } from "react";
import { isEmpty } from "../utils/isEmpty";

interface IEmptyTextProp {
 empty?: boolean | any;
 msg?: string;
 Empty: ReactNode;
 emptyRemove?: boolean;
 children?: any;
}

export const WithEmpty: React.FC<IEmptyTextProp> = ({
 Empty,
 empty,
 msg,
 emptyRemove,
 children,
}) => {
 if (typeof empty !== "boolean") {
  empty = isEmpty(empty);
 }
 if (empty && emptyRemove) return null;
 if (!empty) return children as any;
 return Empty;
};
