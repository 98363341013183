export const dataURLtoFile = (dataurl: string, filename: string) => {
  var arr = dataurl.split(','),
    // @ts-ignore
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export default dataURLtoFile;

//Usage example:
// var file = dataURLtoFile('data:text/plain;base64,aGVsbG8gd29ybGQ=', 'hello.txt');
// console.log(file);

async function urlToFile(url:string, filename:string, mimeType:string) {
  const response = await fetch(url);
  if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
  }
  const blob = await response.blob();
  return new File([blob], filename, { type: mimeType });
}

export  function getFileDataUrl(url:string):Promise<string> {
  return new Promise((resolve, reject) => {
      fetch(url)
          .then(response => {
              if (!response.ok) {
                  throw new Error('Network response was not ok');
              }
              return response.blob();
          })
          .then(blob => {
              const reader = new FileReader();
              reader.onloadend = () => resolve(reader.result as string);
              reader.onerror = error => reject(error);
              reader.readAsDataURL(blob);
          })
          .catch(error => {
              console.error('There has been a problem with your fetch operation:', error);
              reject(error);
          });
  });
}
