import { useContext } from "react";
import { FfileFragment } from "../app/apollo/type/graphql";
import { EditBox } from "./EditBox";
import { SingleImageUploaderBox } from "./basic/SingleImageUploader.box";
import { BasicInput } from "./basic/input";
import { useAppEditContext } from "../hook/useAppEdit.context";

interface IProp {
 title: JSX.Element | string;
 value?: FfileFragment | undefined | null;
 onChange: (value: FfileFragment | undefined) => void;
}

export const EditImageBox: React.FC<IProp> = ({ title, value, onChange }) => {
 return (
  <EditBox title={<>{title}</>}>
   <SingleImageUploaderBox
    setFile={onChange}
    Title="인트로 이미지 변경"
    file={value as FfileFragment}
   />
  </EditBox>
 );
};
