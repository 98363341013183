import { Fragment, useContext } from "react";
import { AppEditContext, useAppEditContext } from "../hook/useAppEdit.context";
import { FpageFragment } from "../app/apollo/type/graphql";
import { DevJsonViewer } from "./devTools/DevJson";
import classNames from "classnames";
import { PencilIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { twMerge } from "tailwind-merge";
import { Popover, Transition } from "@headlessui/react";
import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid";
import { AppContext } from "../hook/useApp.context";
interface IProp {
 path: string;
 name: string;
 index?: number;
 onClickEdit?: () => void;
 onDelete?: () => void;
 page?: FpageFragment;
}

const undeleteAblePath = ["/", "/main", "/chat"];
export const PageCell: React.FC<IProp> = ({
 name,
 path,
 onDelete,
 index,
 page,
 onClickEdit,
}) => {
 const { pageEditModalHook } = useContext(AppContext);
 const { editingPath, setEditingPath, store } = useContext(AppEditContext);
 const unDeleteable = undeleteAblePath?.includes(path);
 const editContext = useAppEditContext();

 return (
  <div
   onClick={() => {
    setEditingPath(path);
   }}
   className={twMerge(
    classNames(
     "px-2 rouned border  rounded-lg cursor-pointer  hover:bg-slate-50  flex items-center justify-center",
     {
      "bg-primary-500 hover:bg-primary-600  text-white": editingPath === path,
     }
    )
   )}
  >
   <div className="text-sm flex w-full px-2.5 py-2 items-center justify-between">
    {name}

    {!["/", "/chat", "/main"]?.includes(path) && (
     <Popover
      onClick={(e) => {
       e.preventDefault();
       e.stopPropagation();
      }}
      className={" flex items-center relative"}
     >
      <Popover.Button>
       <EllipsisHorizontalIcon className="w-5 h-5 hover:bg-neutral-100 rounded " />
      </Popover.Button>
      <Transition
       as={Fragment}
       enter="transition ease-out duration-200"
       enterFrom="opacity-0 translate-y-1"
       enterTo="opacity-100 translate-y-0"
       leave="transition ease-in duration-150"
       leaveFrom="opacity-100 translate-y-0"
       leaveTo="opacity-0 translate-y-1"
      >
       <Popover.Panel>
        <ul className="bg-white p-2  absolute z-20 right-2 border rounded-lg text-center whitespace-nowrap text-stone-900 shadow-lg">
         <li
          onClick={() => {
           pageEditModalHook.openImmediate({
            page,
           });
          }}
          className="p-2 rounded hover:bg-neutral-50"
         >
          수정하기
         </li>
         <li
          onClick={() => {
           store?.pages?.splice(index || 0, 1);
           editContext.set();
          }}
          className="p-2 rounded hover:bg-neutral-50"
         >
          삭제하기
         </li>
         <li
          onClick={() => {
           const deleted = (store?.pages as any)[index || 0];
           store?.pages?.splice(index || 0, 1);
           const main = store?.pages[0];
           store?.pages?.splice(0, 1);
           store!.pages = [main, deleted as any, ...(store?.pages || [])];
           editContext.set();
          }}
          className="p-2 rounded hover:bg-neutral-50 w-full"
         >
          맨위로보내기
         </li>
        </ul>
       </Popover.Panel>
      </Transition>
     </Popover>
    )}
    {/* 
    {!unDeleteable && (
     <XMarkIcon
      className="w-4 h-4"
      onClick={() => {
       onDelete?.();
      }}
     />
    )} */}
   </div>
  </div>
 );
};
