import { AppContext, useAppContextProvider } from "./hook/useApp.context";
import { RouterProvider } from "react-router-dom";
import { Router } from "./page/router";
import PromptModal from "./components/systemModal/PromptModal";
import AlertModal from "./components/systemModal/AlertModal";
import { useMemo, useLayoutEffect, useEffect } from "react";
import { colorInlineObjectGenerater } from "./utils/cssvar";
import "aos/dist/aos.css";
import "swiper/css";
import "swiper/css/pagination";
import { SuperAdminRouter } from "./page/router.superadmin";
import { CouponUseModal } from "./components/CouponUseModal";
import UserCreateModal from "./components/UserCreateModal";
import { isSuperAdminMode } from "./data/const";

export const App = () => {
 return (
  <RouterProvider router={isSuperAdminMode ? SuperAdminRouter : Router} />
 );
};
