import { gql } from "@apollo/client";
import { F_COLLECTION_DATA_INTERFACE, F_PAGEINFO } from "./common";

export const F_BOOKER = gql`
 fragment Fbooker on Booker {
  ...FcollectionDataInterface
  lang
  bookerId
  machineId
  storeId
  fields {
   require
   type
   key
   value
   label
   options
  }
 }
 ${F_COLLECTION_DATA_INTERFACE}
`;

export const BOOKER_SIGNUP = gql`
 mutation bookerSignup($input: BookerInput!) {
  BookerSignup(input: $input)
 }
`;

export const BOOKER_LIST = gql`
 query bookerList(
  $sort: [_BookerSort!]
  $filter: _BookerFilter
  $pagingInput: OffsetPagingInput!
 ) {
  BookerList(sort: $sort, pagingInput: $pagingInput, filter: $filter) {
   pageInfo {
    ...FoffsetPagingInfo
   }
   items {
    ...Fbooker
   }
  }
 }
 ${F_BOOKER}
 ${F_PAGEINFO}
`;
