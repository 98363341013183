import { useState } from "react";

export interface IusePagination {
    page: number;
    setPage: (page: number) => void;
    pageItemCount: number;
    pageCount?: number;
}

// 투글 훅
export function usePagination(
    defaultPage: number,
    pageItemCount: number
): IusePagination {
    const [page, inSetPage] = useState(defaultPage);

    const setPage = (foo: number) => {
        inSetPage(foo);
    };

    return { page, setPage, pageItemCount };
}
