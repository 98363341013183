import classNames from "classnames";
import { ChangeEvent } from "react";
import { twMerge } from "tailwind-merge";

interface IProp
 extends Omit<
  React.HTMLProps<HTMLTextAreaElement>,
  "size" | "label" | "onChange"
 > {
 value?: number | string;
 onChange?: (value: any, e: ChangeEvent<HTMLTextAreaElement>) => void;
 label?: string;
}

export const TextArea: React.FC<IProp> = ({
 value,
 onChange,
 className,
 label,
 ...props
}) => {
 const labelClassnames = twMerge(
  classNames("mb-1 text-neutral-500  text-sm font-light", {})
 );

 return (
  <div className="w-full">
   {label && <p className={labelClassnames}>{label}</p>}
   <textarea
    className={classNames(
     " h-40 p-3 w-full  border border-neutral-200  text-sm placeholder-neutral-200 focus:border-indigo-700 outline-none rounded",
     className
    )}
    value={value}
    onChange={(e) => {
     onChange && onChange(e.currentTarget.value, e);
    }}
    {...props}
   />
  </div>
 );
};
