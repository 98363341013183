import { UserRole, useSignInMutation } from "../app/apollo/type/graphql";
import { Button } from "../components/button";
import Dev from "../components/devTools/Dev";
import { Input } from "../components/input";
import { isSuperAdminMode } from "../data/const";
import { useSignin } from "../hook/useSignin";
const Logo = (
 <svg
  color="ffffff"
  width="144"
  height="23"
  viewBox="0 0 144 23"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
 >
  <path
   d="M129.496 22.4092H129.436C128.996 22.4092 128.446 22.0092 127.786 21.2092C127.126 20.3892 126.796 19.6792 126.796 19.0792C126.796 18.4592 127.376 15.7192 128.536 10.8592C129.716 5.99922 130.306 2.92922 130.306 1.64922C130.306 0.849219 130.596 0.449219 131.176 0.449219C131.656 0.449219 132.236 0.929219 132.916 1.88922C133.596 2.82922 133.936 3.72922 133.936 4.58922C133.936 5.10922 133.676 6.43922 133.156 8.57922C134.796 6.39922 136.336 4.57922 137.776 3.11922C139.216 1.63922 140.196 0.89922 140.716 0.89922C141.256 0.89922 141.796 1.12922 142.336 1.58922C142.876 2.02922 143.146 2.41922 143.146 2.75922C143.146 3.07922 142.636 3.71922 141.616 4.67922C140.596 5.63922 139.336 6.85922 137.836 8.33922C136.336 9.81922 135.176 11.1992 134.356 12.4792C134.356 14.0192 135.246 15.5692 137.026 17.1292C137.566 17.5892 138.286 18.0692 139.186 18.5692C140.086 19.0492 140.786 19.4692 141.286 19.8292C141.806 20.1692 142.066 20.5192 142.066 20.8792C142.066 21.8992 141.346 22.4092 139.906 22.4092C138.466 22.4092 136.916 21.8292 135.256 20.6692C133.596 19.4892 132.356 17.9292 131.536 15.9892C131.016 17.4692 130.696 18.5492 130.576 19.2292C130.456 19.8892 130.366 20.3292 130.306 20.5492C130.266 20.7692 130.226 20.9492 130.186 21.0892C130.146 21.2092 130.106 21.3492 130.066 21.5092C130.026 21.6692 129.986 21.7992 129.946 21.8992C129.906 21.9792 129.866 22.0592 129.826 22.1392C129.706 22.3192 129.596 22.4092 129.496 22.4092Z"
   fill="currentColor"
  />
  <path
   d="M117.589 0C118.089 0 118.699 0.33 119.419 0.99C120.139 1.65 120.499 2.33 120.499 3.03C120.499 4.95 119.509 8.67 117.529 14.19C116.849 16.13 116.509 17.48 116.509 18.24C116.509 18.34 116.529 18.4 116.569 18.42C116.609 18.44 116.979 18.45 117.679 18.45C118.379 18.45 119.339 18.35 120.559 18.15C121.799 17.93 122.799 17.72 123.559 17.52C124.339 17.32 124.799 17.22 124.939 17.22C125.439 17.22 125.689 17.54 125.689 18.18C125.689 19.44 124.729 20.46 122.809 21.24C120.909 22 118.699 22.38 116.179 22.38C115.199 22.38 114.299 22.02 113.479 21.3C112.679 20.56 112.279 19.75 112.279 18.87C112.279 17.99 113.019 15.15 114.499 10.35C115.979 5.53 116.719 2.41 116.719 0.99C116.719 0.33 117.009 0 117.589 0Z"
   fill="currentColor"
  />
  <path
   d="M108.922 11.1605C109.722 11.1605 110.292 11.2405 110.632 11.4005C110.992 11.5405 111.172 11.8105 111.172 12.2105C111.172 12.7505 110.302 13.3005 108.562 13.8605C108.602 15.3805 108.622 16.8405 108.622 18.2405C108.622 19.6205 108.512 20.6605 108.292 21.3605C108.072 22.0405 107.722 22.3805 107.242 22.3805C106.762 22.3805 106.382 22.1405 106.102 21.6605C105.842 21.1605 105.622 20.3505 105.442 19.2305C105.282 18.1105 105.112 16.6305 104.932 14.7905C102.892 15.2505 101.422 15.6205 100.522 15.9005C98.9623 20.2205 97.9223 22.3805 97.4023 22.3805C96.9823 22.3805 96.4223 22.1005 95.7223 21.5405C95.0423 20.9805 94.7023 20.4005 94.7023 19.8005C94.7023 19.4605 95.0923 18.4005 95.8723 16.6205C95.5323 16.4005 95.2323 16.0905 94.9723 15.6905C94.7323 15.2705 94.6123 14.8705 94.6123 14.4905C94.6123 13.8305 95.6023 13.2005 97.5823 12.6005C97.7423 12.2005 97.9023 11.7505 98.0623 11.2505C98.2423 10.7305 98.6723 9.53047 99.3523 7.65047C100.052 5.77047 100.602 4.20047 101.002 2.94047C101.182 2.34047 101.802 1.78047 102.862 1.26047C103.942 0.740468 104.782 0.480469 105.382 0.480469C106.082 0.480469 106.702 1.45047 107.242 3.39047C107.802 5.33047 108.192 7.92047 108.412 11.1605H108.922ZM101.992 11.6705C102.592 11.5905 103.462 11.4905 104.602 11.3705C104.542 10.7905 104.442 9.80047 104.302 8.40047C104.182 7.00047 104.092 5.99047 104.032 5.37047C103.692 6.49047 103.012 8.59047 101.992 11.6705Z"
   fill="currentColor"
  />
  <path
   d="M86.184 4.71141C84.024 4.83141 82.844 4.89141 82.644 4.89141C82.164 4.89141 81.684 4.60141 81.204 4.02141C80.724 3.42141 80.484 2.80141 80.484 2.16141C80.484 1.18141 83.334 0.691406 89.034 0.691406C91.954 0.691406 94.104 0.991407 95.484 1.59141C96.864 2.19141 97.554 2.92141 97.554 3.78141C97.554 4.04141 97.404 4.29141 97.104 4.53141C96.804 4.75141 96.424 4.86141 95.964 4.86141C95.504 4.86141 94.664 4.83141 93.444 4.77141C92.224 4.71141 91.114 4.67141 90.114 4.65141C90.194 4.89141 90.234 5.17141 90.234 5.49141C90.234 5.81141 90.114 6.44141 89.874 7.38141C89.654 8.32141 89.334 9.63141 88.914 11.3114C88.494 12.9714 88.034 14.9914 87.534 17.3714C87.034 19.7314 86.684 21.1414 86.484 21.6014C86.304 22.0414 86.124 22.2614 85.944 22.2614C85.524 22.2614 84.994 21.8814 84.354 21.1214C83.734 20.3414 83.424 19.7114 83.424 19.2314C83.424 18.7514 83.834 16.7714 84.654 13.2914C85.474 9.79141 85.984 6.93141 86.184 4.71141Z"
   fill="currentColor"
  />
  <path
   d="M70.497 0C70.997 0 71.607 0.33 72.327 0.99C73.047 1.65 73.407 2.33 73.407 3.03C73.407 4.95 72.417 8.67 70.437 14.19C69.757 16.13 69.417 17.48 69.417 18.24C69.417 18.34 69.437 18.4 69.477 18.42C69.517 18.44 69.887 18.45 70.587 18.45C71.287 18.45 72.247 18.35 73.467 18.15C74.707 17.93 75.707 17.72 76.467 17.52C77.247 17.32 77.707 17.22 77.847 17.22C78.347 17.22 78.597 17.54 78.597 18.18C78.597 19.44 77.637 20.46 75.717 21.24C73.817 22 71.607 22.38 69.087 22.38C68.107 22.38 67.207 22.02 66.387 21.3C65.587 20.56 65.187 19.75 65.187 18.87C65.187 17.99 65.927 15.15 67.407 10.35C68.887 5.53 69.627 2.41 69.627 0.99C69.627 0.33 69.917 0 70.497 0Z"
   fill="currentColor"
  />
  <path
   d="M56.3736 9.81094C57.4336 9.81094 58.5736 9.76094 59.7936 9.66094C61.0136 9.54094 61.7636 9.48094 62.0436 9.48094C62.3436 9.48094 62.5736 9.55094 62.7336 9.69094C62.9136 9.83094 63.0036 10.0009 63.0036 10.2009C63.0036 11.0809 62.1736 11.7809 60.5136 12.3009C58.8536 12.8209 57.0836 13.1209 55.2036 13.2009C54.4236 15.6209 54.0336 17.1509 54.0336 17.7909C54.0336 18.1309 54.2036 18.3009 54.5436 18.3009C56.8236 18.3009 59.1736 18.0009 61.5936 17.4009C62.4136 17.1809 62.8836 17.0709 63.0036 17.0709C63.3836 17.0709 63.5736 17.3409 63.5736 17.8809C63.5736 18.6009 63.0436 19.3009 61.9836 19.9809C60.9236 20.6609 59.5936 21.2009 57.9936 21.6009C56.3936 22.0009 54.8236 22.2009 53.2836 22.2009C52.3636 22.2009 51.5336 21.8609 50.7936 21.1809C50.0536 20.4809 49.6836 19.6709 49.6836 18.7509C49.6836 17.8109 50.1936 15.4709 51.2136 11.7309C52.2536 7.99094 52.8036 5.17094 52.8636 3.27094C52.8436 3.21094 52.8336 3.09094 52.8336 2.91094C52.8336 2.63094 52.8736 2.41094 52.9536 2.25094C53.1736 1.69094 53.7836 1.34094 54.7836 1.20094C55.7836 1.04094 57.5836 0.960938 60.1836 0.960938C64.5036 0.960938 66.6636 1.44094 66.6636 2.40094C66.6636 3.02094 66.1336 3.45094 65.0736 3.69094C64.3536 3.85094 63.3636 4.00094 62.1036 4.14094C60.1636 4.36094 58.4336 4.68094 56.9136 5.10094C56.9136 6.46094 56.6736 8.03094 56.1936 9.81094H56.3736Z"
   fill="currentColor"
  />
  <path
   d="M41.4653 4.71141C39.3053 4.83141 38.1253 4.89141 37.9253 4.89141C37.4453 4.89141 36.9653 4.60141 36.4853 4.02141C36.0053 3.42141 35.7653 2.80141 35.7653 2.16141C35.7653 1.18141 38.6153 0.691406 44.3153 0.691406C47.2353 0.691406 49.3853 0.991407 50.7653 1.59141C52.1453 2.19141 52.8353 2.92141 52.8353 3.78141C52.8353 4.04141 52.6853 4.29141 52.3853 4.53141C52.0853 4.75141 51.7053 4.86141 51.2453 4.86141C50.7853 4.86141 49.9453 4.83141 48.7253 4.77141C47.5053 4.71141 46.3953 4.67141 45.3953 4.65141C45.4753 4.89141 45.5153 5.17141 45.5153 5.49141C45.5153 5.81141 45.3953 6.44141 45.1553 7.38141C44.9353 8.32141 44.6153 9.63141 44.1953 11.3114C43.7753 12.9714 43.3153 14.9914 42.8153 17.3714C42.3153 19.7314 41.9653 21.1414 41.7653 21.6014C41.5853 22.0414 41.4053 22.2614 41.2253 22.2614C40.8053 22.2614 40.2753 21.8814 39.6353 21.1214C39.0153 20.3414 38.7053 19.7114 38.7053 19.2314C38.7053 18.7514 39.1153 16.7714 39.9353 13.2914C40.7553 9.79141 41.2653 6.93141 41.4653 4.71141Z"
   fill="currentColor"
  />
  <path
   d="M26.671 17.2506C27.831 16.2106 28.841 14.9106 29.701 13.3506C30.561 11.7706 30.991 10.2606 30.991 8.82063C30.991 7.38062 30.811 6.27063 30.451 5.49063C30.091 4.71063 29.591 4.32062 28.951 4.32062C28.311 4.32062 27.471 4.90062 26.431 6.06063C25.411 7.20063 24.481 8.70062 23.641 10.5606C22.821 12.4206 22.411 14.2006 22.411 15.9006C22.411 16.7606 22.531 17.4606 22.771 18.0006C23.011 18.5206 23.311 18.7806 23.671 18.7806C24.511 18.7806 25.511 18.2706 26.671 17.2506ZM23.701 22.4106C22.221 22.4106 20.901 21.6906 19.741 20.2506C18.581 18.7906 18.001 17.3406 18.001 15.9006C18.001 14.0606 18.351 12.1806 19.051 10.2606C19.751 8.32063 20.621 6.65062 21.661 5.25063C22.721 3.83063 23.881 2.67063 25.141 1.77063C26.401 0.850625 27.561 0.390625 28.621 0.390625C30.561 0.390625 32.151 1.22063 33.391 2.88063C34.631 4.52063 35.251 6.37063 35.251 8.43062C35.251 10.4906 34.731 12.5806 33.691 14.7006C32.651 16.8006 31.211 18.6106 29.371 20.1306C27.551 21.6506 25.661 22.4106 23.701 22.4106Z"
   fill="currentColor"
  />
  <path
   d="M3.41 22.4092C2.99 22.4092 2.4 21.9392 1.64 20.9992C0.88 20.0392 0.5 19.3092 0.5 18.8092C0.5 18.2892 0.8 16.7392 1.4 14.1592C0.92 13.6792 0.68 13.0792 0.68 12.3592C0.68 11.9592 1.16 11.5592 2.12 11.1592C3.38 6.03922 4.01 2.79922 4.01 1.43922C4.01 0.779219 4.3 0.449219 4.88 0.449219C5.36 0.449219 5.98 0.929219 6.74 1.88922C7.52 2.84922 7.91 3.74922 7.91 4.58922C7.91 5.12922 7.55 6.92922 6.83 9.98922C8.55 9.68922 10.26 9.50922 11.96 9.44922C12.92 5.42922 13.4 2.75922 13.4 1.43922C13.4 0.779219 13.69 0.449219 14.27 0.449219C14.75 0.449219 15.37 0.929219 16.13 1.88922C16.91 2.84922 17.3 3.74922 17.3 4.58922C17.3 5.00922 16.91 6.72922 16.13 9.74922C17.13 9.98922 17.63 10.3792 17.63 10.9192C17.63 11.5392 16.89 12.0292 15.41 12.3892C14.69 15.1492 14.22 16.9792 14 17.8792C13.78 18.7592 13.61 19.4292 13.49 19.8892C13.39 20.3292 13.28 20.7592 13.16 21.1792C12.94 21.9992 12.73 22.4092 12.53 22.4092C12.05 22.4092 11.47 22.0292 10.79 21.2692C10.13 20.5092 9.8 19.7492 9.8 18.9892C9.8 18.5492 10.22 16.5992 11.06 13.1392C8.76 13.5192 6.99 13.8692 5.75 14.1892C5.13 16.5692 4.72 18.2292 4.52 19.1692C4.32 20.0892 4.16 20.7592 4.04 21.1792C3.82 21.9992 3.61 22.4092 3.41 22.4092Z"
   fill="currentColor"
  />
 </svg>
);
export const Login = () => {
 const { handleSignIn, nameHook, passwordHook } = useSignin(
  isSuperAdminMode ? UserRole.Superadmin : UserRole.Provider
 );
 return (
  <section className="bg-gray-50 dark:bg-gray-900 flex items-center justify-center ">
   <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
    <a
     href="#"
     className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
    >
     {Logo}
    </a>
    <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
     <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
      <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
       {isSuperAdminMode ? "본사 관리자 인증" : "관리자 인증"}
      </h1>
      <form
       onSubmit={(e) => {
        e.preventDefault();
        handleSignIn();
       }}
       className="space-y-4 md:space-y-6"
       action="#"
      >
       <div>
        <Dev>피닉스 아일랜드 [HOTELTALK]</Dev>
        <Input placeholder="관리자명" {...nameHook} />
       </div>
       <div>
        <Input placeholder="패스워드" {...passwordHook} type="password" />
       </div>

       <Button
        className="w-full text-white bg-blue-800 hover:bg-indigo-700 focus:ring-4 focus:outline-none focus:ring-indigo-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-800 dark:hover:bg-indigo-700 dark:focus:ring-indigo-800"
        type="submit"
       >
        로그인
       </Button>
      </form>
     </div>
    </div>
   </div>
  </section>
 );
};
