import { COUPON_LIST } from "../apollo/gql/item";
import { generateListQueryHook } from "../apollo/query";
import {
 FcouponFragment,
 CouponListQuery,
 CouponListQueryVariables,
 _CouponFilter,
 _CouponSort,
} from "../app/apollo/type/graphql";

export const couponListHook = generateListQueryHook<
 _CouponFilter,
 _CouponSort,
 CouponListQuery,
 CouponListQueryVariables,
 FcouponFragment
>(COUPON_LIST);
