import { useContext } from "react";
import {
 FcouponFragment,
 FgroupFragment,
 useCouponUseMutation,
} from "../../app/apollo/type/graphql";
import { AppContext } from "../../hook/useApp.context";
import { isPastDay, yyyymmdd } from "../../utils/date";
import { BasicButton } from "../basic/Button.ratio";
import { L } from "../../data/lang.static";
import { iamTopWindow } from "../../utils/localstorage/StorageAddition";
import { useUserLocation } from "../../hook/useUserLocation";

interface IProp {
 group: FgroupFragment;
 coupon: FcouponFragment;
}

export const Coupon: React.FC<IProp> = ({ group, coupon }) => {
 const { lang, store, couponUseModalHook } = useContext(AppContext);
 const { clinetLocation } = useUserLocation();
 const [couponUseMu, { loading }] = useCouponUseMutation({
  onCompleted(data, clientOptions) {
   if (data.CouponUse.ok) {
    couponUseModalHook.openImmediate({});
   }
  },
 });
 const cell = group?.cells?.[0];
 return (
  <div>
   <div className="w-full h-auto p-[5.56vw] xs:p-[25.6px] bg-white rounded-[2.22vw] xs:rounded-[10.24px] border border-slate-200 flex-col justify-center items-center gap-[3.89vw] xs:gap-[17.92px] inline-flex">
    <div className="text-gray-800 text-[3.89vw] xs:text-[17.92px] font-bold leading-none">
     {lang(cell.name)}
    </div>
    {group.limitDate &&
     (!isPastDay(group.limitDate) ? (
      <div className="text-blue-900 text-[3.33vw] xs:text-[14.4px] font-normal leading-3">
       {L["UseExpire"]} {yyyymmdd(group.limitDate)}
      </div>
     ) : (
      <div className="text-rose-500 text-[3.33vw] xs:text-[14.4px] font-normal leading-3">
       {L["UseExpire"]}
      </div>
     ))}
    <BasicButton
     color="primary"
     className="text-[3.89vw] xs:text-[17.92px] font-semibold rounded-[3.33vw] xs:rounded-[14.4px] max-w-[80%] mx-auto"
     disabled={loading}
     onClick={() => {
      if (!iamTopWindow) {
       alert("미리보기에서는 사용하실 수 없습니다.");
       return;
      }
      couponUseMu({
       variables: {
        input: {
         couponId: coupon._id,
         groupKey: group.key,
         storeId: store?._id,
         lat: clinetLocation?.lat || undefined,
         lng: clinetLocation?.lng || undefined,
        },
       },
      });
     }}
    >
     {L["UseCoupon"]}
    </BasicButton>
   </div>

   <div className="w-full mt-[3.89vw] xs:mt-[17.92px]">
    <span className="text-neutral-700 text-[3.33vw] block mb-[1.11vw] xs:mb-[4.48px] xs:text-[14.4px] font-semibold leading-none">
     {L["CouponUseInfo"]}
     <br />
    </span>
    <span className="text-neutral-700 whitespace-pre-wrap text-[3.33vw] xs:text-[14.4px] font-normal leading-none">
     {lang(cell.desc)}
    </span>
   </div>
  </div>
 );
};
