import React, { useContext } from "react";
import {
 FcouponFragment,
 FpageFragment,
 FstoreFragment,
} from "../app/apollo/type/graphql";

export type TUsePageEditorContext = ReturnType<typeof usePageEditorContext>;

type TProp = {
 setKr: any;
 set: () => void;
 page?: FpageFragment;
 store?: FstoreFragment;
 coupon?: FcouponFragment;
 editingPath?: string;
};

export const usePageEditorContext = ({
 set,
 page,
 setKr,
 store,
 coupon,
 editingPath,
}: TProp) => {
 const subject = coupon! || store!;

 return {
  subject,
  store,
  coupon,
  set,
  page,
  setKr,
  editingPath,
 };
};

export const PageEditorContext = React.createContext<TUsePageEditorContext>(
 {} as any
);
