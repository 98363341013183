import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";

import { Fragment, useContext, useEffect, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
 ArrowsPointingInIcon,
 Bars3Icon,
 BellAlertIcon,
 BellSlashIcon,
 Cog6ToothIcon,
 PlusIcon,
 PresentationChartLineIcon,
 UserIcon,
 UsersIcon,
 XMarkIcon,
} from "@heroicons/react/24/outline";
import {
 ChevronDownIcon,
 MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import classNames from "classnames";
import { eraseCookie, getCookie } from "../utils/cookie";
import {
 FchatFragment,
 FpageFragment,
 useChatRoomSubscriptionSubscription,
} from "../app/apollo/type/graphql";
import { WSclient } from "../apollo/links";
import {
 WindowNotification,
 checkNotificationSetted,
} from "../hook/windowNotification";
import {
 AppEditContext,
 useAppEditContextProvider,
} from "../hook/useAppEdit.context";
import { whenEnter } from "../utils/whenEnter";
import { AppContext } from "../hook/useApp.context";
import { PageCell } from "../components/PageCell";
import { BasicButton } from "../components/basic/button";
import { s6 } from "../utils/s4";
import { twMerge } from "tailwind-merge";
import {
 DragDropContext,
 Droppable,
 Draggable,
 DropResult,
} from "react-beautiful-dnd";
import { swap } from "../components/swap";

{
 /* <div className="flex gap-8">
<nav className="p-8 bg-slate-50">
 <ul className="py-8 flex flex-col  gap-4">
  <li className="p-4 rounded cursor-pointer">
   <Link to={"/item"}>장소</Link>
  </li>
  <li className="p-4 rounded">
   <Link to={"/user"}>유저</Link>
  </li>
  <li className="p-4 rounded">
   <Link to={"/visit"}>유저</Link>
  </li>
 </ul>
</nav>
<section>
 <Outlet />
</section>
</div> */
}

const navigation = [
 { name: "채팅", href: "chats", icon: UsersIcon },
 { name: "화면", href: "edit", icon: ArrowsPointingInIcon },
 { name: "통계", href: "statistic", icon: PresentationChartLineIcon },
 { name: "유저", href: "user", icon: UserIcon },
 //  { name: "방문", href: "visit", icon: PhotoIcon },
];
const teams = [
 { id: 1, name: "Heroicons", href: "#", initial: "H", current: false },
];
const userNavigation = [
 { name: "프로필", href: "#" },
 {
  name: "로그아웃",
  href: "#",
  onClick: () => {
   eraseCookie("qid");
   window.location.href = "/auth";
  },
 },
];

export default function DashboardLayout() {
 const {
  search,
  setSearch,
  admin,
  lang,
  store: _store,
 } = useContext(AppContext);
 const [sidebarOpen, setSidebarOpen] = useState(false);
 const [notiOn, setNotiOn] = useState(false);
 const location = useLocation();
 const navigate = useNavigate();

 const editContext = useAppEditContextProvider();
 const store = editContext?.store;

 const isEdit = location.pathname?.includes("edit");
 const isChat =
  location.pathname === "/" || location.pathname?.includes("chat");

 const { data: newChat, loading } = useChatRoomSubscriptionSubscription({
  variables: {
   chatRoomId: "admin",
  },
  client: WSclient,
  shouldResubscribe: true,
  onSubscriptionComplete() {
   console.info("sound subscribed");
  },
  async onSubscriptionData({ subscriptionData }) {
   triggerNotification(
    subscriptionData.data?.ChatRoomSubscription as FchatFragment
   );
  },
 });

 const handlePageAdd = () => {
  if ((admin?.maxPage || 0) <= (store?.pages?.length || 0)) {
   alert("더이상 페이지를 늘리실 수 없습니다.");
   return;
  }
  const next = {
   groups: [],
   key: s6(),
   name: {
    ko: "새페이지",
   },
   path: "/" + s6(),
   image: null,
  } as FpageFragment;
  store?.pages?.push(next);
  editContext.set();
 };

 const triggerNotification = (chat: FchatFragment) => {
  if (!chat) return;
  if (Notification.permission === "granted" && notiOn) {
   const notification = new Notification(
    `[${chat.chatRoomNum}] 새로운 메세지가 왔습니다.`,
    {
     icon: "/logo.svg",
     body: chat.message,
     badge: "알림",
     silent: false,
    }
   );
   const audio = document.getElementById(
    "notificationSound"
   ) as HTMLAudioElement;
   audio?.play().catch((error) => {
    console.error("Sound playback failed:", error);
   });

   notification.onclick = () => {
    if (chat.chatRoomNum) setSearch(chat.chatRoomNum || "");
    window.focus();
    audio?.pause();
   };
  }
 };

 const handleNotification = () => {
  WindowNotification.askPersmisson((permission) => {
   if (permission === "granted") {
    setNotiOn(true);
   } else {
    setNotiOn(false);
   }
  });
 };

 const handleBellClick = () => {
  if (!notiOn) {
   handleNotification();
  } else {
   setNotiOn(false);
  }
 };

 useEffect(() => {
  if (checkNotificationSetted()) {
   handleNotification();
  }
 }, []);

 const BellIcon = notiOn ? BellAlertIcon : BellSlashIcon;

 const handleDrop = (result: DropResult) => {
  swap(
   store?.pages || ([] as never),
   //  page?.groups || ([] as any),
   result.source.index,
   result.destination?.index!,
   false
  );
  editContext.set();
 };

 useEffect(() => {
  if (admin?.noChat && location?.pathname === "/admin") {
   navigate("/admin/edit");
  }
 }, [admin?.noChat]);

 if (location?.pathname?.includes("/admin/chat-in/")) return <Outlet />;
 return (
  <AppEditContext.Provider value={editContext}>
   <div className="bg-neutral-200/20">
    <Transition.Root show={sidebarOpen} as={Fragment}>
     <Dialog
      as="div"
      className="relative z-50 lg:hidden"
      onClose={setSidebarOpen}
     >
      <Transition.Child
       as={Fragment}
       enter="transition-opacity ease-linear duration-300"
       enterFrom="opacity-0"
       enterTo="opacity-100"
       leave="transition-opacity ease-linear duration-300"
       leaveFrom="opacity-100"
       leaveTo="opacity-0"
      >
       <div className="fixed inset-0 bg-gray-900/80" />
      </Transition.Child>

      <div className="fixed inset-0 flex">
       <Transition.Child
        as={Fragment}
        enter="transition ease-in-out duration-300 transform"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
       >
        <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
         <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
         >
          <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
           <button
            type="button"
            className="-m-2.5 p-2.5"
            onClick={() => setSidebarOpen(false)}
           >
            <span className="sr-only">Close sidebar</span>
            <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
           </button>
          </div>
         </Transition.Child>

         {/* Sidebar component, swap this element with another sidebar if you like */}
         <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-primary-50 px-5 pb-4">
          <div className="flex h-16 shrink-0 items-center">
           <img className="h-8 w-auto" src="/logo.svg" alt="Your Company" />
          </div>
          <nav className="flex flex-1 flex-col">
           <section className="max-h-[50svh] overflow-auto min-w-72 max-w-72 bg-white p-4  shadow">
            <div className="flex my-2 flex-col gap-y-2 ">
             <PageCell name={"인트로"} path="/" />
             {!admin?.noChat && <PageCell name={"채팅"} path="/chat" />}
             <DragDropContext
              onDragEnd={(result) => {
               handleDrop(result);
              }}
             >
              <Droppable direction="vertical" droppableId={"ROOT"} type="group">
               {(provided, snapshot) => {
                return (
                 <div
                  className="flex flex-col gap-2.5"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                 >
                  {editContext.store?.pages?.map((page, index) => {
                   return (
                    <Draggable
                     draggableId={page.key}
                     index={index}
                     key={page.key}
                    >
                     {(provided, snapshot) => {
                      return (
                       <div
                        {...provided.draggableProps}
                        {...provided?.dragHandleProps}
                        key={page.key}
                       >
                        <PageCell
                         page={page}
                         index={index}
                         onDelete={() => {
                          editContext.store?.pages?.splice(index, 1);
                          editContext.set();
                         }}
                         name={page?.name?.ko}
                         path={page.path}
                        />
                       </div>
                      );
                     }}
                    </Draggable>
                   );
                  })}
                  {provided.placeholder}
                 </div>
                );
               }}
              </Droppable>
             </DragDropContext>
             <BasicButton
              className="px-2 rouned border  rounded-lg cursor-pointer  hover:bg-slate-50  flex items-center justify-center"
              onClick={() => {
               handlePageAdd();
              }}
             >
              <PlusIcon className="w-5 h-5  mr-2.5" /> 페이지추가
             </BasicButton>
            </div>
           </section>

           <ul role="list" className="flex flex-1 flex-col gap-y-7">
            <li>
             <ul role="list" className="-mx-2 space-y-2">
              {navigation
               ?.filter((v) => {
                if (v.name?.includes("채팅")) {
                 if (admin?.noChat) return false;
                }
                return true;
               })
               .map((item) => {
                const current = location.pathname.includes(item.href);

                console.log("location.pathname", {
                 p: location.pathname,
                 h: item.href,
                });

                return (
                 <li key={item.name}>
                  <a
                   href={item.href}
                   className={classNames(
                    current
                     ? "bg-primary-700 text-white"
                     : "text-primary-200 hover:text-white hover:bg-primary-700",
                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                   )}
                  >
                   <item.icon
                    className={classNames(
                     current
                      ? "text-white"
                      : "text-primary-200 group-hover:text-white",
                     "h-6 w-6 shrink-0"
                    )}
                    aria-hidden="true"
                   />
                   {item.name}
                  </a>
                 </li>
                );
               })}
             </ul>
            </li>
            <li>
             <div className="text-xs font-semibold leading-6 text-primary-200">
              Your teams
             </div>
             <ul role="list" className="-mx-2 mt-2 space-y-1">
              {teams.map((team) => {
               const current = location.pathname.includes(team.href);

               return (
                <li key={team.name}>
                 <a
                  href={team.href}
                  className={classNames(
                   current
                    ? "bg-primary-700 text-white"
                    : "text-primary-200 hover:text-white hover:bg-primary-700",
                   "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                  )}
                 >
                  <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-primary-400 bg-blue-700 text-[0.625rem] font-medium text-white">
                   {team.initial}
                  </span>
                  <span className="truncate">{team.name}</span>
                 </a>
                </li>
               );
              })}
             </ul>
            </li>
            <li className="mt-auto">
             <a
              href="#"
              className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-primary-200 hover:bg-primary-700 hover:text-white"
             >
              <Cog6ToothIcon
               className="h-6 w-6 shrink-0 text-primary-200 group-hover:text-white"
               aria-hidden="true"
              />
              Settings
             </a>
            </li>
           </ul>
          </nav>
         </div>
        </Dialog.Panel>
       </Transition.Child>
      </div>
     </Dialog>
    </Transition.Root>

    {/* Static sidebar for desktop */}
    <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-56 lg:flex-col">
     {/* Sidebar component, swap this element with another sidebar if you like */}
     <div className="border flex grow flex-col gap-y-5 overflow-y-auto bg-white px-5 pb-4">
      <div className="flex h-20 shrink-0 items-center">
       <img
        className="h-12 my-4 w-auto mx-auto"
        src="/logo.svg"
        alt="Your Company"
       />
      </div>
      <nav className="flex flex-1 flex-col">
       <ul role="list" className="grow-0 flex flex-1 flex-col gap-y-7">
        <li>
         <ul role="list" className="-mx-2 ">
          {navigation
           ?.filter((v) => {
            if (v.name?.includes("채팅")) {
             if (admin?.noChat) return false;
            }
            return true;
           })
           .map((item) => {
            const current = location.pathname.includes(item.href);
            return (
             <li key={item.name} className={""}>
              <Link
               to={item.href}
               className={classNames(
                current
                 ? "text-primary-400 "
                 : "text-stone-900 hover:text-white hover:bg-primary-500",
                "group flex gap-x-3 py-5 justify-center rounded-md p-2 text-lg  leading-6 font-semibold"
               )}
              >
               <item.icon
                strokeWidth={2}
                className={twMerge(
                 classNames(
                  current
                   ? "text-stone-900"
                   : "text-stone-900 group-hover:text-white",
                  "h-6 w-6 shrink-0",
                  {
                   "text-primary-400 ": current,
                  }
                 )
                )}
                aria-hidden="true"
               />
               {item.name}
              </Link>
             </li>
            );
           })}
         </ul>
        </li>
        {/* <li>
         <div className="text-xs font-semibold leading-6 text-primary-200">
          Your teams
         </div>
         <ul role="list" className="-mx-2 mt-2 space-y-1">
          {teams.map((team) => (
           <li key={team.name}>
            <a
             href={team.href}
             className={classNames(
              team.current
               ? "bg-primary-700 text-white"
               : "text-primary-200 hover:text-white hover:bg-primary-700",
              "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
             )}
            >
             <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-primary-400 bg-blue-700 text-[0.625rem] font-medium text-white">
              {team.initial}
             </span>
             <span className="truncate">{team.name}</span>
            </a>
           </li>
          ))}
         </ul>
        </li>
        <li className="mt-auto">
         <a
          href="#"
          className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-primary-200 hover:bg-primary-700 hover:text-white"
         >
          <Cog6ToothIcon
           className="h-6 w-6 shrink-0 text-primary-200 group-hover:text-white"
           aria-hidden="true"
          />
          Settings
         </a>
        </li> */}
       </ul>
       {isEdit && (
        <section className="">
         <div className="h-px bg-neutral-200 my-8"></div>
         <div className="flex my-2 flex-col gap-y-2 ">
          <PageCell name={"인트로"} path="/" />
          {!admin?.noChat && <PageCell name={"채팅"} path="/chat" />}
          <DragDropContext
           onDragEnd={(result) => {
            handleDrop(result);
           }}
          >
           <Droppable direction="vertical" droppableId={"ROOT"} type="group">
            {(provided, snapshot) => {
             return (
              <div
               className="flex flex-col gap-2.5"
               {...provided.droppableProps}
               ref={provided.innerRef}
              >
               {editContext.store?.pages?.map((page, index) => {
                return (
                 <Draggable draggableId={page.key} index={index} key={page.key}>
                  {(provided, snapshot) => {
                   return (
                    <div
                     ref={provided.innerRef}
                     {...provided.draggableProps}
                     {...provided?.dragHandleProps}
                     className="bg-white"
                     key={page.key}
                    >
                     <PageCell
                      page={page}
                      index={index}
                      onDelete={() => {
                       editContext.store?.pages?.splice(index, 1);
                       editContext.set();
                      }}
                      name={page?.name?.ko}
                      path={page.path}
                     />
                    </div>
                   );
                  }}
                 </Draggable>
                );
               })}
               {provided.placeholder}
              </div>
             );
            }}
           </Droppable>
          </DragDropContext>
          <BasicButton
           className="px-2 rouned border bg-white text-nuetral-800 rounded-lg cursor-pointer    flex items-center justify-center"
           onClick={() => {
            handlePageAdd();
           }}
          >
           <PlusIcon className="w-5 h-5 mr-2.5" /> 페이지추가
          </BasicButton>
         </div>
        </section>
       )}
      </nav>
     </div>
    </div>

    <div className="lg:pl-56">
     {isChat && (
      <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px- lg:px-8">
       <button
        type="button"
        className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
        onClick={() => setSidebarOpen(true)}
       >
        <span className="sr-only">Open sidebar</span>
        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
       </button>

       {/* Separator */}
       <div className="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true" />

       <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
        <div className="relative flex flex-1">
         <label htmlFor="search-field" className="sr-only">
          Search
         </label>
         <MagnifyingGlassIcon
          onClick={() => {
           const val = (
            document.getElementById("search-field") as HTMLInputElement
           ).value;
           navigate("/admin/chats");
           setSearch(val);
          }}
          className="cursor-pointer absolute inset-y-0 left-0 h-full w-5 text-gray-400"
          aria-hidden="true"
         />
         <input
          id="search-field"
          className="block outline-none h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
          placeholder="채팅검색하기"
          onKeyDown={whenEnter((e) => {
           setSearch(e.currentTarget.value);
          })}
          type="search"
          name="search"
         />
        </div>
        <div className="flex items-center gap-x-4 lg:gap-x-6">
         <button
          type="button"
          className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
         >
          <span className="sr-only">View notifications</span>
          <BellIcon
           onClick={handleBellClick}
           className="h-6 w-6"
           aria-hidden="true"
          />
         </button>

         {/* Separator */}
         <div
          className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
          aria-hidden="true"
         />

         {/* Profile dropdown */}
         <Menu as="div" className="relative">
          <Menu.Button className="-m-1.5 flex items-center p-1.5">
           <span className="sr-only">Open user menu</span>
           <img
            className="h-8 w-8 rounded-full bg-gray-50"
            src={(_store || store)?.mainImage?.uri || "/profile.jpeg"}
            alt=""
           />
           <span className="hidden lg:flex lg:items-center">
            <span
             className="ml-4 text-sm font-semibold leading-6 text-gray-900"
             aria-hidden="true"
            >
             {admin?.name?.ko}
            </span>
            <ChevronDownIcon
             className="ml-2 h-5 w-5 text-gray-400"
             aria-hidden="true"
            />
           </span>
          </Menu.Button>
          <Transition
           as={Fragment}
           enter="transition ease-out duration-100"
           enterFrom="transform opacity-0 scale-95"
           enterTo="transform opacity-100 scale-100"
           leave="transition ease-in duration-75"
           leaveFrom="transform opacity-100 scale-100"
           leaveTo="transform opacity-0 scale-95"
          >
           <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
            {userNavigation.map((item) => (
             <Menu.Item key={item.name}>
              {({ active }) => (
               <a
                onClick={item.onClick}
                href={item.href}
                className={classNames(
                 active ? "bg-gray-50" : "",
                 "block px-3 py-1 text-sm leading-6 text-gray-900"
                )}
               >
                {item.name}
               </a>
              )}
             </Menu.Item>
            ))}
           </Menu.Items>
          </Transition>
         </Menu>
        </div>
       </div>
      </div>
     )}

     <main className="">
      <Outlet />
     </main>
    </div>
   </div>
  </AppEditContext.Provider>
 );
}
