import { gql } from "@apollo/client";
import {
 F_COLLECTION_DATA_INTERFACE,
 F_INPUTERROR,
 F_PAGEINFO,
} from "./common";
import { F_FILE } from "./file";
import { F_DETAIL_LOCATIOn } from "./location";
import { F_PAGE } from "./store";

export const F_COUPON_HISTORY = gql`
 fragment FcouponHistory on CouponHistory {
  ...FcollectionDataInterface
  couponName
  pageName
  lat
  lng
  storeId
  storeName
  mid
 }
 ${F_COLLECTION_DATA_INTERFACE}
`;

export const F_COUPON = gql`
 fragment Fcoupon on Coupon {
  ...FcollectionDataInterface
  time
  contact
  addressDetail {
   ...FdetailLocation
  }
  page {
   ...Fpage
  }
  providerContact
  categoryKey
  contact
  lat
  lng
 }
 ${F_PAGE}
 ${F_DETAIL_LOCATIOn}
 ${F_COLLECTION_DATA_INTERFACE}
`;

export const COUPON_UPDATE = gql`
 mutation couponUpdate($input: CouponInput!, $couponId: String!) {
  CouponUpdate(couponId: $couponId, input: $input) {
   ok
   error {
    ...FinputError
   }
  }
 }
 ${F_INPUTERROR}
`;

export const COUPON_CREATE = gql`
 mutation couponCreate($input: CouponInput!) {
  CouponCreate(input: $input) {
   ok
   error {
    ...FinputError
   }
  }
 }
 ${F_INPUTERROR}
`;

export const COUPON_DELETE = gql`
 mutation couponDelete($couponId: String!) {
  CouponDelete(couponId: $couponId) {
   ok
   error {
    ...FinputError
   }
  }
 }
 ${F_INPUTERROR}
`;

export const COUPON_FIND_BY_ID = gql`
 query couponFindById($couponId: String!) {
  CouponFindById(couponId: $couponId) {
   ...Fcoupon
  }
 }
 ${F_COUPON}
`;

export const COUPON_FIND_BY_PATH = gql`
 query couponFindByPath($path: String!) {
  CouponFindByPath(path: $path) {
   ...Fcoupon
  }
 }
 ${F_COUPON}
`;

export const COUPON_FIND_BY_STORE_ID = gql`
 query couponFindByStoreId($storeId: String!) {
  CouponFindByStoreId(storeId: $storeId) {
   ...Fcoupon
  }
 }
 ${F_COUPON}
`;

export const COUPON_LIST = gql`
 query couponList(
  $sort: [_CouponSort!]
  $filter: _CouponFilter
  $pagingInput: OffsetPagingInput!
 ) {
  CouponList(sort: $sort, pagingInput: $pagingInput, filter: $filter) {
   pageInfo {
    ...FoffsetPagingInfo
   }
   items {
    ...Fcoupon
   }
  }
 }
 ${F_FILE}
 ${F_COUPON}
 ${F_PAGEINFO}
`;

export const COUPON_HISTORY_LIST = gql`
 query couponHistoryList(
  $sort: [_CouponHistorySort!]
  $filter: _CouponHistoryFilter
  $pagingInput: OffsetPagingInput!
 ) {
  CouponHistoryList(sort: $sort, pagingInput: $pagingInput, filter: $filter) {
   pageInfo {
    ...FoffsetPagingInfo
   }
   items {
    ...FcouponHistory
   }
  }
 }
 ${F_COUPON_HISTORY}
 ${F_PAGEINFO}
`;
