import { USER_LIST } from "../apollo/gql/user";
import { generateListQueryHook } from "../apollo/query";
import {
 FuserFragment,
 UserListQuery,
 UserListQueryVariables,
 _UserFilter,
 _UserSort,
} from "../app/apollo/type/graphql";

export type ListUser = UserListQuery["UserList"]["items"][0];
export const userListHook = generateListQueryHook<
 _UserFilter,
 _UserSort,
 UserListQuery,
 UserListQueryVariables,
 ListUser
>(USER_LIST);
