import { BasicInput } from "./basic/input";

interface IProp {
 title?: JSX.Element | string;
 children: JSX.Element;
 Ref?: any;
 onChangeTitle?: (val: string) => string;
}

export const EditBox: React.FC<IProp> = ({
 Ref,
 title,
 children,
 ...props
}) => {
 return (
  <div ref={Ref} className=" w-full flex flex-col" {...props}>
   {title && (
    <div className=" px-5 py-3 bg-white rounded-tl-lg rounded-tr-lg border border-b-0 border-slate-200 justify-start items-center gap-2.5 flex">
     <div className="text-gray-800 w-full text-sm font-semibold  leading-none">
      {title}
     </div>
    </div>
   )}
   <div className="w-full p-2 py-4 bg-white rounded-bl-lg rounded-br-lg border border-slate-200 justify-center items-center gap-2.5 inline-flex">
    <div className="w-full flex-col justify-center items-center gap-2.5 inline-flex">
     {children}
    </div>
   </div>
  </div>
 );
};
