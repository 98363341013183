import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { FcouponFragment, FpageFragment } from "../app/apollo/type/graphql";
import { Photo } from "../components/Photo";
import { iamTopWindow } from "../utils/localstorage/StorageAddition";
import { useContext, useEffect, useLayoutEffect } from "react";
import { AppContext } from "../hook/useApp.context";
import { Group } from "../components/cell/Group";
import { CouponInfoLineZone } from "../components/cell/CouponInfoLineZone";
import { SuperAdminContext } from "../hook/super.admin.context";
import Aos from "aos";
import Helmet from "react-helmet";

interface IProp {
 coupon?: FcouponFragment;
 page: FpageFragment;
 onBack?: () => void;
}

export const PageUI: React.FC<IProp> = ({ page, coupon, onBack }) => {
 const {} = useContext(SuperAdminContext);
 const { lang } = useContext(AppContext);

 useLayoutEffect(() => {
  const timer = setInterval(() => {
   Aos.refreshHard();
  }, 1000);
  return () => {
   clearTimeout(timer);
  };
 }, []);

 return (
  <>
   <div
    className={classNames(
     "w-full relative min-h-[100svh] lg:min-h-[100vh] pb-[27.78vw] xs:pb-[110.4px] bg-white shadow",
     {
      "min-h-0 overflow-auto max-h-screen": !iamTopWindow,
     }
    )}
   >
    <div
     onClick={() => {
      onBack?.();
      if (!onBack) window.history.back();
     }}
     className="w-[11.11vw] cursor-pointer xs:w-[51.2px] h-[11.11vw] xs:h-[51.2px] flex items-center justify-center bg-white rounded-full absolute z-20 left-[5.56vw] xs:left-[25.6px] top-[5.56vw] xs:top-[25.6px]"
    >
     <ArrowLeftIcon className="w-[6.67vw] xs:w-[30.72px] h-[6.67vw] xs:h-[30.72px]" />
    </div>
    <div className="w-full h-[83.33vw] xs:h-[384px] relative rounded-bl-[13.89vw] xs:rounded-bl-[61.44px] overflow-hidden rounded-br-[13.89vw] xs:rounded-br-[61.44px]">
     <Photo
      className="object-cover bg-fixed object-center w-full h-full"
      size={1500}
      file={page?.image}
      defaultSrc={"/360x300.png"}
     />
    </div>
    <div className="px-[5.56vw] xs:px-[25.6px] h-full text-left gap-[0.69vw] xs:gap-[2.56px]">
     <h1 className="font-semibold mb-[10.11vw] xs:mb-[48px] mt-[5.56vw] xs:mt-[25.6px] text-[5.56vw] xs:text-[25.6px]">
      {lang(page?.name)}
     </h1>
     <div className="flex flex-col gap-[11.11vw] xs:gap-[51.2px]">
      {coupon && <CouponInfoLineZone coupon={coupon} />}
      {page?.groups?.map((g) => {
       return <Group coupon={coupon} group={g} key={g.key} />;
      })}
     </div>
    </div>
   </div>
  </>
 );
};
