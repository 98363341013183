import { useContext } from "react";
import { useInput } from "../hook/useInput";
import { TUseModal } from "../hook/useModal";
import Modal from "./Modal";
import { NDigitInput } from "./basic/NDigitInput";
import { AppContext } from "../hook/useApp.context";
import { BasicButton } from "./basic/Button.ratio";
import { L } from "../data/lang.static";

export interface ICouponUseModalInfo {}

interface IProp {
 modalhook: TUseModal<ICouponUseModalInfo>;
}

export const CouponUseModal: React.FC<IProp> = ({ modalhook }) => {
 const { lang } = useContext(AppContext);

 return (
  <Modal
   bodyclasses="p-[2.78vw] xs:p-[9.2px] w-[66vw] xs:w-[302.19px]"
   outsideClickClose={false}
   modalHook={modalhook}
  >
   <div className="flex-col mx-auto justify-center items-center gap-[2.78vw] xs:gap-[12.8px] flex w-fit">
    <div
     style={{
      lineHeight: 1.3,
     }}
     className="text-center text-gray-800 text-[4.44vw] xs:text-[19.2px] font-normal leading-none"
    >
     {L["CouponComplete"]}
    </div>
    <BasicButton
     className="rounded-[3.33vw] xs:rounded-[14.4px] text-[4.44vw] xs:text-[19.2px]"
     onClick={() => {
      modalhook.close();
     }}
     variant="flat"
     color="primary"
    >
     {L["Confirm"]}
    </BasicButton>
   </div>
  </Modal>
 );
};
