import dayjs from "dayjs";
import { CalendarIcon } from "@heroicons/react/24/outline";
import { BasicInput } from "./input";
import { DayPickerInput } from "../DayPicker";

interface IProp extends React.ComponentProps<typeof DayPickerInput> {
 placeholderL?: string;
 placeholderR?: string;
}

export const BasicDayPickerInputRange = ({
 calprop,
 placeholderL,
 placeholderR,
}: IProp) => {
 const { from, to } = calprop;
 return (
  <DayPickerInput
   className="w-full"
   calendarClassName="w-96"
   ButtonEle={
    <div className="flex gap-3 w-full items-center">
     <BasicInput
      className="w-full"
      Icon={<CalendarIcon />}
      value={from ? dayjs(from).format("YYYY.MM.DD") : undefined}
      placeholder={placeholderL || "시작일"}
     />
     ~
     <BasicInput
      className="w-full"
      Icon={<CalendarIcon />}
      value={to ? dayjs(to).format("YYYY.MM.DD") : undefined}
      placeholder={placeholderR || "종료일"}
     />
    </div>
   }
   calprop={calprop}
  />
 );
};
