import { Fragment, useContext, useState } from "react";
import {
 CellType,
 FcouponFragment,
 FgroupFragment,
} from "../../app/apollo/type/graphql";
import { Cell } from "./Cell";
import { AppContext } from "../../hook/useApp.context";
import { SwiperSlide, Swiper } from "swiper/react";
import { enumCheck } from "../../utils/enum";
import { Photo } from "../Photo";
import { Pagination } from "swiper/modules";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import { CellWrap } from "./CellWrap";
import { KakaoMap } from "../Map";
import { Video } from "./Video";
import { Coupon } from "./Coupon";
import { MapPin } from "../MapPin";

interface IProp {
 coupon?: FcouponFragment;
 group: FgroupFragment;
}

export const Group: React.FC<IProp> = ({ coupon, group }) => {
 const { lang, imgViewModalHook } = useContext(AppContext);
 const typeIs = enumCheck(group.type, CellType);
 const [activateSlide, setActivateSlide] = useState(0);
 const lat = group?.addressDetail?.lat || 0;
 const lng = group?.addressDetail?.lng || 0;

 return (
  <div data-aos="fade-up">
   {!typeIs.PageInfo && group.name?.ko && (
    <h6 className="my-[5.56vw] xs:my-[25.6px] text-[4.17vw] xs:text-[19.2px] font-semibold !leading-none">
     {lang(group.name)}
    </h6>
   )}
   {typeIs.Coupon && coupon && <Coupon coupon={coupon} group={group} />}

   {typeIs.Video && <Video group={group} />}
   {typeIs.Map && (
    <div className="rounded-[2.22vw] xs:rounded-[10.24px] overflow-hidden col-span-2">
     <KakaoMap
      defaultZoom={12}
      key={lat + lng}
      defaultCenter={{
       lat: lat || 35.1002169222157,
       lng: lng || 129.032488274285,
      }}
      style={{
       width: "100%",
       height: "55.56vw",
       maxHeight: "320px",
      }}
     >
      {!!lat && (
       <svg
        className="w-6 h-6 text-rose-500 shadow-md"
        //@ts-ignore
        lat={lat || 35.1002169222157}
        lng={lng || 129.032488274285}
        stroke="currentColor"
        fill="currentColor"
        stroke-width="0"
        viewBox="0 0 512 512"
        height="150px"
        width="150px"
        xmlns="http://www.w3.org/2000/svg"
       >
        <path d="M256 48c-79.5 0-144 59.9-144 133.7 0 104 144 282.3 144 282.3s144-178.3 144-282.3C400 107.9 335.5 48 256 48zm0 190.9c-25.9 0-46.9-21-46.9-46.9s21-46.9 46.9-46.9 46.9 21 46.9 46.9-21 46.9-46.9 46.9z"></path>
       </svg>
      )}
     </KakaoMap>
    </div>
   )}

   {typeIs.Gallery && (
    <>
     <Swiper
      className="aspect-square rounded-[2.22vw] xs:rounded-[10.24px] overflow-hidden bulletoutside"
      modules={[Pagination]}
      onSlideChange={(swiper: any) => {
       setActivateSlide(swiper.activeIndex);
      }}
      slidesPerView={1}
     >
      {group.images?.map((file) => {
       return (
        <SwiperSlide
         className=" w-full h-full relative"
         key={file._id + "Slide"}
        >
         <Photo
          onClick={() => {
           imgViewModalHook?.openImmediate({
            urls: [
             file?.uri,
             ...(group.images
              ?.filter((v) => v.uri !== file.uri)
              ?.map((i) => i.uri) || []),
            ],
           });
          }}
          className="object-cover w-full h-full"
          file={file}
         />
        </SwiperSlide>
       );
      })}
     </Swiper>
     {(group.images?.length || 0) > 1 && (
      <div className="flex gap-[3.33vw] xs:gap-[14.4px] mt-[5.56vw] xs:mt-[25.6px] w-full justify-center">
       {group.images?.map((img, index) => {
        return (
         <div
          className={twMerge(
           classNames(
            "w-[3.33vw] xs:w-[14.4px] aspect-square rounded-full bg-zinc-300",
            {
             "bg-zinc-700": index === activateSlide,
            }
           )
          )}
         ></div>
        );
       })}
      </div>
     )}
    </>
   )}

   <div className="grid-cols-2 grid gap-2">
    {typeIs.ImgList &&
     group.images?.map((img) => {
      return (
       <div className="border aspect-square relative overflow-hidden rounded-[4.44vw] xs:rounded-[19.2px]">
        <Photo
         size={500}
         defaultSrc={"/144x140.png"}
         file={img}
         onClick={() => {
          imgViewModalHook?.openImmediate({
           urls: [
            img?.uri,
            ...(group.images
             ?.filter((v) => v.uri !== img.uri)
             ?.map((i) => i.uri) || []),
           ],
          });
         }}
         className="w-full  h-full object-cover border-none outline-none"
        />
       </div>
      );
     })}
   </div>
   {(!typeIs.Gallery || !typeIs.ImgList) && (
    <div className="grid-cols-2 grid gap-[3.33vw] xs:gap-[14.4px]">
     {group.cells?.map((cell) => {
      return <Cell group={group} key={cell.key} cell={cell} />;
     })}
    </div>
   )}
  </div>
 );
};
