import {
 Category,
 UserRole,
 _ChatRoomSort,
 useUserDeleteMutation,
 useUserUpdateMutation,
 useUserValueUpdateMutation,
} from "../app/apollo/type/graphql";
import { SkipUpdate } from "../components/SkipUpdate";
import { hhmmA, yyyymmdd } from "../utils/date";
import { useContext } from "react";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import Pagination from "../components/Pagination";
import { SuperAdminContext } from "../hook/super.admin.context";
import { userListHook } from "../hook/userListHook";
import {
 GlobeAltIcon,
 PlusIcon,
 SpeakerWaveIcon,
} from "@heroicons/react/24/outline";
import { Badge } from "../components/Badge";
import { JWtable } from "../components/Table";
import { Button } from "../components/button";
import { CategoryKr } from "../data/const";
import { getSppech } from "../utils/speech";
import { AppContext } from "../hook/useApp.context";
import { getRefetch } from "../utils/getRefetch";
import { USER_LIST } from "../apollo/gql/user";
import { BasicInput } from "../components/basic/input";
import { BasicSwitch } from "../components/basic/Switch";

export const UserList = () => {
 const { authLoading, refetchSuperAdmin, superAdmin } =
  useContext(SuperAdminContext);
 const [deleteMu] = useUserDeleteMutation({
  ...getRefetch(USER_LIST),
 });
 const [updateMu] = useUserValueUpdateMutation({
  ...getRefetch(USER_LIST),
 });
 const { userCreateModalHook } = useContext(AppContext);
 const {
  items: users,
  getLoading,
  pageInfo,
  setPage,
 } = userListHook({
  fixingFilter: {
   role__eq: UserRole.Provider,
  },
 });

 const iconClasses = "cursor-pointer";
 return (
  <div className="flex  ">
   <div className="w-full">
    {/* {search && (
     <div className="p-4 tracking-wide">
      <span className=" text-primary-200 font-semibold">{search}</span>에 대한
      검색결과 총
      <b className="text-primary-200 tracking-widest">
       {pageInfo?.totalPageCount || 0}
      </b>
      건{" "}
      <XMarkIcon
       className="w-5 h-5 cursor-pointer inline-block"
       onClick={() => {
        setSearch("");
       }}
      />
     </div>
    )} */}

    <Button
     onClick={() => {
      userCreateModalHook.openImmediate({});
     }}
     color="primary"
     className="w-[90%] mx-auto mt-20 px-4 mb-8"
    >
     등록하기 <PlusIcon className="ml-1 w-5" strokeWidth={2} />
    </Button>

    <SkipUpdate skip={getLoading}>
     <JWtable
      className="mb-8"
      data={users}
      columns={[
       {
        title: "사진",
        accessor: "Store",
        Cell: ({ original }) => {
         const src = original?.Store?.mainImage?.uri;
         return (
          <div className="relative rounded overflow-hidden">
           <img className="w-24" src={src || ""} />
          </div>
         );
        },
       },
       {
        title: "이름",
        accessor: "name",
        Cell: ({ original }) => {
         return (
          <div className="flex justify-center flex-col items-center">
           {original.name?.ko}
          </div>
         );
        },
       },
       {
        title: "도메인",
        accessor: "Store",
        Cell: ({ original }) => {
         return (
          <div>
           <div className="text-xs line-clamp-3">
            https://
            {original.Store?.domain}.hoteltalk.me
           </div>
          </div>
         );
        },
       },
       {
        title: "페이지",
        accessor: "Store",
        Cell: ({ original }) => {
         return (
          <div className="flex gap-1 flex-col">
           <div>페이지수 {original?.Store?.pages?.length}</div>

           <div className="flex gap-1 items-center">
            최대
            <input
             className="py-1 px-2 "
             onBlur={(e) => {
              const val = parseInt(e.currentTarget?.value) || 0;
              updateMu({
               variables: {
                input: {
                 maxPage: val,
                },
                userId: original._id,
               },
              });
             }}
             defaultValue={(original?.maxPage || 99)?.toString()}
            />
           </div>
           <BasicSwitch
            label="채팅"
            checked={!original.noChat}
            onChange={() => {
             updateMu({
              variables: {
               input: {
                noChat: !original.noChat,
               },
               userId: original._id,
              },
             });
            }}
           />
          </div>
         );
        },
       },
       {
        title: "페이지",
        accessor: "Store",
        Cell: ({ original }) => {
         return (
          <div className="flex gap-1 flex-col">
           <Button
            onClick={() => {
             userCreateModalHook.openImmediate({
              user: original,
             });
            }}
            className="w-16"
            size="S"
            color="primary"
            variant="border"
           >
            수정
           </Button>
           <Button
            onClick={() => {
             if (window.confirm("정말로 삭제하시겠습니까?"))
              deleteMu({
               variables: {
                userId: original._id,
               },
              });
            }}
            className="w-16"
            size="S"
            color="red"
            variant="border"
           >
            삭제
           </Button>
          </div>
         );
        },
       },
      ]}
     />
     {pageInfo.totalPageCount > 1 && (
      <div className="mt-20">
       <Pagination
        onPageChange={(page) => {
         setPage(page - 1);
        }}
        currentPage={pageInfo.pageIndex + 1}
        totalPages={pageInfo.totalPageCount}
       />
      </div>
     )}
    </SkipUpdate>
   </div>
  </div>
 );
};

export default UserList;
