import { useContext } from "react";
import { FcellFragment } from "../../app/apollo/type/graphql";
import { Photo } from "../Photo";
import { AppContext } from "../../hook/useApp.context";
import { CellWrap } from "./CellWrap";

interface IProp {
 cell: FcellFragment;
}

export const Vertical: React.FC<IProp> = ({ cell }) => {
 const { lang } = useContext(AppContext);
 return (
  <CellWrap
   cell={cell}
   className="col-span-2 xs:p-[5.12px] overflow-hidden border-neutral-200"
  >
   <div className="relative overflow-hidden rounded-[4.44vw] xs:rounded-[20.48px]">
    <Photo
     width={320}
     height={90}
     size={500}
     defaultSrc={"/320x90.png"}
     file={cell.image}
     className="w-full aspect-[320/90] h-full object-cover border-none outline-none"
    ></Photo>
   </div>
   <h6 className="text-center pb-[5.56vw] xs:pb-[25.6px] text-gray-800 text-[3.33vw] xs:text-[14.4px] font-bold pt-[2.78vw] xs:pt-[11.52px] !leading-none">
    {lang(cell.name)}
   </h6>
  </CellWrap>
 );
};
