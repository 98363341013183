import { gql } from "@apollo/client";
import { F_FILE } from "./file";
import { F_INPUTERROR, F_PAGEINFO } from "./common";
import { F_DETAIL_LOCATIOn } from "./location";

export const F_ICON_PAIR = gql`
 fragment FiconPair on IconPair {
  type
  key
  icon
  title
 }
`;

export const F_CELL = gql`
 fragment Fcell on Cell {
  key
  desc
  name
  type
  image {
   ...Ffile
  }
  addressDetail {
   ...FdetailLocation
  }
  link
  customLink
  iconLines {
   ...FiconPair
  }
 }
 ${F_DETAIL_LOCATIOn}
 ${F_FILE}
 ${F_ICON_PAIR}
`;

export const F_GROUP = gql`
 fragment Fgroup on Group {
  key
  type
  name
  limitDate
  addressDetail {
   ...FdetailLocation
  }
  youtubeCode
  video {
   ...Ffile
  }
  images {
   ...Ffile
  }
  cells {
   ...Fcell
  }
 }
 ${F_DETAIL_LOCATIOn}
 ${F_FILE}
 ${F_CELL}
`;

export const F_PAGE = gql`
 fragment Fpage on Page {
  key
  path
  image {
   ...Ffile
  }
  name
  groups {
   ...Fgroup
  }
 }
 ${F_GROUP}
 ${F_FILE}
`;

export const F_INPUT_FIELD = gql`
 fragment FinputField on InputField {
  require
  type
  label
  options
  key
  value
 }
`;

export const F_STORE = gql`
 fragment Fstore on Store {
  ...FcollectionDataInterface
  domain
  pages {
   ...Fpage
  }
  mainMain
  mainIntro
  introMain
  introduce
  mainImage {
   ...Ffile
  }
  User {
   maxPage
   noChat
  }
  introChat
  userId
  userName
  name
  gaCode
  fields {
   ...FinputField
  }
 }
 ${F_INPUT_FIELD}
 ${F_FILE}
 ${F_PAGE}
`;

export const STORE_UPDATE = gql`
 mutation storeUpdate($input: StoreInput!, $storeId: String!) {
  StoreUpdate(storeId: $storeId, input: $input) {
   ok
   error {
    ...FinputError
   }
  }
 }
 ${F_INPUTERROR}
`;

export const STORE_EDIT = gql`
 mutation storeEdit($input: StoreEditInput!, $storeId: String!) {
  StoreEdit(storeId: $storeId, input: $input) {
   ok
   error {
    ...FinputError
   }
  }
 }
 ${F_INPUTERROR}
`;

export const STORE_CREATE = gql`
 mutation storeCreate($input: StoreInput!) {
  StoreCreate(input: $input) {
   ok
   error {
    ...FinputError
   }
  }
 }
 ${F_INPUTERROR}
`;

export const STORE_DELETE = gql`
 mutation storeDelete($storeId: String!) {
  StoreDelete(storeId: $storeId) {
   ok
   error {
    ...FinputError
   }
  }
 }
 ${F_INPUTERROR}
`;

export const STORE_FIND_BY_ID = gql`
 query storeFindById($storeId: String!) {
  StoreFindById(storeId: $storeId) {
   ...Fstore
  }
 }
 ${F_STORE}
`;

export const STORE_FIND_BY_PATH = gql`
 query storeFindByPath($path: String!) {
  StoreFindByPath(path: $path) {
   ...Fstore
  }
 }
 ${F_STORE}
`;

export const STORE_FIND_BY_STORE_ID = gql`
 query storeFindByStoreId($storeId: String!) {
  StoreFindByStoreId(storeId: $storeId) {
   ...Fstore
  }
 }
 ${F_STORE}
`;

export const STORE_LIST = gql`
 query storeList(
  $sort: [_StoreSort!]
  $filter: _StoreFilter
  $pagingInput: OffsetPagingInput!
 ) {
  StoreList(sort: $sort, pagingInput: $pagingInput, filter: $filter) {
   pageInfo {
    ...FoffsetPagingInfo
   }
   items {
    ...Fstore
   }
  }
 }
 ${F_FILE}
 ${F_STORE}
 ${F_PAGEINFO}
`;
