import {
 Category,
 FbookerFragment,
 UserRole,
 _ChatRoomSort,
 useBookerListQuery,
 useUserDeleteMutation,
 useUserUpdateMutation,
 useUserValueUpdateMutation,
} from "../app/apollo/type/graphql";
import { SkipUpdate } from "../components/SkipUpdate";
import { hhmmA, yyyymmdd } from "../utils/date";
import { useContext } from "react";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import Pagination from "../components/Pagination";
import { SuperAdminContext } from "../hook/super.admin.context";
import { userListHook } from "../hook/userListHook";
import {
 GlobeAltIcon,
 PlusIcon,
 SpeakerWaveIcon,
} from "@heroicons/react/24/outline";
import { Badge } from "../components/Badge";
import { JWtable } from "../components/Table";
import { Button } from "../components/button";
import { CategoryKr, LangMapKr } from "../data/const";
import { getSppech } from "../utils/speech";
import { AppContext } from "../hook/useApp.context";
import { getRefetch } from "../utils/getRefetch";
import { USER_LIST } from "../apollo/gql/user";
import { BasicInput } from "../components/basic/input";
import { BasicSwitch } from "../components/basic/Switch";
import { bookerListHook } from "../hook/useBooker";
import { DevJsonViewer } from "../components/devTools/DevJson";

export const BookerList = () => {
 const { authLoading, refetchSuperAdmin, superAdmin } =
  useContext(SuperAdminContext);
 const { userCreateModalHook, store } = useContext(AppContext);
 const {
  items: users,
  getLoading,
  pageInfo,
  setPage,
 } = bookerListHook({
  fixingFilter: {
   storeId__eq: store?._id,
  },
 });

 if (!store?.fields?.length && users?.length) {
  return (
   <div className="font-semibold text-center  pt-20">
    사용자요구 정보는 페이지수정 / 인트로 / 입력필드 <br />
    설정을 통해서 수집하실 수 있습니다.
   </div>
  );
 }

 const iconClasses = "cursor-pointer";
 return (
  <div className="flex bg-white  p-8 ">
   <div className="w-full">
    <div className="p-4">
     <h6 className="text-2xl font-semibold mb-4">사용자 수집 정보</h6>
     검색결과 총
     <b className="text-primary-200 tracking-widest">
      {pageInfo?.totalPageCount || 0}
     </b>
     건
    </div>
    <SkipUpdate skip={getLoading}>
     <div className="rounded-xl bg-white">
      <JWtable
       data={users}
       columns={[
        {
         title: "언어",
         accessor: "lang",
         Cell: ({ original }) => {
          return (
           <div className="relative rounded overflow-hidden">
            {LangMapKr[original?.lang || ""]}
           </div>
          );
         },
        },
        {
         title: "구분",
         accessor: "bookerId",
         Cell: ({ original }) => {
          return (
           <div className="flex justify-center flex-col items-center">
            {original.bookerId}
           </div>
          );
         },
        },
        {
         title: "가입일",
         accessor: "createdAt",
         Cell: ({ original }) => {
          return (
           <div>
            <div className="text-xs line-clamp-3">
             {yyyymmdd(original.createdAt)}
            </div>
           </div>
          );
         },
        },
        ...(store?.fields?.map((field) => {
         return {
          title: field?.label?.ko || "-",
          accessor: "_id",
          Cell: ({ original }: any) => {
           const targetField = original.fields?.find(
            (fd: any) => fd.key === field.key
           );
           return <div>{targetField?.value || "-"}</div>;
          },
         } as any;
        }) || []),
       ]}
      />
     </div>
     {pageInfo.totalPageCount > 1 && (
      <div className="mt-20">
       <Pagination
        onPageChange={(page) => {
         setPage(page - 1);
        }}
        currentPage={pageInfo.pageIndex + 1}
        totalPages={pageInfo.totalPageCount}
       />
      </div>
     )}
    </SkipUpdate>
   </div>
  </div>
 );
};

export default BookerList;
