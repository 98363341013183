import { useNavigate } from "react-router-dom";
import { ISet } from "./userListQuery";
import { useEffect, useMemo, useRef, useState } from "react";
import { Self } from "./self.type";
import useWindowEventListener from "./useEventListener";
import { FcouponFragment, FstoreFragment } from "../app/apollo/type/graphql";
export interface IuseIframCommunicateProp {}
const iamInnerWidnow =
 typeof window !== "undefined" && window.parent !== window.self;

export type IuseIframCommunicate = ReturnType<typeof useIframCommunicate>;

interface IProp {
 setCoupon?: ISet<FcouponFragment>;
 setStore?: ISet<FstoreFragment>;
}

export const useIframCommunicate = ({ setStore, setCoupon }: IProp) => {
 const [inited, setInited] = useState(false);
 const selfIframeRef = useRef<HTMLIFrameElement>(null);
 const navigate = useNavigate();

 const postToChild = (param: Self.Request) => {
  console.log("post to child" + param.command);
  selfIframeRef.current?.contentWindow?.postMessage(
   param,
   window.location.origin
  );
 };

 const postToParent = (data: Self.Request) => {
  console.log("inner iframe edit data:" + data.command, data);
  window.parent.postMessage(data, window.location.origin);
 };

 const onMessage = useMemo(() => {
  function eventFn(event: MessageEvent<Self.Request>) {
   const request = event.data;
   console.log("!!!!onMessaged" + request);
   if (request.command)
    console.log(
     `event: [${iamInnerWidnow ? "In" : "Out"} - ${request.command}]`,
     request.command
    );
   switch (request.command) {
    case "inited":
     setInited?.(true);
     break;
    case "update":
     setStore?.(request.store);
     break;
    case "updateCoupon":
     setCoupon?.(request.coupon);
     break;
    case "route":
     navigate(request.path);
     break;
   }
  }
  return eventFn;
 }, []);

 useWindowEventListener("message", onMessage);
 return {
  inited,
  setInited,
  postToChild,
  selfIframeRef,
  postToParent,
 };
};
