import { useContext } from "react";
import {
 FcellFragment,
 FiconPairFragment,
} from "../../app/apollo/type/graphql";
import { Photo } from "../Photo";
import { AppContext } from "../../hook/useApp.context";
import { InfoCell } from "./InfoCell";
import { CellWrap } from "./CellWrap";

interface IProp {
 cell: FcellFragment;
}

export const InfoList: React.FC<IProp> = ({ cell }) => {
 const { lang } = useContext(AppContext);
 return (
  <CellWrap
   cell={cell}
   className="col-span-2 flex items-center gap-x-[2.22vw] xs:gap-x-[10.24px] p-[1.11vw] xs:p-[5.12px] bg-white overflow-hidden rounded-[4.44vw] xs:rounded-[20.48px] shadow border-[0.28vw] xs:border-[1.12px] border-neutral-200"
  >
   <div className="aspect-square w-[22.22vw] xs:w-[102.4px] relative overflow-hidden rounded-[4.44vw] xs:rounded-[20.48px]">
    <Photo
     width={144}
     height={140}
     size={500}
     defaultSrc={"/144x140.png"}
     file={cell.image}
     className="w-full h-full object-cover border-none outline-none"
    />
   </div>
   <div className="flex text-left flex-col">
    <h6 className="text-left mb-[1.67vw] xs:mb-[7.68px] text-gray-800 text-[3.33vw] xs:text-[14.4px] font-semibold !leading-none">
     {lang(cell.name)}
    </h6>
    <ul>
     {cell.iconLines?.map((info) => {
      return <InfoCell infocell={info} />;
     })}
    </ul>
   </div>
  </CellWrap>
 );
};
