import { Fragment, ReactElement } from "react";
import { Navigate } from "react-router-dom";

import {
 SuperAdminContext,
 useSuperAdminContextProvider,
} from "../../hook/super.admin.context";

interface IProp {
 children: ReactElement;
}

export const SuperProtectedRoute: React.FC<IProp> = ({ children }) => {
 const adminCtx = useSuperAdminContextProvider({});

 if (adminCtx.authLoading) return null;

 if (!adminCtx.superAdmin) {
  // Redirect to login page if not authenticated
  return <Navigate to="/auth" replace />;
 }

 console.log("@@", {
  adminCtx,
 });

 return (
  <SuperAdminContext.Provider value={adminCtx}>
   <Fragment key={adminCtx?.superAdmin?._id + "EditRoute"}>{children}</Fragment>{" "}
  </SuperAdminContext.Provider>
 );
};
