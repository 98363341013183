import { gql } from "@apollo/client";
import { F_COLLECTION_DATA_INTERFACE, F_INPUTERROR } from "./common";
import { F_USER } from "./user";

export const SignIn = gql`
 mutation signIn($password: String!, $name: String!, $role: UserRole!) {
  SignIn(password: $password, name: $name, role: $role) {
   ok
   error {
    ...FinputError
   }
   data {
    ...Fuser
   }
  }
 }
 ${F_USER}
 ${F_INPUTERROR}
`;

export const ADMIN = gql`
 query admin($domain: String!) {
  Admin(domain: $domain) {
   ...Fuser
  }
 }
 ${F_USER}
`;

export const SUPER_ADMIN = gql`
 query superAdmin {
  SuperAdmin {
   ...Fuser
  }
 }
 ${F_USER}
`;
