import { PhotoIcon, PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { ReactNode, useState } from "react";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import { BasicButton } from "./button";
import { FfileFragment } from "../../app/apollo/type/graphql";
import { ImageDropzone } from "../ImageDropZone";
import { Spinner } from "../Spinner";

interface IProp {
 file?: FfileFragment;
 setFile: (file?: FfileFragment) => void;
 Title?: ReactNode;
 className?: string;
}

export const SingleImageUploaderBox: React.FC<IProp> = ({
 file,
 setFile,
 Title,
 className,
}) => {
 const [loading, setLoading] = useState(false);
 return (
  <ImageDropzone
   loading={loading}
   setLoading={setLoading}
   onUploaded={(file, { blurHash }) => {
    file.blur = blurHash;
    setFile(file);
   }}
   accept={"image/*"}
   className={twMerge(
    classNames(
     "rounded-lg w-20 aspect-square p-0 overflow-hidden cursor-pointer  bg-slate-50 hover:bg-slate-100 flex-col justify-center items-center gap-3 inline-flex"
    ),
    className
   )}
   resize={{
    width: 500,
   }}
  >
   {file && !loading && (
    <div
     key={file.uri}
     className="w-full  aspect-square relative"
     style={{
      backgroundSize: "cover",
      backgroundPosition: "center center",
      backgroundImage: `url(${file.uri})`,
     }}
    >
     <div
      onClick={(e) => {
       e.preventDefault();
       e.stopPropagation();
       setFile(undefined);
      }}
      style={{
       borderBottomLeftRadius: 4,
      }}
      className="cursor-pointer  hover:bg-white w-4 h-4 absolute top-0 right-0 flex items-center justify-center bg-white bg-opacity-80"
     >
      <XMarkIcon className="text-red-500 w-3 h-3" strokeWidth={1.5} />
     </div>
    </div>
   )}
   {loading && <Spinner className="text-primary-500 w-6 h-6 relative" />}
   {!file && !loading && (
    <PlusIcon strokeWidth={1.5} className="text-gray-800 w-6 h-6 relative" />
   )}
  </ImageDropzone>
 );
};
