import { useContext } from "react";
import { EditBoxTitle } from "./EditBox.title";
import { PageEditorContext } from "./PageEditor.context";
import { AddressInput } from "./addressInput/AddressInput";
import { DayPickerInput } from "./DayPicker";
import { BasicSelect } from "./basic/Select";
import { EditBox } from "./EditBox";
import { BasicInput } from "./basic/input";
import { CalendarIcon } from "@heroicons/react/24/outline";
import dayjs from "dayjs";
import { CategoryOps } from "../data/const";

interface IProp {}

export const CouponEditor = () => {
 const { coupon, setKr, subject, set } = useContext(PageEditorContext);
 if (!coupon) return null;

 return (
  <>
   <EditBox title={<>카테고리</>}>
    <BasicSelect
     value={coupon.categoryKey}
     options={CategoryOps}
     onChange={(val) => {
      coupon["categoryKey"] = val;
      set();
     }}
    />
   </EditBox>

   <EditBox title={<>주소</>}>
    <AddressInput
     className="w-full"
     address={coupon?.addressDetail?.jibunAddress || ""}
     data={coupon?.addressDetail!}
     setAddress={(add) => {
      if (coupon) coupon.addressDetail = add;
      set();
     }}
    />
   </EditBox>

   <EditBoxTitle
    value={coupon.time?.ko || ""}
    onChange={(val) => {
     console.log("$$$$", coupon);
     setKr(coupon, "time", val);
    }}
    InputProp={{
     comma: true,
    }}
    title={"운영시간"}
   />
   <EditBoxTitle
    value={coupon.contact || ""}
    onChange={(val) => {
     coupon.contact = val;
     set();
    }}
    InputProp={{
     hyphen: true,
    }}
    title={"연락처"}
   />

   <EditBoxTitle
    value={coupon.providerContact || ""}
    onChange={(val) => {
     coupon.providerContact = val;
     set();
    }}
    InputProp={{
     hyphen: true,
    }}
    title={"쿠폰 알림 연락처"}
   />
  </>
 );
};
