import { SpeakerWaveIcon } from "@heroicons/react/20/solid";
import { useContext, useRef, useState } from "react";
import { AppContext } from "../../hook/useApp.context";
import { EventType, FcellFragment } from "../../app/apollo/type/graphql";
import { getSppech } from "../../utils/speech";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import { CellWrap } from "./CellWrap";
import { stopAllSpeaker } from "../../utils";
import { unspportedAwsLangCodes } from "../../data/lang.static";
import {
 SessionStroage,
 iamTopWindow,
} from "../../utils/localstorage/StorageAddition";

interface IInfoProp {
 cell: FcellFragment;
 name?: any;
}

export const Info: React.FC<IInfoProp> = ({ name, cell }) => {
 const { lang, langCode, sendRecord } = useContext(AppContext);
 const speakerRef = useRef<SVGSVGElement>(null);
 const descArray: string[] = lang(cell.desc)?.split("\n") || [];

 return (
  <CellWrap cell={cell} className="col-span-2">
   <div className="w-full py-[2.78vw] xs:py-[12.8px] justify-between items-center flex">
    <div className="text-[4.17vw] xs:text-[19.2px] font-semibold !leading-none">
     {name ? lang(name) : lang(cell.name)}
    </div>
    {!unspportedAwsLangCodes.includes(cell.key) && (
     <SpeakerWaveIcon
      ref={speakerRef}
      onClick={() => {
       const audio = document.getElementById(
        cell.key + "Speech"
       ) as HTMLAudioElement;

       sendRecord({
        event: EventType.Click,
        cellName: cell.name?.ko + "(스피커)",
       });

       fetch(getSppech(cell.key, langCode || "ko"))
        ?.then((res) => {
         if (res.status === 403) {
          throw Error("");
         }
         if (audio) {
          if (!audio.paused) {
           audio.pause();
          } else {
           audio.play().then((err) => {
            console.log("@@err", err);
           });
          }
         }
        })
        .catch(() => {
         if (!iamTopWindow) {
          alert("저장하기 이후에 이용해주세요.");
          return;
         }
        });
      }}
      className={twMerge(
       classNames(
        "text-white cursor-pointer p-[1.1vw] xs:p-1 w-[6.67vw] xs:w-[28.8px] h-[6.67vw] xs:h-[28.8px] relative bg-blue-900 rounded-[1.11vw] xs:rounded-[4.48px]"
       )
      )}
     />
    )}
   </div>
   <audio
    key={langCode + cell.key + "langSpeaker"}
    className="Speaker"
    onPlayCapture={(e) => {
     stopAllSpeaker(e.currentTarget as HTMLAudioElement);
    }}
    onPlay={(e) => {
     if (speakerRef.current?.style) {
      speakerRef.current?.classList.remove("bg-blue-900");
      speakerRef.current?.classList.add("bg-yellow-500");
     }
    }}
    onPause={() => {
     if (speakerRef.current?.style) {
      speakerRef.current?.classList.add("bg-blue-900");
      speakerRef.current?.classList.remove("bg-yellow-500");
     }
    }}
    id={cell.key + "Speech"}
   >
    <source src={getSppech(cell.key, langCode || "ko")} />
   </audio>
   <ul className="text-[3.89vw] xs:text-[17.92px] list-none">
    {descArray?.map((desc, index) => {
     return (
      <li
       className="flex text-[3.89vw] xs:text-[17.92px] gap-[1.11vw] xs:gap-[2.56px]"
       key={index + "Desc"}
      >
       <span className="mt-[2.22vw] xs:mt-[10.24px] mx-[2.22vw] xs:mx-[10.24px] min-w-[1.11vw] xs:min-w-[2.56px] h-[1.11vw] xs:h-[2.56px] rounded-full bg-stone-800"></span>
       {desc}
      </li>
     );
    })}
   </ul>
   <span className="bg-yellow-500" />
  </CellWrap>
 );
};
