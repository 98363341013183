import { useContext } from "react";
import { AppContext } from "../hook/useApp.context";
import { useLocation } from "react-router-dom";

import { Photo } from "../components/Photo";
import { useNavigate } from "react-router-dom";
import { Group } from "../components/cell/Group";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { iamTopWindow } from "../utils/localstorage/StorageAddition";
import {
 FcouponFragment,
 useCouponFindByIdQuery,
} from "../app/apollo/type/graphql";
import { PageUI } from "./Page.ui";
import { getFromUrl } from "../utils/getFromUrl";
import { SuperAdminContext } from "../hook/super.admin.context";
import { DevJsonViewer } from "../components/devTools/DevJson";

interface IProp {
 coupon: FcouponFragment;
}

const CouponPage: React.FC<IProp> = ({ coupon }) => {
 return (
  <>
   <PageUI coupon={coupon} page={coupon.page} />
  </>
 );
};

interface IPropWrap {}

export const CouponPageWrap: React.FC<IPropWrap> = ({}) => {
 const couponId = getFromUrl("cid")!;
 const { editingCoupon } = useContext(SuperAdminContext);
 const { data } = useCouponFindByIdQuery({
  variables: {
   couponId,
  },
 });
 if (!couponId) return null;
 const coupon = editingCoupon || data?.CouponFindById;
 if (!coupon) return null;
 return <CouponPage coupon={coupon} />;
};

export const CouponPreviewWrap: React.FC<IPropWrap> = ({}) => {
 const { previewCoupon } = useContext(AppContext);
 if (!previewCoupon) return null;
 return <CouponPage coupon={previewCoupon} />;
};

export default CouponPage;
