import { useContext, useEffect, useState } from "react";
import {
 _RecordFilter,
 FrecordFragment,
 useRecordsQuery,
 useStoreEditMutation,
 useStoreUpdateMutation,
} from "../../app/apollo/type/graphql";
import { AppContext } from "../../hook/useApp.context";
import {
 EventsOverTimeLineChart,
 HourlyViewsBarChart,
 TopClicksBarChart,
 TopPageViewsPieChart,
 DailyEventsBarChart,
 EventDistributionRadarChart,
 HeatmapEventsByDayHour,
 WeeklyEventsBarChart,
 LanguageStatsDoughnutChart,
} from "./Charts";
import dayjs from "dayjs";
import { BasicDayPickerInputRange } from "../../components/basic/DayPicker";
import { Input } from "../../components/input";
import { BasicInput } from "../../components/basic/input";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import { toast } from "react-toastify";
import { getRefetch } from "../../utils/getRefetch";
import { STORE_FIND_BY_ID } from "../../apollo/gql/store";
import { useInput } from "../../hook/useInput";

const CardHead: React.FC<{
 children: React.ReactNode;
}> = ({ children }) => {
 return <div className="text-lg font-semibold">{children}</div>;
};

const Card: React.FC<{
 className?: string;
 children: React.ReactNode;
}> = ({ className, children }) => {
 return (
  <div
   className={twMerge(
    classNames("bg-white flex flex-col  gap-4 p-6 rounded-xl", className)
   )}
  >
   {children}
  </div>
 );
};

const Statistic: React.FC = () => {
 const { store } = useContext(AppContext);
 const [keyword, setKeyword] = useState("");
 const gaValue = useInput(store?.gaCode || "");
 const [dayFilter, setDayFilter] = useState<_RecordFilter>({
  createdAt__gte: dayjs().add(-1, "month").startOf("day").toDate(),
  createdAt__lte: new Date(),
 });

 const [storeUp] = useStoreEditMutation({
  ...getRefetch(STORE_FIND_BY_ID),
  onCompleted(data, clientOptions) {
   if (data.StoreEdit?.ok && gaValue.value) toast("GA 코드가 반영되었습니다.");
  },
 });

 const handleSaveGa = (gaCode?: string) => {
  storeUp({
   variables: {
    storeId: store?._id!,
    input: {
     gaCode,
    },
   },
  });
 };

 const [filter, setFilter] = useState<_RecordFilter>(dayFilter);

 const { data } = useRecordsQuery({
  variables: {
   filter: {
    ...filter,
    storeId__eq: store?._id,
   },
  },
 });

 //hoursOps ?
 const records = [...(data?.Records || [])]?.filter((v) => {
  if (!keyword) return true;
  return v.cellName?.includes(keyword) || v.path?.includes(keyword);
 });

 return (
  <div className="m-8">
   <h1 className="text-3xl font-semibold mb-8">통계 데이터</h1>
   <div className="mb-8 rounded-xl bg-white p-6">
    <div className="mb-4">
     <h2 className="mb-2 font-semibold">날짜로 검색하기</h2>
     <BasicDayPickerInputRange
      calprop={{
       canSelectBeforeDay: true,
       from: filter.createdAt__gte,
       to: filter.createdAt__lte,
       onChange(from, to) {
        const dateFilter = {
         createdAt__gte: from,
         createdAt__lte: to,
        };
        setFilter(dateFilter);
        if (from && to) {
         setDayFilter(dateFilter);
        }
       },
      }}
     />
    </div>
    <div>
     <h2 className="mb-2 font-semibold">키워드필터</h2>
     <BasicInput
      value={keyword}
      onChange={(val) => {
       setKeyword(val || "");
       // e.currentTarget.value
      }}
      placeholder="키워드로 검색하기"
     ></BasicInput>
    </div>
   </div>

   <div className="grid  xl:grid-cols-2 2xl:grid-cols-3 gap-6">
    <Card>
     <CardHead>페이지뷰</CardHead>
     <TopPageViewsPieChart data={records} />
    </Card>
    <Card className="col-span-2">
     <CardHead>클릭</CardHead>
     <TopClicksBarChart aspectRatio={2} data={records} />
    </Card>

    <Card className="col-span-2">
     <CardHead>시간별 이벤트</CardHead>
     <EventsOverTimeLineChart aspectRatio={2} data={records} />
    </Card>
    <Card>
     <CardHead>일자별 이벤트</CardHead>
     <DailyEventsBarChart data={records} />
    </Card>

    <Card>
     <CardHead>주별 이벤트</CardHead>
     <WeeklyEventsBarChart data={records} />
    </Card>

    <Card>
     <CardHead>이벤트 분포도</CardHead>
     <EventDistributionRadarChart data={records} />
    </Card>

    <Card>
     <CardHead>언어별 활동량</CardHead>
     <LanguageStatsDoughnutChart data={records} />
    </Card>
    <Card className="col-span-2">
     <CardHead>요일/시간 이벤트</CardHead>
     <HeatmapEventsByDayHour data={records} />
    </Card>
    <Card>
     <CardHead>
      더 많은 데이터를 원하시나요? <br /> 구글 어널리틱스 코드등록
     </CardHead>
     <BasicInput
      {...gaValue}
      onBlur={(e) => {
       handleSaveGa(e.currentTarget.value || "");
      }}
      placeholder="G-XXX"
     ></BasicInput>
    </Card>
   </div>
  </div>
 );
};

export default Statistic;
