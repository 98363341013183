import {
 PhotoIcon,
 VideoCameraIcon,
 XMarkIcon,
} from "@heroicons/react/24/outline";
import { ReactNode, useState } from "react";
import classNames from "classnames";
import { twMerge } from "tailwind-merge";
import { BasicButton } from "./button";
import { FfileFragment } from "../../app/apollo/type/graphql";
import { ImageDropzone } from "../ImageDropZone";
import { Spinner } from "../Spinner";

interface IProp {
 file?: FfileFragment;
 setFile: (file?: FfileFragment) => void;
 Title?: ReactNode;
 className?: string;
}

export const VideoUploader: React.FC<IProp> = ({
 file,
 setFile,
 Title,
 className,
}) => {
 const [loading, setLoading] = useState(false);
 return (
  <ImageDropzone
   loading={loading}
   setLoading={setLoading}
   onUploaded={(file, { blurHash }) => {
    file.blur = blurHash;
    setFile(file);
   }}
   accept={"video/*"}
   className={twMerge(
    classNames(
     "cursor-pointer relative w-full h-48 p-3 border border-neutral-200 flex-col justify-center items-center gap-3 inline-flex"
    ),
    className
   )}
   resize={{
    width: 500,
   }}
  >
   {file && !loading && (
    <div key={file.uri} className="w-full  aspect-square relative">
     {file?.name}
     <div
      onClick={(e) => {
       e.preventDefault();
       e.stopPropagation();
       setFile(undefined);
      }}
      className="cursor-pointer hover:bg-white w-8 h-8 absolute top-0 right-0 flex items-center justify-center bg-white bg-opacity-80"
     >
      <XMarkIcon className="text-red-500 w-6 h-6" strokeWidth={1.5} />
     </div>
    </div>
   )}
   {(!file || loading) && (
    <>
     <VideoCameraIcon
      strokeWidth={1.5}
      className="text-neutral-200 w-12 h-12 relative"
     />
     <p className="w-auto text-center text-neutral-400 text-xs font-normal leading-none">
      {Title}
     </p>
     <BasicButton
      disabled={loading}
      className="w-32"
      color="primary"
      variant="border"
     >
      {loading ? (
       <Spinner color="red" className="w-6 dark:text-nuetral-200" />
      ) : (
       "비디오 등록하기"
      )}
     </BasicButton>
    </>
   )}
  </ImageDropzone>
 );
};
